import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from "primereact/paginator";
import { Tag } from "primereact/tag";
import { Badge } from "primereact/badge";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import SubscriptionLineChart from "../Charts/SubscriptionLineChart";
import config from "../../config";

const MobileUserSubscriptionTable = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  // const [status, setStatus] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [ideamartCount, setIdeamartCount] = useState(0);
  const [mspaceCount, setMspaceCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  // const [registeredCount, setRegisteredCount] = useState(0);
  // const [notRegisteredCount, setNotRegisteredCount] = useState(0);

  const today = new Date();

  const formatDateForAPI = (date, endOfDay = false) => {
    if (!date) return null;
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    const time = endOfDay ? "23:59:59.999Z" : "00:00:00.000Z";
    return `${year}-${month}-${day}T${time}`;
  };

  useEffect(() => {
    fetchAllSubscriptions();
  }, [type, fromDate, toDate]);

  const fetchAllSubscriptions = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const params = {
        first,
        rows,
        type,
        fromDate: formatDateForAPI(fromDate),
        toDate: formatDateForAPI(toDate, true),
      };

      const response = await axios.get(`${config.apiUrl}/subscription`, {
        params,
        ...headersConfig,
      });

      setSubscriptions(response.data.subscriptions);
      setTotalRecords(response.data.totalRecords);
      setIdeamartCount(response.data.totalRecordsIdeamart);
      setMspaceCount(response.data.totalRecordsMspace);
      setTodayCount(response.data.todayCount);
      // setRegisteredCount(response.data.totalRegisteredCount);
      // setNotRegisteredCount(response.data.totalNotRegisteredCount);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderType = (rowData) => {
    const typeLabel = rowData.type === "ideamart" ? "IDEAMART" : "MSPACE";
    const typeSeverity = rowData.type === "ideamart" ? "info" : "success";

    return <Tag value={typeLabel} severity={typeSeverity} />;
  };

  const renderStatus = (rowData) => {
    const statusLabel =
      rowData.status === "registered" ? "Registered" : "Not Registered";
    const statusSeverity =
      rowData.status === "not-registered" ? "danger" : "success";

    return <Tag value={statusLabel} severity={statusSeverity} />;
  };

  const formatDateForDisplay = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const subscriptionTypes = [
    { label: "IDEAMART", value: "ideamart" },
    { label: "MSPACE", value: "mspace" },
  ];

  // const subscriptionStatuses = [
  //   { label: "Registered", value: "registered" },
  //   { label: "Not Registered", value: "not-registered" },
  // ];

  const handleClearAllFilters = () => {
    setType(null);
    // setStatus(null);
    setFromDate(null);
    setToDate(null);
  };

  return (
    <>
      <h3
        style={{
          float: "left",
          color: "#808080",
        }}
      >
        Mobile User Subscriptions
      </h3>
      <br />
      <br />
      <br />
      <br />

      <div
        className="subscription-counts"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          height: "80px",
        }}
      >
        <Card
          style={{
            backgroundColor: "#eff3f8",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            color: "#708db6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>
              <b>Subscription Count</b>
            </span>
            &nbsp;&nbsp;
            <Badge
              value={totalRecords}
              size="large"
              severity="info"
              style={{ fontWeight: "bold" }}
            />
          </div>
        </Card>

        <Card
          style={{
            backgroundColor: "#eff3f8",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            color: "#708db6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>
              <b>IdeaMart Count</b>
            </span>
            &nbsp;&nbsp;
            <Badge
              value={ideamartCount}
              size="large"
              severity="info"
              style={{ fontWeight: "bold" }}
            />
          </div>
        </Card>

        <Card
          style={{
            backgroundColor: "#eff3f8",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            color: "#708db6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>
              <b>Mspace Count</b>
            </span>
            &nbsp;&nbsp;
            <Badge
              value={mspaceCount}
              size="large"
              severity="info"
              style={{ fontWeight: "bold" }}
            />
          </div>
        </Card>

        <Card
          style={{
            backgroundColor: "#eff3f8",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            color: "#708db6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>
              <b>Today Count</b>
            </span>
            &nbsp;&nbsp;
            <Badge
              value={todayCount}
              size="large"
              severity="success"
              style={{ fontWeight: "bold" }}
            />
          </div>
        </Card>

        {/* <Card
          style={{
            backgroundColor: "#eff3f8",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            color: "#708db6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>
              <b>Registered Count</b>
            </span>
            &nbsp;&nbsp;
            <Badge
              value={registeredCount}
              size="large"
              severity="info"
              style={{ fontWeight: "bold" }}
            />
          </div>
        </Card>

        <Card
          style={{
            backgroundColor: "#eff3f8",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            color: "#708db6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>
              <b>Not Registered Count</b>
            </span>
            &nbsp;&nbsp;
            <Badge
              value={notRegisteredCount}
              size="large"
              severity="info"
              style={{ fontWeight: "bold" }}
            />
          </div>
        </Card> */}
      </div>

      <br />
      <br />

      <div
        className="filters"
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "50px",
        }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Dropdown
            value={type}
            options={subscriptionTypes}
            onChange={(e) => setType(e.value)}
            placeholder="Filter by Type"
            showClear
          />
          {/* <Dropdown
            value={status}
            options={subscriptionStatuses}
            onChange={(e) => setStatus(e.value)}
            placeholder="Filter by Status"
            showClear
          /> */}
          <Calendar
            value={fromDate}
            onChange={(e) => setFromDate(e.value)}
            placeholder="From Date"
            dateFormat="yy-mm-dd"
            maxDate={today}
          />
          <Calendar
            value={toDate}
            onChange={(e) => setToDate(e.value)}
            placeholder="To Date"
            dateFormat="yy-mm-dd"
            maxDate={today}
          />
          <Button
            icon="pi pi-filter-slash"
            className="p-button-rounded"
            style={{
              background: "none",
              border: "none",
              borderColor: "#708db6",
              color: "#708db6",
              fontSize: "0.5rem",
            }}
            onClick={handleClearAllFilters}
          />
        </div>
      </div>

      <div className="table-container">
        <DataTable value={subscriptions} style={{ marginBottom: "50px" }}>
          <Column field="country" header="Country" />
          <Column field="userName" header="Name" />
          <Column field="phone" header="Phone" />
          <Column field="type" header="Type" body={renderType} />
          <Column
            field="registeredDate"
            header="Registered Date"
            body={(rowData) => formatDateForDisplay(rowData.registeredDate)}
          />
          <Column field="status" header="Status" body={renderStatus} />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          fetchAllSubscriptions(e.first / e.rows + 1, e.rows);
        }}
      />
      <SubscriptionLineChart />
    </>
  );
};

export default MobileUserSubscriptionTable;
