import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload } from "primereact/fileupload";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import QuestionType from "../../types/QuestionType";
import config from "../../config";
import Resizer from "react-image-file-resizer";
import { Editor } from "primereact/editor";
import "../../app/App.css";

// Component for adding a question
const AddQuestionForm = ({ question }) => {
  const [formData, setFormData] = useState(
    question || {
      syllabusId: "",
      mediumId: null,
      gradeId: "",
      subjectId: "",
      paperTypeId: "",
      paperInfoId: "",
      questionType: "",
      questionTitle: "",
      questionImage: null,
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      optionE: "",
      answer: "",
      optionImageA: null,
      optionImageB: null,
      optionImageC: null,
      optionImageD: null,
      optionImageE: null,
      note: "",
    }
  );
  const [visible, setVisible] = useState(false);
  const [syllabuses, setSyllabuses] = useState([]);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [grades, setGrades] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [paperTypeOptions, setPaperTypeOptions] = useState([]);
  const [paperInfos, setPaperInfos] = useState([]);
  const [paperInfoOptions, setPaperInfoOptions] = useState([]);
  const questionImageRef = useRef(null);
  const optionImageARef = useRef(null);
  const optionImageBRef = useRef(null);
  const optionImageCRef = useRef(null);
  const optionImageDRef = useRef(null);
  const optionImageERef = useRef(null);
  const [selectedValues, setSelectedValues] = useState({
    syllabusId: "",
    mediumId: "",
    gradeId: "",
    subjectId: "",
    paperTypeId: "",
    paperInfoId: "",
  });
  const [loading, setLoading] = useState(false);
  const [addedQuestions, setAddedQuestions] = useState([]);
  const toast = useRef(null);

  const navigate = useNavigate();
  const navigateToQuestionTable = () => {
    navigate("/questions");
  };

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("all");
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/country`);
        setCountries(
          response.data.map((country) => ({
            label: country.name,
            value: country.name,
          }))
        );
      } catch (error) {
        console.error("Error fetching country list:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch country list",
        });
      }
    };

    fetchCountryData();
  }, []);

  const fetchSyllabusData = async (country = "all") => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let url = `${config.apiUrl}/syllabus/all-countries`;

      if (country !== "all") {
        if (country === "") {
          return;
        } else {
          url = `${config.apiUrl}/syllabus/by-country/${encodeURIComponent(
            country
          )}`;
        }
      }

      const response = await axios.get(url, headersConfig);
      const fetchedSyllabuses = response.data.syllabuses;
      setSyllabuses(fetchedSyllabuses);
      setSyllabusOptions(
        fetchedSyllabuses.map((syllabus) => ({
          label: syllabus.name,
          value: syllabus._id,
        }))
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        syllabusId: "",
      }));
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    }
  };

  useEffect(() => {
    if (selectedCountry === "all" || selectedCountry !== "") {
      fetchSyllabusData(selectedCountry);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (formData.syllabusId) {
      const fetchMediumData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const selectedSyllabus = formData.syllabusId;
          const response = await axios.get(
            `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
            headersConfig
          );
          const fetchedMediums = response.data.mediums;
          if (fetchedMediums && fetchedMediums.length > 0) {
            setMediums(fetchedMediums);
            setMediumOptions(
              fetchedMediums.map((medium) => ({
                label: medium.name,
                value: medium._id,
              }))
            );
          } else {
            setMediums([]);
            setMediumOptions([]);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setMediums([]);
            setMediumOptions([]);
          } else {
            console.error("Error fetching mediums:", error);
          }
        }
      };

      fetchMediumData();
    }
  }, [formData.syllabusId]);

  useEffect(() => {
    if (formData.syllabusId) {
      const fetchGradeData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const selectedSyllabus = formData.syllabusId;
          let url = `${config.apiUrl}/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

          if (formData.mediumId) {
            url += `&mediumId=${formData.mediumId}`;
          }

          const response = await axios.get(url, headersConfig);
          const fetchedGrades = response.data.grades;
          setGrades(fetchedGrades);
          setGradeOptions(
            fetchedGrades.map((grade) => ({
              label: grade.name,
              value: grade._id,
            }))
          );
        } catch (error) {
          console.error("Error fetching grades:", error);
        }
      };

      fetchGradeData();
    }
  }, [formData.syllabusId, formData.mediumId]);

  useEffect(() => {
    const fetchSubjectData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const selectedSyllabus = formData.syllabusId;
        let url = `${config.apiUrl}/subject/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

        if (formData.mediumId) {
          url += `&mediumId=${formData.mediumId}`;
        }
        const response = await axios.get(url, headersConfig);
        const fetchedSubjects = response.data.subjects;
        setSubjects(fetchedSubjects);
        setSubjectOptions(
          fetchedSubjects.map((subject) => ({
            label: subject.name,
            value: subject._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };
    if (formData.syllabusId) {
      fetchSubjectData();
    }
  }, [formData.syllabusId, formData.mediumId]);

  useEffect(() => {
    const fetchPaperTypeData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        let url = `${config.apiUrl}/paperType/byFilter?`;

        if (formData.syllabusId) {
          url += `syllabusId=${encodeURIComponent(formData.syllabusId)}`;
        }

        if (formData.mediumId !== null) {
          url += `&mediumId=${encodeURIComponent(formData.mediumId)}`;
        }

        if (formData.gradeId) {
          url += `&gradeId=${encodeURIComponent(formData.gradeId)}`;
        }

        if (formData.subjectId) {
          url += `&subjectId=${encodeURIComponent(formData.subjectId)}`;
        }

        const response = await axios.get(url, headersConfig);
        const fetchedPaperTypes = response.data.paperTypes;
        setPaperTypes(fetchedPaperTypes);
        setPaperTypeOptions(
          fetchedPaperTypes.map((paperType) => ({
            label: paperType.paperTypeName,
            value: paperType._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching paper types:", error);
      }
    };

    fetchPaperTypeData();
  }, [
    formData.syllabusId,
    formData.mediumId,
    formData.gradeId,
    formData.subjectId,
  ]);

  useEffect(() => {
    const fetchPaperInfoData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        let url = `${config.apiUrl}/paperInfo/byFilter?`;

        if (formData.syllabusId) {
          url += `syllabusId=${encodeURIComponent(formData.syllabusId)}`;
        }

        if (formData.mediumId !== null) {
          url += `&mediumId=${encodeURIComponent(formData.mediumId)}`;
        }

        if (formData.gradeId) {
          url += `&gradeId=${encodeURIComponent(formData.gradeId)}`;
        }

        if (formData.subjectId) {
          url += `&subjectId=${encodeURIComponent(formData.subjectId)}`;
        }

        if (formData.paperTypeId) {
          url += `&paperTypeId=${encodeURIComponent(formData.paperTypeId)}`;
        }

        const response = await axios.get(url, headersConfig);

        const fetchedPaperInfos = response.data.paperInfos;
        setPaperInfos(fetchedPaperInfos);
        setPaperInfoOptions(
          fetchedPaperInfos.map((paperInfo) => ({
            label: paperInfo.paperInfo,
            value: paperInfo._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching paper infos:", error);
      }
    };

    fetchPaperInfoData();
  }, [
    formData.syllabusId,
    formData.mediumId,
    formData.gradeId,
    formData.subjectId,
    formData.paperTypeId,
  ]);

  const allAnswerOptions = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" },
  ];

  const limitedAnswerOptions = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
  ];

  const answerOptions =
    formData.questionType === QuestionType.TRUE_FALSE
      ? limitedAnswerOptions
      : allAnswerOptions;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      optionA:
        name === "questionType" && value === QuestionType.TRUE_FALSE
          ? "True"
          : prevFormData.optionA,
      optionB:
        name === "questionType" && value === QuestionType.TRUE_FALSE
          ? "False"
          : prevFormData.optionB,
      optionC:
        name === "questionType" && value === QuestionType.TRUE_FALSE
          ? ""
          : prevFormData.optionC,
      optionD:
        name === "questionType" && value === QuestionType.TRUE_FALSE
          ? ""
          : prevFormData.optionD,
      optionE:
        name === "questionType" && value === QuestionType.TRUE_FALSE
          ? ""
          : prevFormData.optionE,
    }));
  };

  const handleEditorChange = (name, editorValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: editorValue.htmlValue,
    }));
  };

  const handleQuestionTitleChange = (editor) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      questionTitle: editor.htmlValue,
    }));
  };

  const handleFileUpload = (name, files) => {
    const file = files[0];
    Resizer.imageFileResizer(
      file,
      512,
      384,
      "png",
      100,
      0,
      (resizedFile) => {
        const imageUrl = URL.createObjectURL(resizedFile);
        setFormData({
          ...formData,
          [name]: resizedFile,
          [`${name}Preview`]: imageUrl,
          [`${name}EncryptedName`]: resizedFile.name,
        });
      },
      "file"
    );
  };

  const handleClearImage = (name) => {
    setFormData({
      ...formData,
      [name]: null,
      [`${name}Preview`]: null,
      [`${name}EncryptedName`]: null,
    });
    switch (name) {
      case "questionImage":
        questionImageRef.current.clear();
        break;
      case "optionImageA":
        optionImageARef.current.clear();
        break;
      case "optionImageB":
        optionImageBRef.current.clear();
        break;
      case "optionImageC":
        optionImageCRef.current.clear();
        break;
      case "optionImageD":
        optionImageDRef.current.clear();
        break;
      case "optionImageE":
        optionImageERef.current.clear();
        break;
      default:
        break;
    }
  };

  const handleClearAll = () => {
    setFormData({
      syllabusId: "",
      mediumId: "",
      gradeId: "",
      subjectId: "",
      paperTypeId: "",
      paperInfoId: "",
    });
    handleClearSpecific();
  };

  const handleClearSpecific = () => {
    setFormData({
      ...formData,
      questionType: "",
      questionTitle: "",
      questionImage: questionImageRef.current.clear(),
      questionImagePreview: null,
      questionImageEncryptedName: null,
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      optionE: "",
      optionImageA: null,
      optionImageAPreview: null,
      optionImageAEncryptedName: null,
      optionImageB: null,
      optionImageBPreview: null,
      optionImageBEncryptedName: null,
      optionImageC: null,
      optionImageCPreview: null,
      optionImageCEncryptedName: null,
      optionImageD: null,
      optionImageDPreview: null,
      optionImageDEncryptedName: null,
      optionImageE: null,
      optionImageEPreview: null,
      optionImageEEncryptedName: null,
      answer: "",
      note: "",
    });
  };

  const handleCountrySelection = (e) => {
    const value = e.target.value;
    setSelectedCountry(value);
    setShowCountryDropdown(value === "specific");
  };

  const validateMissingFields = () => {
    const missingFields = [];
    if (!formData.syllabusId) missingFields.push("Syllabus");
    if (!formData.gradeId) missingFields.push("Grade");
    if (!formData.subjectId) missingFields.push("Subject");
    if (!formData.paperTypeId) missingFields.push("Paper Type");
    if (!formData.paperInfoId) missingFields.push("Paper Info");
    if (!formData.questionType) missingFields.push("Question Type");
    if (!formData.questionTitle) missingFields.push("Question Title");
    if (!formData.answer) missingFields.push("Answer");
    if (
      formData.questionType === QuestionType.TRUE_FALSE &&
      (!formData.optionA || !formData.optionB)
    ) {
      missingFields.push("Option A and B");
    }
    if (
      formData.questionType === QuestionType.IMAGE &&
      (!formData.optionA ||
        !formData.optionB ||
        !formData.optionC ||
        !formData.optionD)
    ) {
      missingFields.push("Option Values");
    }
    if (
      formData.questionType === QuestionType.IMAGE &&
      !formData.questionImage
    ) {
      missingFields.push("Question Image");
    }
    if (
      formData.questionType === QuestionType.TEXT_ONLY &&
      (!formData.optionA ||
        !formData.optionB ||
        !formData.optionC ||
        !formData.optionD)
    ) {
      missingFields.push("Option Values");
    }
    if (
      formData.questionType === QuestionType.IMAGE_ANSWER &&
      (!formData.optionImageA ||
        !formData.optionImageB ||
        !formData.optionImageC ||
        !formData.optionImageD)
    ) {
      missingFields.push("Option Images");
    }
    return missingFields;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const missingFields = validateMissingFields();
      if (missingFields.length > 0) {
        const missingFieldsString = missingFields.join(", ");
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `Please fill the mandatory fields: ${missingFieldsString}.`,
        });
        return;
      }

      const token = localStorage.getItem("token");
      const userName = localStorage.getItem("userName");
      const formDataToSend = new FormData();
      const formDataKeys = [
        "syllabusId",
        "mediumId",
        "gradeId",
        "subjectId",
        "paperTypeId",
        "paperInfoId",
        "questionType",
        "questionTitle",
        "questionImage",
        "optionA",
        "optionB",
        "optionC",
        "optionD",
        "optionE",
        "answer",
        "note",
      ];
      formDataKeys.forEach((key) => {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
          if (
            key.startsWith("option") &&
            formData[`optionImage${key.charAt(6).toUpperCase()}`]
          ) {
            formDataToSend.append(
              key,
              formData[`optionImage${key.charAt(6).toUpperCase()}`]
            );
          }
        }
      });

      formDataToSend.append("addedBy", userName);

      const response = await axios.post(
        `${config.apiUrl}/question`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      handleClearAll();
      setSelectedValues({
        syllabusId: formData.syllabusId,
        mediumId: formData.mediumId,
        gradeId: formData.gradeId,
        subjectId: formData.subjectId,
        paperTypeId: formData.paperTypeId,
        paperInfoId: formData.paperInfoId,
      });

      handleClearSpecific();

      setVisible(false);
      setAddedQuestions((prevQuestions) => [
        ...prevQuestions,
        response.data.data,
      ]);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Question added successfully",
      });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const renderImage = (rowData) => {
    if (rowData.questionImage) {
      return (
        <img
          src={`${config.apiUrl}/uploads/${rowData.questionImage}`}
          alt={rowData.questionImage}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return null;
    }
  };

  const renderOption = (value) => {
    if (typeof value === "string" && /\.(png)$/.test(value)) {
      return (
        <img
          src={`${config.apiUrl}/uploads/${value}`}
          alt={`${value} Preview`}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else if (typeof value === "string") {
      return stripHtml(value);
    } else {
      return value;
    }
  };

  const stripHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const renderQuestionTitle = (rowData) => {
    if (typeof rowData.questionTitle === "string") {
      return stripHtml(rowData.questionTitle);
    } else {
      return JSON.stringify(rowData.questionTitle);
    }
  };

  const renderQuestionType = (rowData) => {
    switch (rowData.questionType) {
      case "truefalse":
        return "True False";
      case "textonly":
        return "Text Only";
      case "image":
        return "Image";
      case "imageanswer":
        return "Image Answer";
      default:
        return rowData.questionType;
    }
  };

  return (
    <>
      <div>
        <div>
          <h3
            style={{
              float: "left",
              color: "#808080",
            }}
          >
            Add Question
          </h3>
          <Button
            label="Questions"
            onClick={navigateToQuestionTable}
            style={{ float: "right" }}
            rounded
            text
            raised
          />
          <br />
          <br />
          <br />
          <br />
        </div>
        <div className="p-fluid">
          <div>
            <div style={{ marginBottom: "20px", fontWeight: "500" }}>
              <RadioButton
                id="allCountries"
                name="countryFilter"
                value="all"
                checked={selectedCountry === "all"}
                onChange={handleCountrySelection}
              />
              &nbsp;
              <label htmlFor="allCountries">All Countries</label>
              <RadioButton
                id="specificCountry"
                name="countryFilter"
                value="specific"
                checked={selectedCountry !== "all"}
                onChange={handleCountrySelection}
                style={{ marginLeft: "20px" }}
              />
              &nbsp;
              <label htmlFor="specificCountry">Specific Country</label>
            </div>
            {showCountryDropdown && (
              <div
                className="p-field"
                style={{
                  marginTop: "40px",
                  marginBottom: "20px",
                  width: "49%",
                }}
              >
                <label htmlFor="country">Country</label>
                <Dropdown
                  id="country"
                  name="country"
                  value={selectedCountry}
                  options={countries}
                  onChange={(e) => setSelectedCountry(e.value)}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select Country"
                  style={{ marginTop: "10px" }}
                  filter
                />
              </div>
            )}
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div
              className="p-field"
              style={{
                marginBottom: "20px",
                marginRight: "20px",
                width: "50%",
              }}
            >
              <label htmlFor="syllabusId">
                Syllabus<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
              <Dropdown
                id="syllabusId"
                name="syllabusId"
                value={formData.syllabusId}
                options={syllabusOptions}
                onChange={handleChange}
                optionLabel="label"
                optionValue="value"
                placeholder="Select Syllabus"
                style={{ marginTop: "10px" }}
              />
            </div>
            <div
              className="p-field"
              style={{ marginBottom: "20px", width: "50%" }}
            >
              <label htmlFor="mediumId">Medium</label>
              <Dropdown
                id="mediumId"
                name="mediumId"
                value={formData.mediumId}
                options={mediumOptions}
                onChange={handleChange}
                optionLabel="label"
                optionValue="value"
                placeholder="Select Medium"
                style={{ marginTop: "10px" }}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="p-field"
              style={{
                marginBottom: "20px",
                marginRight: "20px",
                width: "50%",
              }}
            >
              <label htmlFor="gradeId">
                Grade<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
              <Dropdown
                id="gradeId"
                name="gradeId"
                value={formData.gradeId}
                options={gradeOptions}
                onChange={handleChange}
                optionLabel="label"
                optionValue="value"
                placeholder="Select Grade"
                style={{ marginTop: "10px" }}
              />
            </div>
            <div
              className="p-field"
              style={{ marginBottom: "20px", width: "50%" }}
            >
              <label htmlFor="subjectId">
                Subject<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
              <Dropdown
                id="subjectId"
                name="subjectId"
                value={formData.subjectId}
                options={subjectOptions}
                onChange={handleChange}
                optionLabel="label"
                optionValue="value"
                placeholder="Select Subject"
                style={{ marginTop: "10px" }}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="p-field"
              style={{
                marginBottom: "20px",
                marginRight: "20px",
                width: "50%",
              }}
            >
              <label htmlFor="paperTypeId">
                Paper Type<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
              <Dropdown
                id="paperTypeId"
                name="paperTypeId"
                value={formData.paperTypeId}
                options={paperTypeOptions}
                onChange={handleChange}
                optionLabel="label"
                optionValue="value"
                placeholder="Select Paper Type"
                style={{ marginTop: "10px" }}
              />
            </div>
            <div
              className="p-field"
              style={{
                marginBottom: "20px",
                width: "50%",
              }}
            >
              <label htmlFor="paperInfoId">
                Paper Info<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
              <Dropdown
                id="paperInfoId"
                name="paperInfoId"
                value={formData.paperInfoId}
                options={paperInfoOptions}
                onChange={handleChange}
                optionLabel="label"
                optionValue="value"
                placeholder="Select Paper Info"
                style={{ marginTop: "10px" }}
              />
            </div>
          </div>
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="questionType">
              Question Type<span style={{ color: "red" }}>&nbsp;*</span>
            </label>
            <br></br>
            <RadioButton
              id="questionType"
              name="questionType"
              value={QuestionType.TRUE_FALSE}
              onChange={handleChange}
              checked={formData.questionType === QuestionType.TRUE_FALSE}
              style={{ marginTop: "20px" }}
            />
            &nbsp;&nbsp;True False&nbsp;&nbsp;
            <RadioButton
              id="questionType"
              name="questionType"
              value={QuestionType.IMAGE}
              onChange={handleChange}
              checked={formData.questionType === QuestionType.IMAGE}
              style={{ marginTop: "20px" }}
            />
            &nbsp;&nbsp;Image&nbsp;&nbsp;
            <RadioButton
              id="questionType"
              name="questionType"
              value={QuestionType.TEXT_ONLY}
              onChange={handleChange}
              checked={formData.questionType === QuestionType.TEXT_ONLY}
              style={{ marginTop: "10px" }}
            />
            &nbsp;&nbsp;Text Only&nbsp;&nbsp;
            <RadioButton
              id="questionType"
              name="questionType"
              value={QuestionType.IMAGE_ANSWER}
              onChange={handleChange}
              checked={formData.questionType === QuestionType.IMAGE_ANSWER}
              style={{ marginTop: "10px" }}
            />
            &nbsp;&nbsp;Image Answer
          </div>
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="questionTitle">
              Question Title<span style={{ color: "red" }}>&nbsp;*</span>
            </label>
            <br />
            <br />
            <Editor
              id="questionTitle"
              name="questionTitle"
              value={formData.questionTitle}
              onTextChange={handleQuestionTitleChange}
              style={{ marginTop: "10px", width: "100%" }}
            />
          </div>
          {formData.questionType !== QuestionType.TEXT_ONLY && (
            <div className="p-field" style={{ marginBottom: "20px" }}>
              <label htmlFor="questionImage">
                Question Image&nbsp;
                {formData.questionType === QuestionType.IMAGE_ANSWER && (
                  <span style={{ fontSize: "small" }}>(optional)</span>
                )}
                {formData.questionType === QuestionType.TRUE_FALSE && (
                  <span style={{ fontSize: "small" }}>(optional)</span>
                )}
              </label>
              <FileUpload
                ref={questionImageRef}
                name="questionImage"
                accept="image/*"
                chooseLabel="Upload"
                uploadLabel="Upload"
                mode="basic"
                customUpload
                onSelect={(e) => handleFileUpload("questionImage", e.files)}
                style={{ marginTop: "10px" }}
              />
              {formData[`questionImagePreview`] && (
                <>
                  <img
                    src={formData[`questionImagePreview`]}
                    alt={`QuestionImage Preview`}
                    style={{ marginTop: "10px", maxWidth: "150px" }}
                  />
                  <i
                    className="pi pi-times-circle"
                    onClick={() => handleClearImage("questionImage")}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      verticalAlign: "middle",
                      fontSize: "1rem",
                      color: "#bb2124",
                    }}
                  ></i>
                </>
              )}
            </div>
          )}
          {formData.questionType !== QuestionType.IMAGE_ANSWER && (
            <>
              <div style={{ display: "flex" }}>
                <div
                  className="p-field"
                  style={{
                    marginBottom: "20px",
                    marginRight: "20px",
                    width: "50%",
                  }}
                >
                  <label htmlFor="optionA">Option A</label> <br />
                  <br />
                  <Editor
                    id="optionA"
                    name="optionA"
                    value={formData.optionA}
                    onTextChange={(e) => handleEditorChange("optionA", e)}
                    style={{ marginTop: "10px" }}
                  />
                </div>
                <div
                  className="p-field"
                  style={{
                    marginBottom: "20px",
                    width: "50%",
                  }}
                >
                  <label htmlFor="optionB">Option B</label> <br />
                  <br />
                  <Editor
                    id="optionB"
                    name="optionB"
                    value={formData.optionB}
                    onTextChange={(e) => handleEditorChange("optionB", e)}
                    style={{ marginTop: "10px" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {formData.questionType !== QuestionType.TRUE_FALSE && (
                  <div
                    className="p-field"
                    style={{
                      marginBottom: "20px",
                      marginRight: "20px",
                      width: "50%",
                    }}
                  >
                    <label htmlFor="optionC">Option C</label> <br />
                    <br />
                    <Editor
                      id="optionC"
                      name="optionC"
                      value={formData.optionC}
                      onTextChange={(e) => handleEditorChange("optionC", e)}
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                )}

                {formData.questionType !== QuestionType.TRUE_FALSE && (
                  <div
                    className="p-field"
                    style={{
                      marginBottom: "20px",
                      width: "50%",
                    }}
                  >
                    <label htmlFor="optionD">Option D</label> <br />
                    <br />
                    <Editor
                      id="optionD"
                      name="optionD"
                      value={formData.optionD}
                      onTextChange={(e) => handleEditorChange("optionD", e)}
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                )}
              </div>
              <div style={{ display: "flex" }}>
                {formData.questionType !== QuestionType.TRUE_FALSE && (
                  <div
                    className="p-field"
                    style={{ marginBottom: "20px", width: "50%" }}
                  >
                    <label htmlFor="optionE">
                      Option E&nbsp;
                      <span style={{ fontSize: "small" }}>(optional)</span>
                    </label>{" "}
                    <br />
                    <br />
                    <Editor
                      id="optionE"
                      name="optionE"
                      value={formData.optionE}
                      onTextChange={(e) => handleEditorChange("optionE", e)}
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {formData.questionType === QuestionType.IMAGE_ANSWER && (
            <>
              <div style={{ marginTop: "20px", display: "flex" }}>
                <div
                  className="p-field"
                  style={{
                    marginBottom: "20px",
                    marginRight: "20px",
                  }}
                >
                  <label htmlFor="optionImageA">Option Image A</label>
                  <FileUpload
                    ref={optionImageARef}
                    name="optionImageA"
                    accept="image/*"
                    chooseLabel="Upload"
                    uploadLabel="Upload"
                    mode="basic"
                    customUpload
                    onSelect={(e) => handleFileUpload("optionImageA", e.files)}
                    style={{ marginTop: "10px" }}
                  />
                  {formData[`optionImageAPreview`] && (
                    <>
                      <img
                        src={formData[`optionImageAPreview`]}
                        alt={`OptionImageA Preview`}
                        style={{ marginTop: "10px", maxWidth: "150px" }}
                      />
                      <i
                        className="pi pi-times-circle"
                        onClick={() => handleClearImage("optionImageA")}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          verticalAlign: "middle",
                          fontSize: "1rem",
                          color: "#bb2124",
                        }}
                      ></i>
                    </>
                  )}
                </div>
                <div
                  className="p-field"
                  style={{
                    marginBottom: "20px",
                    marginRight: "20px",
                  }}
                >
                  <label htmlFor="optionImageB">Option Image B</label>
                  <FileUpload
                    ref={optionImageBRef}
                    name="optionImageB"
                    accept="image/*"
                    chooseLabel="Upload"
                    uploadLabel="Upload"
                    mode="basic"
                    customUpload
                    onSelect={(e) => handleFileUpload("optionImageB", e.files)}
                    style={{ marginTop: "10px" }}
                  />
                  {formData[`optionImageBPreview`] && (
                    <>
                      <img
                        src={formData[`optionImageBPreview`]}
                        alt={`OptionImageB Preview`}
                        style={{ marginTop: "10px", maxWidth: "150px" }}
                      />
                      <i
                        className="pi pi-times-circle"
                        onClick={() => handleClearImage("optionImageB")}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          verticalAlign: "middle",
                          fontSize: "1rem",
                          color: "#bb2124",
                        }}
                      ></i>
                    </>
                  )}
                </div>
                <div
                  className="p-field"
                  style={{
                    marginBottom: "20px",
                    marginRight: "20px",
                  }}
                >
                  <label htmlFor="optionImageC">Option Image C</label>
                  <FileUpload
                    ref={optionImageCRef}
                    name="optionImageC"
                    accept="image/*"
                    chooseLabel="Upload"
                    uploadLabel="Upload"
                    mode="basic"
                    customUpload
                    onSelect={(e) => handleFileUpload("optionImageC", e.files)}
                    style={{ marginTop: "10px" }}
                  />
                  {formData[`optionImageCPreview`] && (
                    <>
                      <img
                        src={formData[`optionImageCPreview`]}
                        alt={`OptionImageC Preview`}
                        style={{ marginTop: "10px", maxWidth: "150px" }}
                      />
                      <i
                        className="pi pi-times-circle"
                        onClick={() => handleClearImage("optionImageC")}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          verticalAlign: "middle",
                          fontSize: "1rem",
                          color: "#bb2124",
                        }}
                      ></i>
                    </>
                  )}
                </div>
                <div
                  className="p-field"
                  style={{
                    marginBottom: "20px",
                    marginRight: "20px",
                  }}
                >
                  <label htmlFor="optionImageD">Option Image D</label>
                  <FileUpload
                    ref={optionImageDRef}
                    name="optionImageD"
                    accept="image/*"
                    chooseLabel="Upload"
                    uploadLabel="Upload"
                    mode="basic"
                    customUpload
                    onSelect={(e) => handleFileUpload("optionImageD", e.files)}
                    style={{ marginTop: "10px" }}
                  />
                  {formData[`optionImageDPreview`] && (
                    <>
                      <img
                        src={formData[`optionImageDPreview`]}
                        alt={`OptionImageD Preview`}
                        style={{ marginTop: "10px", maxWidth: "150px" }}
                      />
                      <i
                        className="pi pi-times-circle"
                        onClick={() => handleClearImage("optionImageD")}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          verticalAlign: "middle",
                          fontSize: "1rem",
                          color: "#bb2124",
                        }}
                      ></i>
                    </>
                  )}
                </div>
                <div className="p-field" style={{ marginBottom: "20px" }}>
                  <label htmlFor="optionImageE">
                    Option Image E&nbsp;
                    <span style={{ fontSize: "small" }}>(optional)</span>
                  </label>
                  <FileUpload
                    ref={optionImageERef}
                    name="optionImageE"
                    accept="image/*"
                    chooseLabel="Upload"
                    uploadLabel="Upload"
                    mode="basic"
                    customUpload
                    onSelect={(e) => handleFileUpload("optionImageE", e.files)}
                    style={{ marginTop: "10px" }}
                  />
                  {formData[`optionImageEPreview`] && (
                    <>
                      <img
                        src={formData[`optionImageEPreview`]}
                        alt={`OptionImageE Preview`}
                        style={{ marginTop: "10px", maxWidth: "150px" }}
                      />
                      <i
                        className="pi pi-times-circle"
                        onClick={() => handleClearImage("optionImageE")}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          verticalAlign: "middle",
                          fontSize: "1rem",
                          color: "#bb2124",
                        }}
                      ></i>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="answer">
              Answer<span style={{ color: "red" }}>&nbsp;*</span>
            </label>
            <Dropdown
              id="answer"
              name="answer"
              value={formData.answer}
              options={answerOptions}
              onChange={handleChange}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Answer"
              style={{ marginTop: "10px" }}
            />
          </div>
          <div className="p-field" style={{ marginBottom: "40px" }}>
            <label htmlFor="note">Note</label>
            <InputTextarea
              id="note"
              name="note"
              value={formData.note}
              onChange={handleChange}
              rows={3}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div
          className="p-dialog-footer"
          style={{ marginBottom: "40px", float: "left" }}
        >
          <Button
            label="Clear All"
            onClick={handleClearAll}
            severity="info"
            outlined
          />
        </div>
        <div
          className="p-dialog-footer"
          style={{ marginBottom: "50px", float: "right" }}
        >
          <Button
            label="Cancel"
            onClick={navigateToQuestionTable}
            severity="danger"
            outlined
          />{" "}
          &nbsp;
          <Button
            label={
              loading ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  Saving...&nbsp;&nbsp;
                  <ProgressSpinner
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                </div>
              ) : (
                "Save"
              )
            }
            onClick={handleSubmit}
            severity="success"
            outlined
            disabled={loading}
          />
        </div>
      </div>
      <Toast ref={toast} />

      {addedQuestions.length > 0 && (
        <>
          <br />
          <br />
          <br />
          <br />
          <h3
            style={{
              float: "left",
              color: "#808080",
            }}
          >
            Newly Added Questions
          </h3>
          <div style={{ marginTop: "100px", marginBottom: "100px" }}>
            <DataTable value={addedQuestions}>
              <Column
                field="questionType"
                header="Question Type"
                body={renderQuestionType}
              />
              <Column
                field="questionTitle"
                header="Question Title"
                body={renderQuestionTitle}
              />
              <Column
                field="questionImage"
                header="Question Image"
                body={renderImage}
              />
              <Column
                field="optionA"
                header="Option A"
                body={(rowData) => renderOption(rowData.optionA)}
              />
              <Column
                field="optionB"
                header="Option B"
                body={(rowData) => renderOption(rowData.optionB)}
              />
              <Column
                field="optionC"
                header="Option C"
                body={(rowData) => renderOption(rowData.optionC)}
              />
              <Column
                field="optionD"
                header="Option D"
                body={(rowData) => renderOption(rowData.optionD)}
              />
              <Column
                field="optionE"
                header="Option E"
                body={(rowData) => renderOption(rowData.optionE)}
              />
              <Column field="answer" header="Answer" />
              <Column field="note" header="Note" />
            </DataTable>
          </div>
        </>
      )}
    </>
  );
};

export default AddQuestionForm;
