import React from "react";
import { useNavigate } from "react-router-dom";
import { PanelMenu } from "primereact/panelmenu";
import { Menubar } from "primereact/menubar";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions";
import logo from "../../assests/logo.png";

const AppMenu = ({ panelMenuVisible, setPanelMenuVisible }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const largeScreen = window.innerWidth >= 1920;
  const currentUserType = localStorage.getItem("userType");
  const isAdmin = currentUserType === "admin";

  const handleSignOutClick = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleSettings = () => {
    navigate("/web-settings");
  };

  const handleProfile = () => {
    navigate("/me");
  };

  const menuItems = [
    {
      label: "Dashboard",
      icon: "pi pi-home",
      command: () => navigate("/dashboard"),
    },
    {
      label: "Questions",
      icon: "pi pi-question",
      command: () => navigate("/questions"),
    },
    {
      label: "All Papers",
      icon: "pi pi-fw pi-folder-open",
      command: () => navigate("/all-papers"),
    },
    {
      label: "Settings",
      icon: "pi pi-server",
      expanded: false,
      items: [
        {
          label: "Syllabus",
          icon: "pi pi-fw pi-book",
          command: () => navigate("/syllabus"),
        },
        {
          label: "Medium",
          icon: "pi pi-fw pi-globe",
          command: () => navigate("/medium"),
        },
        {
          label: "Grade",
          icon: "pi pi-fw pi-clone",
          command: () => navigate("/grade"),
        },
        {
          label: "Subject",
          icon: "pi pi-fw pi-bookmark",
          command: () => navigate("/subject"),
        },
        {
          label: "Paper Type",
          icon: "pi pi-fw pi-file",
          command: () => navigate("/paper-type"),
        },
        {
          label: "Paper Sub Info",
          icon: "pi pi-fw pi-info-circle",
          command: () => navigate("/paper-info"),
        },
        {
          label: "Copy IDs",
          icon: "pi pi-clone",
          command: () => navigate("/copy-ids"),
          visible: isAdmin,
        },
      ],
    },
    {
      label: "Ad Banners",
      icon: "pi pi-images",
      command: () => navigate("/banners"),
      visible: isAdmin,
    },
    {
      label: "System Configuration",
      icon: "pi pi-cog",
      expanded: false,
      items: [
        {
          label: "App Settings",
          icon: "pi pi-fw pi-wrench",
          command: () => navigate("/system-configs"),
        },
        {
          label: "Country List Updates",
          icon: "pi pi-map-marker",
          command: () => navigate("/country"),
        },
        {
          label: "App Data",
          icon: "pi pi-slack",
          command: () => navigate("/app-data"),
        },
      ],
      visible: isAdmin,
    },
    {
      label: "Admin Users",
      icon: "pi pi-users",
      expanded: false,
      items: [
        {
          label: "User Registration",
          icon: "pi pi-fw pi-user",
          command: () => navigate("/add-user"),
        },
        {
          label: "User List",
          icon: "pi pi-fw pi-list",
          command: () => navigate("/users"),
        },
      ],
      visible: isAdmin,
    },
    {
      label: "Mobile Users",
      icon: "pi pi-users",
      expanded: false,
      items: [
        {
          label: "Mobile User List",
          icon: "pi pi-fw pi-user",
          command: () => navigate("/mobile-users"),
        },
        {
          label: "Mobile User Criterias",
          icon: "pi pi-fw pi-list",
          command: () => navigate("/mobile-user-criterias"),
        },
        {
          label: "Subscriptions",
          icon: "pi pi-fw pi-wallet",
          command: () => navigate("/mobile-user-subscriptions"),
        },
        {
          label: "Payment Gateway Info",
          icon: "pi pi-fw pi-credit-card",
          command: () => navigate("/mobile-user-payment-gateway"),
        },
      ],
      visible: isAdmin,
    },
    {
      label: "Insights",
      icon: "pi pi-chart-bar",
      command: () => navigate("/insights"),
    },
  ];

  const start = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={logo}
        alt="Logo"
        style={{
          height: "30px",
          marginLeft: "16px",
        }}
      />
      <i
        className={classNames("pi pi-bars p-menubar-toggler", {
          "p-highlight": panelMenuVisible,
        })}
        style={{
          cursor: "pointer",
          marginLeft: "6.8rem",
          color: "#708db6",
          fontSize: "22px",
        }}
        onClick={() => setPanelMenuVisibleLocal(!panelMenuVisible)}
      />
    </div>
  );

  const end = (
    <div>
      <i
        className="pi pi-cog"
        style={{
          color: "#708db6",
          cursor: "pointer",
          fontSize: "larger",
          marginRight: "40px",
        }}
        onClick={handleSettings}
      />
      <i
        className="pi pi-user"
        style={{
          color: "#708db6",
          cursor: "pointer",
          fontSize: "larger",
          marginRight: "40px",
        }}
        onClick={handleProfile}
      />
      <i
        className="pi pi-sign-out"
        style={{
          color: "#708db6",
          cursor: "pointer",
          fontSize: "larger",
          marginRight: "50px",
        }}
        onClick={handleSignOutClick}
      />
    </div>
  );

  const setPanelMenuVisibleLocal = (visible) => {
    setPanelMenuVisible(visible);
  };

  return (
    <div
      className="app-menu"
      style={{
        width: "260px",
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        zIndex: 1000,
      }}
    >
      <Menubar
        start={start}
        end={end}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          background: "ghostwhite",
          height: "12vh",
        }}
      />
      <PanelMenu
        model={menuItems.filter((item) => item.visible !== false)}
        style={{
          height: "calc(100vh - 12vh)",
          marginTop: largeScreen ? "150px" : "100px",
          marginLeft: "20px",
          paddingBottom: "50px",
          width: panelMenuVisible ? "260px" : "0px",
          transition: "width 0.1s ease",
          overflowY: panelMenuVisible ? "auto" : "hidden",
          overflowX: "hidden",
        }}
      />
    </div>
  );
};

export default AppMenu;
