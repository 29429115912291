import React from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from "primereact/paginator";
import config from "../../config";

// Table component for displaying banners
const BannerTable = ({
  banners,
  totalRecords,
  first,
  setFirst,
  rows,
  setRows,
  onPageChange,
  loading,
  selectedBanner,
  handleEdit,
  handleDelete,
}) => {
  const renderVisibility = (rowData) => {
    return <span>{rowData.visibility ? "Yes" : "No"}</span>;
  };

  const renderType = (rowData) => {
    const typeLabel = rowData.type === "banner" ? "Banner" : "Advertisement";
    const typeSeverity = rowData.type === "banner" ? "info" : "success";

    return <Tag value={typeLabel} severity={typeSeverity} />;
  };

  const renderImage = (rowData) => {
    if (rowData.image && typeof rowData.image === "string") {
      return (
        <img
          src={
            rowData.image.startsWith("http")
              ? rowData.image
              : `${config.apiUrl}/uploads/${rowData.image}`
          }
          alt={rowData.name}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return null;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const navigate = useNavigate();
  const navigateToAddBanner = () => {
    navigate("/add-banner");
  };

  return (
    <>
      <h3 style={{ float: "left", color: "#808080", marginTop: "20px" }}>
        Ad Banners
      </h3>
      <Button
        label="+ New Ad Banner"
        onClick={navigateToAddBanner}
        style={{ float: "right", marginTop: "10px" }}
        rounded
        text
        raised
      />
      <br />
      <br />
      <br />
      <br />
      <DataTable
        value={banners}
        selectionMode="single"
        selection={selectedBanner}
        style={{ marginBottom: "50px", marginTop: "10px" }}
      >
        <Column field="syllabus.name" header="Syllabus" />
        <Column field="medium.name" header="Medium" />
        <Column field="grade.name" header="Grade" />

        <Column
          field="expireDate"
          header="Expire Date"
          body={(rowData) => formatDate(rowData.expireDate)}
        />
        <Column field="type" header="Type" body={renderType} />
        <Column
          field="visibility"
          header="Visibility"
          body={renderVisibility}
        />
        <Column field="image" header="Image" body={renderImage} />
        <Column
          header="Actions"
          body={(rowData) => (
            <div>
              <Button
                icon="pi pi-pencil"
                className="p-button-rounded p-button-success"
                onClick={() => handleEdit(rowData)}
              />
              &nbsp;&nbsp;
              <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger"
                onClick={() => handleDelete(rowData)}
              />
            </div>
          )}
        />
      </DataTable>
      <center>
        {loading && (
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="5"
            animationDuration=".5s"
          />
        )}
      </center>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          onPageChange(e);
        }}
      />
    </>
  );
};

export default BannerTable;
