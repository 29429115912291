import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { ProgressSpinner } from "primereact/progressspinner";
import config from "../../config";

// Component for adding an ad banner
const AddBannerForm = ({ banner }) => {
  const [formData, setFormData] = useState(
    banner || {
      syllabusId: "",
      mediumId: null,
      gradeId: "",
      image: "",
      expireDate: "",
      visibility: true,
      type: "banner",
    }
  );

  const [syllabuses, setSyllabuses] = useState([]);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [grades, setGrades] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const fileUploadRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  const navigate = useNavigate();
  const navigateToBannerTable = () => {
    navigate("/banners");
  };

  const today = new Date();

  useEffect(() => {
    const fetchSyllabusData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${config.apiUrl}/syllabus`,
          headersConfig
        );
        const fetchedSyllabuses = response.data.syllabuses;
        setSyllabuses(fetchedSyllabuses);
        setSyllabusOptions(
          fetchedSyllabuses.map((syllabus) => ({
            label: syllabus.name,
            value: syllabus._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching syllabuses:", error);
      }
    };

    fetchSyllabusData();
  }, []);

  useEffect(() => {
    if (formData.syllabusId) {
      const fetchMediumData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const selectedSyllabus = formData.syllabusId;
          const response = await axios.get(
            `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
            headersConfig
          );
          const fetchedMediums = response.data.mediums;
          if (fetchedMediums && fetchedMediums.length > 0) {
            setMediums(fetchedMediums);
            setMediumOptions(
              fetchedMediums.map((medium) => ({
                label: medium.name,
                value: medium._id,
              }))
            );
          } else {
            setMediums([]);
            setMediumOptions([]);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setMediums([]);
            setMediumOptions([]);
          } else {
            console.error("Error fetching mediums:", error);
          }
        }
      };

      fetchMediumData();
    }
  }, [formData.syllabusId]);

  useEffect(() => {
    if (formData.syllabusId) {
      const fetchGradeData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const selectedSyllabus = formData.syllabusId;
          let url = `${config.apiUrl}/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

          if (formData.mediumId) {
            url += `&mediumId=${formData.mediumId}`;
          }

          const response = await axios.get(url, headersConfig);
          const fetchedGrades = response.data.grades;
          setGrades(fetchedGrades);
          setGradeOptions(
            fetchedGrades.map((grade) => ({
              label: grade.name,
              value: grade._id,
            }))
          );
        } catch (error) {
          console.error("Error fetching grades:", error);
        }
      };

      fetchGradeData();
    }
  }, [formData.syllabusId, formData.mediumId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (name, files) => {
    const file = files[0];
    const imageUrl = URL.createObjectURL(file);
    setFormData({
      ...formData,
      [name]: file,
      [`${name}Preview`]: imageUrl,
      [`${name}EncryptedName`]: file.name,
    });
  };

  const handleClearImage = () => {
    setFormData({
      ...formData,
      image: null,
      imagePreview: null,
      imageEncryptedName: null,
    });
    fileUploadRef.current.clear();
  };

  const handleDateChange = (e) => {
    const selectedDate = e.value;
    setFormData({
      ...formData,
      expireDate: selectedDate,
    });
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("syllabusId", formData.syllabusId);
      if (formData.mediumId !== null) {
        formDataToSend.append("mediumId", formData.mediumId);
      }
      formDataToSend.append("gradeId", formData.gradeId);
      formDataToSend.append("expireDate", formData.expireDate);
      formDataToSend.append("visibility", formData.visibility);
      formDataToSend.append("type", formData.type);

      if (formData.image) {
        formDataToSend.append("image", formData.image);
      }

      const response = await axios.post(
        `${config.apiUrl}/banner`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Paper type added successfully",
      });
      navigateToBannerTable();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        label="Ad Banners"
        onClick={navigateToBannerTable}
        style={{ float: "right" }}
        rounded
        text
        raised
      />
      <br />
      <br />
      <br />
      <br />
      <div className="p-fluid">
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="syllabusId">Syllabus</label>
            <Dropdown
              id="syllabusId"
              name="syllabusId"
              value={formData.syllabusId}
              options={syllabusOptions}
              onChange={handleChange}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Syllabus"
              style={{ marginTop: "10px", marginRight: "20px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="mediumId">Medium</label>
            <Dropdown
              id="mediumId"
              name="mediumId"
              value={formData.mediumId}
              options={mediumOptions}
              onChange={handleChange}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Medium"
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="gradeId">Grade</label>
            <Dropdown
              id="gradeId"
              name="gradeId"
              value={formData.gradeId}
              options={gradeOptions}
              onChange={handleChange}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Grade"
              style={{ marginTop: "10px", marginRight: "20px" }}
            />
          </div>

          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="expireDate">Expire Date</label>
            <Calendar
              id="expireDate"
              name="expireDate"
              value={formData.expireDate}
              onChange={handleDateChange}
              showIcon
              minDate={today}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "50px", width: "50%" }}
          >
            <label htmlFor="type">Type</label>
            <br></br>
            <RadioButton
              id="type"
              name="type"
              value={"banner"}
              onChange={handleChange}
              checked={formData.type === "banner"}
              style={{ marginTop: "10px" }}
            />{" "}
            Banner &nbsp;
            <RadioButton
              id="type"
              name="type"
              value={"advertisment"}
              onChange={handleChange}
              checked={formData.type === "advertisment"}
              style={{ marginTop: "10px" }}
            />{" "}
            Advertisment
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "50px", width: "50%" }}
          >
            <label htmlFor="visibility">Visibility</label>
            <br></br>
            <RadioButton
              id="visibility"
              name="visibility"
              value={true}
              onChange={handleChange}
              checked={formData.visibility === true}
              style={{ marginTop: "10px" }}
            />{" "}
            Yes &nbsp;
            <RadioButton
              id="visibility"
              name="visibility"
              value={false}
              onChange={handleChange}
              checked={formData.visibility === false}
              style={{ marginTop: "10px" }}
            />{" "}
            No
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="image">
              Image&nbsp;&nbsp;
              {formData.type === "banner" && (
                <small style={{ color: "#808080", fontStyle: "italic" }}>
                  (Square size, eg: 300 x 300)
                </small>
              )}
            </label>
            <FileUpload
              ref={fileUploadRef}
              name="image"
              accept="image/*"
              chooseLabel="Upload"
              uploadLabel="Upload"
              mode="basic"
              customUpload
              maxFileSize={1000000}
              onSelect={(e) => handleFileUpload("image", e.files)}
              style={{ marginTop: "10px", marginRight: "20px" }}
            />
            {formData.imagePreview && (
              <>
                <img
                  src={formData.imagePreview}
                  alt="Uploaded"
                  style={{ maxWidth: "150px", marginTop: "10px" }}
                />
                <i
                  className="pi pi-times-circle"
                  onClick={handleClearImage}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    verticalAlign: "middle",
                    fontSize: "1rem",
                    color: "#bb2124",
                  }}
                ></i>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="p-dialog-footer"
        style={{ float: "right", marginBottom: "20px" }}
      >
        <Button label="Cancel" severity="danger" outlined />
        &nbsp;&nbsp;
        <Button
          label={
            loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Saving...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Save"
            )
          }
          onClick={handleSubmit}
          severity="success"
          outlined
          disabled={loading}
        />
      </div>
      <Toast ref={toast} />
    </div>
  );
};

export default AddBannerForm;
