import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserTable from "./MobileUserTable";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import config from "../../config";

// Component for managing mobile user operations
const MobileUser = () => {
  const [users, setUsers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedUser, setSelectedUser] = useState(null);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [countryCounts, setCountryCounts] = useState([]);
  const navigate = useNavigate();
  const toast = useRef(null);

  const formatDateForAPI = (date, endOfDay = false) => {
    if (!date) return null;
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    const time = endOfDay ? "23:59:59.999Z" : "00:00:00.000Z";
    return `${year}-${month}-${day}T${time}`;
  };

  useEffect(() => {
    fetchAllUsers();
    fetchCountryCounts();
  }, [selectedCountry, selectedFromDate, selectedToDate]);

  const fetchAllUsers = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let requestUrl = `${config.apiUrl}/mobile/user?first=${first}&rows=${rows}`;
      if (selectedCountry) {
        requestUrl += `&country=${selectedCountry}`;
      }
      if (selectedFromDate) {
        requestUrl += `&fromDate=${formatDateForAPI(selectedFromDate)}`;
      }
      if (selectedToDate) {
        requestUrl += `&toDate=${formatDateForAPI(selectedToDate, true)}`;
      }

      const response = await axios.get(requestUrl, headersConfig);

      setUsers(response.data.users);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCountryCounts = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${config.apiUrl}/common/count-by-country`,
        headersConfig
      );
      setCountryCounts(response.data);
    } catch (error) {
      console.error("Error fetching mobile user counts by country:", error);
    }
  };

  const handleSelect = (user) => {
    setSelectedUser({ ...user, id: user._id });
    navigate(`/completed-levels`, { state: { selectedUser: user } });
  };

  const onPageChange = (event) => {
    fetchAllUsers(event.first / event.rows + 1, event.rows);
  };

  const handleDelete = (user) => {
    setUserToDelete({
      ...user,
      id: user._id,
    });
    setVisibleDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.apiUrl}/mobile/user/${userToDelete.id}`,
        headersConfig
      );
      setVisibleDeleteDialog(false);
      fetchAllUsers();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    }
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const handleFromDateChange = (e) => {
    setSelectedFromDate(e.value);
  };

  const handleToDateChange = (e) => {
    setSelectedToDate(e.value);
  };

  const handleClearFilter = (e) => {
    setSelectedCountry(null);
    setSelectedFromDate(null);
    setSelectedToDate(null);
  };

  return (
    <div>
      <UserTable
        users={users}
        totalRecords={totalRecords}
        first={first}
        setFirst={setFirst}
        rows={rows}
        setRows={setRows}
        onPageChange={onPageChange}
        loading={loading}
        selectedUser={selectedUser}
        handleSelect={handleSelect}
        handleDelete={handleDelete}
        handleCountryChange={handleCountryChange}
        handleFromDateChange={handleFromDateChange}
        handleToDateChange={handleToDateChange}
        handleClearFilter={handleClearFilter}
        selectedCountry={selectedCountry}
        selectedFromDate={selectedFromDate}
        selectedToDate={selectedToDate}
        countryCounts={countryCounts}
      />

      <ConfirmDialog
        visible={visibleDeleteDialog}
        onHide={() => setVisibleDeleteDialog(false)}
        message="Are you sure you want to delete this mobile user?"
        header="Confirm"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        rejectClassName="p-button-secondary"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmDelete}
        reject={() => setVisibleDeleteDialog(false)}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default MobileUser;
