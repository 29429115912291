import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import config from "../../../config";

// Table component for displaying paper info
const PaperInfoTable = ({
  paperInfos,
  totalRecords,
  first,
  setFirst,
  rows,
  setRows,
  onPageChange,
  loading,
  selectedPaperInfo,
  handleEdit,
  handleDelete,
  syllabusOptions,
  selectedSyllabus,
  handleSyllabusChange,
  selectedMedium,
  handleMediumChange,
  selectedGrade,
  handleGradeChange,
  selectedSubject,
  handleSubjectChange,
  selectedPaperType,
  handlePaperTypeChange,
  handleClearFilter,
}) => {
  const toast = useRef(null);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [paperTypeOptions, setPaperTypeOptions] = useState([]);
  const navigate = useNavigate();

  const navigateToAddPaperInfo = () => {
    navigate("/add-paper-info");
  };

  const fetchMediums = async (syllabusId) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/medium/bySyllabus?syllabusId=${syllabusId}`,
        headersConfig
      );
      const fetchedMediums = response.data.mediums;
      return fetchedMediums.map((medium) => ({
        label: medium.name,
        value: medium._id,
      }));
    } catch (error) {
      console.error("Error fetching mediums:", error);
      return [];
    }
  };

  useEffect(() => {
    if (selectedSyllabus) {
      const fetchData = async () => {
        const mediums = await fetchMediums(selectedSyllabus);
        setMediumOptions(mediums);
      };
      fetchData();
    }
  }, [selectedSyllabus]);

  const fetchGrades = async (syllabusId, mediumId) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let apiUrl = `${config.apiUrl}/grade/bySyllabusAndMedium`;
      const params = { syllabusId };

      if (mediumId) {
        params.mediumId = mediumId;
      }

      const response = await axios.get(apiUrl, {
        params,
        ...headersConfig,
      });

      const fetchedGrades = response.data.grades;
      return fetchedGrades.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  useEffect(() => {
    if (selectedSyllabus) {
      const fetchData = async () => {
        const grades = await fetchGrades(selectedSyllabus, selectedMedium);
        setGradeOptions(grades);
      };
      fetchData();
    }
  }, [selectedSyllabus, selectedMedium]);

  const fetchSubjects = async (syllabusId, mediumId) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let apiUrl = `${config.apiUrl}/subject/bySyllabusAndMedium`;
      const params = { syllabusId };

      if (mediumId) {
        params.mediumId = mediumId;
      }

      const response = await axios.get(apiUrl, {
        params,
        ...headersConfig,
      });

      const fetchedSubjects = response.data.subjects;
      return fetchedSubjects.map((subject) => ({
        label: subject.name,
        value: subject._id,
      }));
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  useEffect(() => {
    if (selectedSyllabus) {
      const fetchData = async () => {
        const subjects = await fetchSubjects(selectedSyllabus, selectedMedium);
        setSubjectOptions(subjects);
      };
      fetchData();
    }
  }, [selectedSyllabus, selectedMedium]);

  const fetchPaperTypes = async (syllabusId, mediumId, gradeId, subjectId) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let apiUrl = `${config.apiUrl}/paperType/byFilter`;
      const params = { syllabusId, gradeId, subjectId };

      if (mediumId) {
        params.mediumId = mediumId;
      }

      const response = await axios.get(apiUrl, {
        params,
        ...headersConfig,
      });

      const fetchPaperTypes = response.data.paperTypes;
      return fetchPaperTypes.map((paperType) => ({
        label: paperType.paperTypeName,
        value: paperType._id,
      }));
    } catch (error) {
      console.error("Error fetching paper types:", error);
    }
  };

  useEffect(() => {
    if ((selectedSyllabus, selectedGrade, selectedSubject)) {
      const fetchData = async () => {
        const paperTypes = await fetchPaperTypes(
          selectedSyllabus,
          selectedMedium,
          selectedGrade,
          selectedSubject
        );
        setPaperTypeOptions(paperTypes);
      };
      fetchData();
    }
  }, [selectedSyllabus, selectedMedium, selectedGrade, selectedSubject]);

  const renderVisibility = (rowData) => {
    return <span>{rowData.visibility ? "Yes" : "No"}</span>;
  };

  const renderImage = (rowData) => {
    if (rowData.image && typeof rowData.image === "string") {
      return (
        <img
          src={
            rowData.image.startsWith("http")
              ? rowData.image
              : `${config.apiUrl}/uploads/${rowData.image}`
          }
          alt={rowData.name}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return null;
    }
  };

  const handleCopyId = (id) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: `ID copied successfully!`,
    });
  };

  const filteredPaperInfos = paperInfos.filter((paperInfo) => {
    const syllabusMatch =
      !selectedSyllabus ||
      (paperInfo.syllabus && paperInfo.syllabus._id === selectedSyllabus);
    const mediumMatch =
      !selectedMedium ||
      (paperInfo.medium && paperInfo.medium._id === selectedMedium);
    const gradeMatch =
      !selectedGrade ||
      (paperInfo.grade && paperInfo.grade._id === selectedGrade);
    const subjectMatch =
      !selectedSubject ||
      (paperInfo.subject && paperInfo.subject._id === selectedSubject);
    const paperTypeMatch =
      !selectedPaperType ||
      (paperInfo.paperType && paperInfo.paperType._id === selectedPaperType);
    return (
      syllabusMatch &&
      mediumMatch &&
      gradeMatch &&
      subjectMatch &&
      paperTypeMatch
    );
  });

  return (
    <>
      <h3 style={{ float: "left", color: "#808080", marginTop: "20px" }}>
        Paper Infos
      </h3>
      <Button
        label="+ New Paper Info"
        onClick={navigateToAddPaperInfo}
        style={{ float: "right", marginTop: "10px" }}
        rounded
        text
        raised
      />
      <br />
      <br />
      <br />
      <br />
      <div className="table-container">
        <Dropdown
          value={selectedSyllabus}
          options={syllabusOptions}
          onChange={handleSyllabusChange}
          placeholder="Select Syllabus"
          optionLabel="label"
          optionValue="value"
          showClear
          onClear={() => handleClearFilter("syllabus.name", "syllabusId")}
        />
        &nbsp;&nbsp;
        <Dropdown
          value={selectedMedium}
          options={mediumOptions}
          onChange={handleMediumChange}
          placeholder="Select Medium"
          optionLabel="label"
          optionValue="value"
          disabled={!selectedSyllabus}
          showClear
          onClear={() => handleClearFilter("medium.name", "mediumId")}
        />
        &nbsp;&nbsp;
        <Dropdown
          value={selectedGrade}
          options={gradeOptions}
          onChange={handleGradeChange}
          placeholder="Select Grade"
          optionLabel="label"
          optionValue="value"
          disabled={!selectedSyllabus}
          showClear
          onClear={() => handleClearFilter("grade.name", "gradeId")}
        />
        &nbsp;&nbsp;
        <Dropdown
          value={selectedSubject}
          options={subjectOptions}
          onChange={handleSubjectChange}
          placeholder="Select Subject"
          optionLabel="label"
          optionValue="value"
          disabled={!selectedSyllabus}
          showClear
          onClear={() => handleClearFilter("subject.name", "subjectId")}
        />
        &nbsp;&nbsp;
        <Dropdown
          value={selectedPaperType}
          options={paperTypeOptions}
          onChange={handlePaperTypeChange}
          placeholder="Select Paper Type"
          optionLabel="label"
          optionValue="value"
          disabled={!selectedSyllabus}
          showClear
          onClear={() =>
            handleClearFilter("paperType.paperTypeName", "paperTypeId")
          }
        />
        &nbsp;&nbsp;
        <Button
          icon="pi pi-filter-slash"
          className="p-button-rounded"
          style={{
            background: "none",
            border: "none",
            borderColor: "#708db6",
            color: "#708db6",
            fontSize: "0.5rem",
          }}
          onClick={() => {
            handleClearFilter("syllabus.name", "syllabusId");
            handleClearFilter("medium.name", "mediumId");
            handleClearFilter("grade.name", "gradeId");
            handleClearFilter("subject.name", "subjectId");
            handleClearFilter("paperType.paperTypeName", "paperTypeId");
          }}
        />
        <br />
        <br />
        <DataTable
          value={filteredPaperInfos}
          selectionMode="single"
          selection={selectedPaperInfo}
          style={{ marginBottom: "50px" }}
        >
          <Column field="syllabus.name" header="Syllabus" />
          <Column field="medium.name" header="Medium" />
          <Column field="grade.name" header="Grade" />
          <Column field="subject.name" header="Subject" />
          <Column field="paperType.paperTypeName" header="Paper Type" />
          <Column field="paperInfo" header="Paper Info" />
          <Column field="image" header="Image" body={renderImage} />
          <Column
            field="visibility"
            header="Visibility"
            body={renderVisibility}
          />
          <Column
            header="Actions"
            body={(rowData) => (
              <div>
                <CopyToClipboard
                  text={rowData._id}
                  onCopy={() => handleCopyId(rowData._id)}
                >
                  <Button
                    icon="pi pi-copy"
                    className="p-button-rounded"
                    style={{
                      backgroundColor: "#aaaaaa",
                      borderColor: "#aaaaaa",
                      color: "#ffffff",
                    }}
                  />
                </CopyToClipboard>
                &nbsp;&nbsp;
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-success"
                  onClick={() => handleEdit(rowData)}
                />
                &nbsp;&nbsp;
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-danger"
                  onClick={() => handleDelete(rowData)}
                />
              </div>
            )}
          />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          onPageChange(e);
        }}
      />
      <Toast ref={toast} />
    </>
  );
};

export default PaperInfoTable;
