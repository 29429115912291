import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import UserTable from "./UserTable";
import EditUserForm from "./EditUserForm";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import UserStatus from "../../types/UserStatus";
import UserType from "../../types/UserType";
import config from "../../config";

// Component for managing user operations
const User = () => {
  const [users, setUsers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedUser, setSelectedUser] = useState(null);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editedUser, setEditedUser] = useState({
    country: "",
    name: "",
    email: "",
    phone: "",
    userType: "",
    userStatus: "",
  });
  const [countryList, setCountryList] = useState([]);
  const userTypeOptions = Object.keys(UserType).map((key) => ({
    label: UserType[key].charAt(0).toUpperCase() + UserType[key].slice(1),
    value: UserType[key],
  }));
  const userStatusOptions = Object.keys(UserStatus).map((key) => ({
    label: UserStatus[key].charAt(0).toUpperCase() + UserStatus[key].slice(1),
    value: UserStatus[key],
  }));
  const [userToDelete, setUserToDelete] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/user?first=${first}&rows=${rows}`,
        headersConfig
      );
      setUsers(response.data.users);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (event) => {
    fetchAllUsers(event.first / event.rows + 1, event.rows);
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/country`);
      setCountryList(
        response.data.map((country) => ({
          label: country.name,
          value: country.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching country list:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch country list",
      });
    }
  };

  const handleEdit = (user) => {
    setEditedUser({
      ...user,
      id: user._id,
    });
    setSelectedUser(user);
    setVisibleEditDialog(true);
  };

  const saveEditedUser = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.put(
        `${config.apiUrl}/user/update/${editedUser.id}`,
        editedUser,
        headersConfig
      );
      setVisibleEditDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User edited successfully",
      });
      fetchAllUsers();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDelete = (user) => {
    setUserToDelete({
      ...user,
      id: user._id,
    });
    setVisibleDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.apiUrl}/user/${userToDelete.id}`,
        headersConfig
      );
      setVisibleDeleteDialog(false);
      fetchAllUsers();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    }
  };

  return (
    <div>
      <UserTable
        users={users}
        totalRecords={totalRecords}
        first={first}
        setFirst={setFirst}
        rows={rows}
        setRows={setRows}
        onPageChange={onPageChange}
        loading={loading}
        selectedUser={selectedUser}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <EditUserForm
        visible={visibleEditDialog}
        onHide={() => setVisibleEditDialog(false)}
        editedUser={editedUser}
        countryList={countryList}
        userTypeOptions={userTypeOptions}
        userStatusOptions={userStatusOptions}
        onInputChange={onInputChange}
        saveEditedUser={saveEditedUser}
        loading={loading}
      />
      <ConfirmDialog
        visible={visibleDeleteDialog}
        onHide={() => setVisibleDeleteDialog(false)}
        message="Are you sure you want to delete this user?"
        header="Confirm"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        rejectClassName="p-button-secondary"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmDelete}
        reject={() => setVisibleDeleteDialog(false)}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default User;
