import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import config from "../../config";

const EditSystemConfigForm = ({ systemConfig }) => {
  const [formData, setFormData] = useState(
    systemConfig || {
      maintenance: "",
      playWithFriend: "",
      challengeYourSelf: "",
      timePerQuiz: "",
      androidAppUpdate: "",
      androidVersion: "",
      iosAppUpdate: "",
      iosAppVersion: "",
      totalQuestionsForLevel: "",
      attemptCount: "",
    }
  );
  const [questionCount, setQuestionCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    fetchQuestionCount();
  }, []);

  const fetchQuestionCount = async () => {
    try {
      const token = localStorage.getItem("token");

      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${config.apiUrl}/question/countByUser`,
        headersConfig
      );

      let totalCount = 0;
      for (const user in response.data.questionCountsByUser) {
        totalCount += response.data.questionCountsByUser[user];
      }
      setQuestionCount(totalCount);
    } catch (error) {
      console.error("Error fetching question count:", error);
    }
  };

  useEffect(() => {
    fetchSystemConfig();
  }, []);

  const fetchSystemConfig = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/systemConfig`,
        headersConfig
      );
      setFormData(response.data.systemConfig);
    } catch (error) {
      console.error("Error fetching system configuration:", error);
    }
  };

  useEffect(() => {
    if (formData.totalQuestionsForLevel !== "" && questionCount !== 0) {
      setFormData({ ...formData });
    }
  }, [formData.totalQuestionsForLevel, questionCount]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSwitchChange = (name, checked) => {
    setFormData({ ...formData, [name]: checked });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.put(`${config.apiUrl}/systemConfig`, formData, headersConfig);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "System configuration edited successfully",
      });
      fetchSystemConfig();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h3
        style={{
          float: "left",
          color: "#808080",
          marginLeft: "20px",
        }}
      >
        System Configuration
      </h3>
      <br />
      <br />
      <br />
      <br />
      <div className="p-fluid" style={{ marginLeft: "20px" }}>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <label htmlFor="maintenance" style={{ marginRight: "10px" }}>
              Maintenance
            </label>
            <InputSwitch
              id="maintenance"
              checked={formData.maintenance}
              onChange={(e) => handleSwitchChange("maintenance", e.value)}
              style={{ marginRight: "20px" }}
            />
          </div>

          <div
            className="p-field"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <label htmlFor="playWithFriend" style={{ marginRight: "10px" }}>
              Play With Friend
            </label>
            &nbsp;
            <InputSwitch
              id="playWithFriend"
              name="playWithFriend"
              checked={formData.playWithFriend}
              onChange={(e) => handleSwitchChange("playWithFriend", e.value)}
              style={{ marginRight: "20px" }}
            />
          </div>
          <div
            className="p-field"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <label htmlFor="challengeYourSelf" style={{ marginRight: "10px" }}>
              Challenge Yourself
            </label>
            &nbsp;
            <InputSwitch
              id="challengeYourSelf"
              name="challengeYourSelf"
              checked={formData.challengeYourSelf}
              onChange={(e) => handleSwitchChange("challengeYourSelf", e.value)}
            />
          </div>
        </div>
        <div className="p-field">
          <label htmlFor="timePerQuiz">
            Time Per Quiz&nbsp;&nbsp;
            <small style={{ color: "#808080", fontStyle: "italic" }}>
              (In seconds)
            </small>
          </label>
          <InputText
            id="timePerQuiz"
            name="timePerQuiz"
            value={formData.timePerQuiz}
            keyfilter="int"
            onChange={handleChange}
            style={{ marginTop: "10px" }}
          />
        </div>
        <div
          className="p-field"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <label htmlFor="androidAppUpdate" style={{ marginRight: "10px" }}>
            Android App Update
          </label>
          <InputSwitch
            id="androidAppUpdate"
            name="androidAppUpdate"
            checked={formData.androidAppUpdate}
            onChange={(e) => handleSwitchChange("androidAppUpdate", e.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="androidVersion">Android Version</label>
          <InputText
            id="androidVersion"
            name="androidVersion"
            value={formData.androidVersion}
            onChange={handleChange}
            style={{ marginTop: "10px" }}
          />
        </div>
        <div
          className="p-field"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <label htmlFor="iosAppUpdate" style={{ marginRight: "10px" }}>
            iOS App Update
          </label>
          <InputSwitch
            id="iosAppUpdate"
            name="iosAppUpdate"
            checked={formData.iosAppUpdate}
            onChange={(e) => handleSwitchChange("iosAppUpdate", e.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="iosAppVersion">iOS Version</label>
          <InputText
            id="iosAppVersion"
            name="iosAppVersion"
            value={formData.iosAppVersion}
            onChange={handleChange}
            style={{ marginTop: "10px" }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{
              marginBottom: "20px",
              marginTop: "20px",
              width: "50%",
              marginRight: "20px",
            }}
          >
            <label htmlFor="totalQuestionsForLevel">
              Total Questions For Level
            </label>
            <InputText
              id="totalQuestionsForLevel"
              name="totalQuestionsForLevel"
              value={formData.totalQuestionsForLevel}
              onChange={handleChange}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div
            className="p-field"
            style={{
              marginBottom: "20px",
              marginTop: "20px",
              width: "50%",
            }}
          >
            <label htmlFor="attemptCount">Attempt Count</label>
            <InputText
              id="attemptCount"
              name="attemptCount"
              value={formData.attemptCount}
              onChange={handleChange}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
      </div>
      <div
        className="p-dialog-footer"
        style={{ float: "right", marginBottom: "50px", marginTop: "40px" }}
      >
        <Button
          label={
            loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Updating...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Update"
            )
          }
          onClick={handleSubmit}
          severity="success"
          outlined
          disabled={loading}
        />
      </div>
      <Toast ref={toast} />
    </div>
  );
};

export default EditSystemConfigForm;
