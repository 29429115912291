import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import config from "../../../config";

// Table component for displaying subjects
const SubjectTable = ({
  subjects,
  totalRecords,
  first,
  setFirst,
  rows,
  setRows,
  onPageChange,
  loading,
  selectedSubject,
  handleEdit,
  handleDelete,
  syllabusOptions,
  selectedSyllabus,
  handleSyllabusChange,
  selectedMedium,
  handleMediumChange,
  handleClearFilter,
}) => {
  const toast = useRef(null);
  const [mediumOptions, setMediumOptions] = useState([]);

  const fetchMediums = async (syllabusId) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/medium/bySyllabus?syllabusId=${syllabusId}`,
        headersConfig
      );
      const fetchedMediums = response.data.mediums;
      return fetchedMediums.map((medium) => ({
        label: medium.name,
        value: medium._id,
      }));
    } catch (error) {
      console.error("Error fetching mediums:", error);
      return [];
    }
  };

  useEffect(() => {
    if (selectedSyllabus) {
      const fetchData = async () => {
        const mediums = await fetchMediums(selectedSyllabus);
        setMediumOptions(mediums);
      };
      fetchData();
    }
  }, [selectedSyllabus]);

  const renderVisibility = (rowData) => {
    return <span>{rowData.visibility ? "Yes" : "No"}</span>;
  };

  const handleCopyId = (id) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: `ID copied successfully!`,
    });
  };

  const filteredSubjects = subjects.filter((subject) => {
    const syllabusMatch =
      !selectedSyllabus ||
      (subject.syllabus && subject.syllabus._id === selectedSyllabus);
    const mediumMatch =
      !selectedMedium ||
      (subject.medium && subject.medium._id === selectedMedium);
    return syllabusMatch && mediumMatch;
  });

  return (
    <>
      <div className="table-container">
        <Dropdown
          value={selectedSyllabus}
          options={syllabusOptions}
          onChange={handleSyllabusChange}
          placeholder="Select Syllabus"
          optionLabel="label"
          optionValue="value"
          showClear
          onClear={() => handleClearFilter("syllabus.name", "syllabusId")}
        />
        &nbsp;&nbsp;
        <Dropdown
          value={selectedMedium}
          options={mediumOptions}
          onChange={handleMediumChange}
          placeholder="Select Medium"
          optionLabel="label"
          optionValue="value"
          disabled={!selectedSyllabus}
          showClear
          onClear={() => handleClearFilter("medium.name", "mediumId")}
        />
        &nbsp;&nbsp;
        <Button
          icon="pi pi-filter-slash"
          className="p-button-rounded"
          style={{
            background: "none",
            border: "none",
            borderColor: "#708db6",
            color: "#708db6",
            fontSize: "0.5rem",
          }}
          onClick={() => {
            handleClearFilter("syllabus.name", "syllabusId");
            handleClearFilter("medium.name", "mediumId");
          }}
        />
        <br />
        <br />
        <DataTable
          value={filteredSubjects}
          selectionMode="single"
          selection={selectedSubject}
          style={{ marginBottom: "50px" }}
        >
          <Column field="syllabus.name" header="Syllabus" />
          <Column field="medium.name" header="Medium" />
          <Column field="name" header="Name" />
          <Column
            field="visibility"
            header="Visibility"
            body={renderVisibility}
          />
          <Column field="orderId" header="Order ID" />
          <Column
            header="Actions"
            body={(rowData) => (
              <div>
                <CopyToClipboard
                  text={rowData._id}
                  onCopy={() => handleCopyId(rowData._id)}
                >
                  <Button
                    icon="pi pi-copy"
                    className="p-button-rounded"
                    style={{
                      backgroundColor: "#aaaaaa",
                      borderColor: "#aaaaaa",
                      color: "#ffffff",
                    }}
                  />
                </CopyToClipboard>
                &nbsp;&nbsp;
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-success"
                  onClick={() => handleEdit(rowData)}
                />
                &nbsp;&nbsp;
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-danger"
                  onClick={() => handleDelete(rowData)}
                />
              </div>
            )}
          />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          onPageChange(e);
        }}
      />
      <Toast ref={toast} />
    </>
  );
};

export default SubjectTable;
