import React, { useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import config from "../../../config";

// Table component for displaying syllabuses
const SyllabusTable = ({
  syllabuses,
  totalRecords,
  first,
  setFirst,
  rows,
  setRows,
  onPageChange,
  loading,
  selectedSyllabus,
  handleEdit,
  handleDelete,
}) => {
  const toast = useRef(null);
  const renderVisibility = (rowData) => {
    return <span>{rowData.visibility ? "Yes" : "No"}</span>;
  };

  const renderIsMedium = (rowData) => {
    return <span>{rowData.isMedium ? "Yes" : "No"}</span>;
  };

  const renderImage = (rowData) => {
    if (rowData.image && typeof rowData.image === "string") {
      return (
        <img
          src={
            rowData.image.startsWith("http")
              ? rowData.image
              : `${config.apiUrl}/uploads/${rowData.image}`
          }
          alt={rowData.name}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return null;
    }
  };

  const handleCopyId = (id) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: `ID copied successfully!`,
    });
  };

  return (
    <>
      <div className="table-container">
        <DataTable
          value={syllabuses}
          selectionMode="single"
          selection={selectedSyllabus}
          style={{ marginBottom: "50px" }}
        >
          <Column field="country" header="Country" />
          <Column field="name" header="Name" />
          <Column field="image" header="Image" body={renderImage} />
          <Column
            field="visibility"
            header="Visibility"
            body={renderVisibility}
          />
          <Column field="isMedium" header="Is Medium" body={renderIsMedium} />
          <Column field="orderId" header="Order ID" />
          <Column
            header="Actions"
            body={(rowData) => (
              <div>
                <CopyToClipboard
                  text={rowData._id}
                  onCopy={() => handleCopyId(rowData._id)}
                >
                  <Button
                    icon="pi pi-copy"
                    className="p-button-rounded"
                    style={{
                      backgroundColor: "#aaaaaa",
                      borderColor: "#aaaaaa",
                      color: "#ffffff",
                    }}
                  />
                </CopyToClipboard>
                &nbsp;&nbsp;
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-success"
                  onClick={() => handleEdit(rowData)}
                />
                &nbsp;&nbsp;
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-danger"
                  onClick={() => handleDelete(rowData)}
                />
              </div>
            )}
          />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          onPageChange(e);
        }}
      />
      <Toast ref={toast} />
    </>
  );
};

export default SyllabusTable;
