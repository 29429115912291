import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload } from "primereact/fileupload";
import { ProgressSpinner } from "primereact/progressspinner";
import Resizer from "react-image-file-resizer";
import config from "../../../config";

// Component for adding a medium
const AddMediumForm = ({ medium }) => {
  const [formData, setFormData] = useState(
    medium || {
      syllabusId: "",
      name: "",
      image: null,
      visibility: true,
      orderId: "",
    }
  );
  const [visible, setVisible] = useState(false);
  const [syllabuses, setSyllabuses] = useState([]);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const fileUploadRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    const fetchSyllabusData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${config.apiUrl}/syllabus`,
          headersConfig
        );
        const fetchedSyllabuses = response.data.syllabuses.filter(
          (syllabus) => syllabus.isMedium === true
        );
        setSyllabuses(fetchedSyllabuses);
        setSyllabusOptions(
          fetchedSyllabuses.map((syllabus) => ({
            label: syllabus.name,
            value: syllabus._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching syllabuses:", error);
      }
    };

    fetchSyllabusData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (name, files) => {
    const file = files[0];
    Resizer.imageFileResizer(
      file,
      512,
      512,
      "png",
      100,
      0,
      (resizedFile) => {
        const imageUrl = URL.createObjectURL(resizedFile);
        setFormData({
          ...formData,
          [name]: resizedFile,
          [`${name}Preview`]: imageUrl,
          [`${name}EncryptedName`]: resizedFile.name,
        });
      },
      "file"
    );
  };

  const handleClearImage = () => {
    setFormData({
      ...formData,
      image: null,
      imagePreview: null,
      imageEncryptedName: null,
    });
    fileUploadRef.current.clear();
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("syllabusId", formData.syllabusId);
      formDataToSend.append("name", formData.name);
      formDataToSend.append("visibility", formData.visibility);
      formDataToSend.append("orderId", formData.orderId);

      if (formData.image) {
        formDataToSend.append("image", formData.image);
      }

      const response = await axios.post(
        `${config.apiUrl}/medium`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVisible(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Medium added successfully",
      });
      window.location.reload();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div>
        <h3
          style={{
            float: "left",
            color: "#808080",
          }}
        >
          Mediums
        </h3>
        <Button
          label="+ New Medium"
          onClick={() => setVisible(true)}
          style={{ float: "right" }}
          rounded
          text
          raised
        />
        <br />
        <br />
        <br />
      </div>
      <Dialog
        header="Add Medium"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "700px", maxHeight: "600px" }}
      >
        <div className="p-fluid">
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="syllabusId">Syllabus Name</label>
            <Dropdown
              id="syllabusId"
              name="syllabusId"
              value={formData.syllabusId}
              options={syllabusOptions}
              onChange={handleChange}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Syllabus"
              style={{ marginTop: "10px" }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="p-field"
              style={{
                marginBottom: "20px",
                marginRight: "20px",
                width: "50%",
              }}
            >
              <label htmlFor="name">Name</label>
              <InputText
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                style={{ marginTop: "10px" }}
              />
            </div>
            <div
              className="p-field"
              style={{ marginBottom: "20px", width: "50%" }}
            >
              <label htmlFor="orderId">
                Order ID&nbsp;&nbsp;
                <small style={{ color: "#808080", fontStyle: "italic" }}>
                  (Optional)
                </small>
              </label>
              <InputText
                id="orderId"
                name="orderId"
                value={formData.orderId || ""}
                keyfilter="int"
                onChange={handleChange}
                style={{ marginTop: "10px" }}
              />
            </div>
          </div>
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="image">
              Image&nbsp;<span style={{ fontSize: "small" }}>(optional)</span>
            </label>
            <FileUpload
              ref={fileUploadRef}
              name="image"
              accept="image/*"
              chooseLabel="Upload"
              uploadLabel="Upload"
              mode="basic"
              customUpload
              onSelect={(e) => handleFileUpload("image", e.files)}
              style={{ marginTop: "10px" }}
            />
            {formData.imagePreview && (
              <>
                <img
                  src={formData.imagePreview}
                  alt="Uploaded"
                  style={{ maxWidth: "150px", marginTop: "10px" }}
                />
                <i
                  className="pi pi-times-circle"
                  onClick={handleClearImage}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    verticalAlign: "middle",
                    fontSize: "1rem",
                    color: "#bb2124",
                  }}
                ></i>
              </>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="visibility">Visibility</label>
            <br></br>
            <RadioButton
              id="visibility"
              name="visibility"
              value={true}
              onChange={handleChange}
              checked={formData.visibility === true}
              style={{ marginTop: "10px" }}
            />{" "}
            Yes &nbsp;
            <RadioButton
              id="visibility"
              name="visibility"
              value={false}
              onChange={handleChange}
              checked={formData.visibility === false}
              style={{ marginTop: "10px" }}
            />{" "}
            No &nbsp;
          </div>
        </div>
        <div
          className="p-dialog-footer"
          style={{
            marginTop: "20px",
            marginBottom: "-20px",
            marginRight: "-24px",
          }}
        >
          <Button
            label="Cancel"
            onClick={() => setVisible(false)}
            severity="danger"
            outlined
          />
          <Button
            label={
              loading ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  Saving...&nbsp;&nbsp;
                  <ProgressSpinner
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                </div>
              ) : (
                "Save"
              )
            }
            onClick={handleSubmit}
            severity="success"
            outlined
            disabled={loading}
          />
        </div>
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};

export default AddMediumForm;
