import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PaperTypeTable from "./PaperTypeTable";
import EditPaperTypeForm from "./EditPaperTypeForm";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import Resizer from "react-image-file-resizer";
import config from "../../../config";

// Component for managing paper type operations
const PaperType = () => {
  const [paperTypes, setPaperTypes] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedPaperType, setSelectedPaperType] = useState(null);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editedPaperType, setEditedPaperType] = useState({
    syllabusId: "",
    mediumId: "",
    gradeId: "",
    subjectId: "",
    paperTypeName: "",
    image: "",
    visibility: "",
  });
  const [filePreview, setFilePreview] = useState(null);
  const [paperTypeToDelete, setPaperTypeToDelete] = useState(null);
  const fileUploadRef = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchAllPaperTypes();
  }, [selectedSyllabus, selectedMedium, selectedGrade, selectedSubject]);

  const fetchAllPaperTypes = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let url = `${config.apiUrl}/paperType`;
      const params = { first, rows };

      if (selectedSyllabus) {
        params.syllabusId = selectedSyllabus;
      }
      if (selectedMedium) {
        params.mediumId = selectedMedium;
      }
      if (selectedGrade) {
        params.gradeId = selectedGrade;
      }
      if (selectedSubject) {
        params.subjectId = selectedSubject;
      }

      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");

      if (queryString) {
        url = `${config.apiUrl}/paperType/byFilter?${queryString}`;
      }

      const response = await axios.get(url, {
        headers: headersConfig.headers,
      });

      setPaperTypes(response.data.paperTypes);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching paper types:", error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (event) => {
    fetchAllPaperTypes(event.first / event.rows + 1, event.rows);
  };

  useEffect(() => {
    fetchSyllabuses();
  }, []);

  const fetchSyllabuses = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/syllabus`,
        headersConfig
      );
      const fetchedSyllabuses = response.data.syllabuses;
      setSyllabusOptions(
        fetchedSyllabuses.map((syllabus) => ({
          label: syllabus.name,
          value: syllabus._id,
        }))
      );
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    }
  };

  useEffect(() => {
    if (editedPaperType.syllabusId) {
      fetchMediums();
    }
  }, [editedPaperType.syllabusId]);

  const fetchMediums = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedPaperType.syllabusId;
      const response = await axios.get(
        `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
        headersConfig
      );
      const fetchedMediums = response.data.mediums;
      setMediumOptions(
        fetchedMediums.map((medium) => ({
          label: medium.name,
          value: medium._id,
        }))
      );
      if (
        fetchedMediums.some((medium) => medium._id === editedPaperType.mediumId)
      ) {
        setEditedPaperType((prevState) => ({
          ...prevState,
          mediumId: editedPaperType.mediumId,
        }));
      } else {
        setEditedPaperType((prevState) => ({
          ...prevState,
          mediumId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching mediums:", error);
    }
  };

  useEffect(() => {
    if (editedPaperType.syllabusId) {
      fetchGrades();
    }
  }, [editedPaperType.syllabusId, editedPaperType.mediumId]);

  const fetchGrades = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedPaperType.syllabusId;
      const selectedMedium = editedPaperType.mediumId;
      const response = await axios.get(
        `${config.apiUrl}/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}&mediumId=${selectedMedium}`,
        headersConfig
      );
      const fetchedGrades = response.data.grades;
      setGradeOptions(
        fetchedGrades.map((grade) => ({
          label: grade.name,
          value: grade._id,
        }))
      );

      if (
        fetchedGrades.some((grade) => grade._id === editedPaperType.gradeId)
      ) {
        setEditedPaperType((prevState) => ({
          ...prevState,
          gradeId: editedPaperType.gradeId,
        }));
      } else {
        setEditedPaperType((prevState) => ({
          ...prevState,
          gradeId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  useEffect(() => {
    if (editedPaperType.syllabusId) {
      fetchSubjects();
    }
  }, [editedPaperType.syllabusId, editedPaperType.mediumId]);

  const fetchSubjects = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedPaperType.syllabusId;
      let url = `${config.apiUrl}/subject/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

      if (editedPaperType.mediumId) {
        url += `&mediumId=${editedPaperType.mediumId}`;
      }
      const response = await axios.get(url, headersConfig);
      const fetchedSubjects = response.data.subjects;
      setSubjectOptions(
        fetchedSubjects.map((subject) => ({
          label: subject.name,
          value: subject._id,
        }))
      );
      if (
        fetchedSubjects.some(
          (subject) => subject._id === editedPaperType.subjectId
        )
      ) {
        setEditedPaperType((prevState) => ({
          ...prevState,
          subjectId: editedPaperType.subjectId,
        }));
      } else {
        setEditedPaperType((prevState) => ({
          ...prevState,
          subjectId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.files[0];
    const handleResizedImage = (resizedFile) => {
      const fileURL = URL.createObjectURL(resizedFile);
      setFilePreview(fileURL);

      onInputChange({
        target: {
          name: "image",
          value: resizedFile.name,
          files: [resizedFile],
        },
      });
    };

    Resizer.imageFileResizer(
      file,
      512,
      512,
      "png",
      100,
      0,
      handleResizedImage,
      "file"
    );
  };

  const handleClearImage = () => {
    setEditedPaperType((prevState) => ({
      ...prevState,
      image: null,
    }));
    setFilePreview(null);
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const handleEdit = (paperType) => {
    setEditedPaperType({
      ...paperType,
      id: paperType._id,
      syllabusId: paperType.syllabus ? paperType.syllabus._id : null,
      mediumId: paperType.medium ? paperType.medium._id : null,
      gradeId: paperType.grade ? paperType.grade._id : null,
      subjectId: paperType.subject ? paperType.subject._id : null,
    });
    setSelectedPaperType(paperType);
    setVisibleEditDialog(true);
  };

  const saveEditedPaperType = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const formDataToSend = new FormData();
      for (const key in editedPaperType) {
        if (key === "image" && editedPaperType[key]) {
          formDataToSend.append("image", editedPaperType[key]);
        } else {
          formDataToSend.append(key, editedPaperType[key]);
        }
      }
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.put(
        `${config.apiUrl}/paperType/update/${editedPaperType.id}`,
        editedPaperType,
        headersConfig
      );
      setVisibleEditDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Paper type edited successfully",
      });
      fetchAllPaperTypes();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setEditedPaperType((prevState) => ({
        ...prevState,
        [name]: file,
      }));
      setFilePreview(URL.createObjectURL(file));
    } else {
      setEditedPaperType((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleDelete = (paperType) => {
    const userType = localStorage.getItem("userType");
    if (userType === "admin") {
      setPaperTypeToDelete({
        ...paperType,
        id: paperType._id,
      });
      setVisibleDeleteDialog(true);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Unauthorized",
        detail: "You do not have permission to delete the record.",
      });
    }
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.apiUrl}/paperType/${paperTypeToDelete.id}`,
        headersConfig
      );
      setVisibleDeleteDialog(false);
      fetchAllPaperTypes();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    }
  };

  const handleSyllabusChange = (e) => {
    setSelectedSyllabus(e.value);
    setSelectedMedium(null);
    setSelectedGrade(null);
    setSelectedSubject(null);
    setSelectedPaperType(null);
  };

  const handleMediumChange = (e) => {
    setSelectedMedium(e.value);
    setSelectedGrade(null);
    setSelectedSubject(null);
    setSelectedPaperType(null);
  };

  const handleGradeChange = (e) => {
    setSelectedGrade(e.value);
    setSelectedPaperType(null);
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.value);
    setSelectedPaperType(null);
  };

  const handleClearFilter = () => {
    setSelectedSyllabus(null);
    setSelectedMedium(null);
    setSelectedGrade(null);
    setSelectedSubject(null);
  };

  return (
    <div>
      <PaperTypeTable
        paperTypes={paperTypes}
        totalRecords={totalRecords}
        first={first}
        setFirst={setFirst}
        rows={rows}
        setRows={setRows}
        onPageChange={onPageChange}
        loading={loading}
        selectedPaperType={selectedPaperType}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        syllabusOptions={syllabusOptions}
        selectedSyllabus={selectedSyllabus}
        handleSyllabusChange={handleSyllabusChange}
        selectedMedium={selectedMedium}
        handleMediumChange={handleMediumChange}
        selectedGrade={selectedGrade}
        handleGradeChange={handleGradeChange}
        selectedSubject={selectedSubject}
        handleSubjectChange={handleSubjectChange}
        handleClearFilter={handleClearFilter}
      />
      <EditPaperTypeForm
        visible={visibleEditDialog}
        onHide={() => setVisibleEditDialog(false)}
        editedPaperType={editedPaperType}
        syllabusOptions={syllabusOptions}
        mediumOptions={mediumOptions}
        gradeOptions={gradeOptions}
        subjectOptions={subjectOptions}
        onInputChange={onInputChange}
        handleFileUpload={handleFileUpload}
        handleClearImage={handleClearImage}
        fileUploadRef={fileUploadRef}
        filePreview={filePreview}
        saveEditedPaperType={saveEditedPaperType}
        loading={loading}
      />
      <ConfirmDialog
        visible={visibleDeleteDialog}
        onHide={() => setVisibleDeleteDialog(false)}
        message="Are you sure you want to delete this paper type?"
        header="Confirm"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        rejectClassName="p-button-secondary"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmDelete}
        reject={() => setVisibleDeleteDialog(false)}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default PaperType;
