import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";
import config from "../../config";

const DBUsersByGradeChart = () => {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const res = await axios.get(
          `${config.apiUrl}/common/by-grade/db/total-users`,
          headersConfig
        );
        const usersByGrade = res.data.usersByGrade;
        const labels = usersByGrade.map((item) => item.grade);
        const data = usersByGrade.map((item) => item.count);

        setChartData({
          labels,
          datasets: [
            {
              label: "Mobile Users",
              backgroundColor: ["#4caf50", "#708db6"],
              data,
              barPercentage: 1,
              categoryPercentage: 0.5,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="card">
      <h3
        style={{
          color: "#808080",
          marginTop: "40px",
          marginBottom: "70px",
        }}
      >
        Mobile Users by Grade
      </h3>
      {loading ? (
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      ) : (
        <div style={{ width: "1000px" }}>
          <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
      )}
    </div>
  );
};

export default DBUsersByGradeChart;
