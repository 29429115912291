import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";
import config from "../../config";

const Insights = () => {
  const [userQuestionCounts, setUserQuestionCounts] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestionCountsByUser = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${config.apiUrl}/question/countByUser`,
          headersConfig
        );

        const questionCountsByUser = response.data.questionCountsByUser;
        setUserQuestionCounts(questionCountsByUser);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching question counts by user:", error);
        setLoading(false);
      }
    };

    fetchQuestionCountsByUser();
  }, []);

  const getChartData = () => {
    const labels = Object.keys(userQuestionCounts);
    const data = Object.values(userQuestionCounts);

    return {
      labels: labels,
      datasets: [
        {
          label: "Question Counts",
          backgroundColor: ["#4caf50", "#708db6"],
          data: data,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
        },
      ],
    };
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="card">
      <center>
        <h3
          style={{
            color: "#808080",
            marginTop: "50px",
            marginBottom: "70px",
          }}
        >
          Question Count by User
        </h3>
        {loading ? (
          <center>
            {loading && (
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="5"
                animationDuration=".5s"
              />
            )}
          </center>
        ) : (
          <div style={{ width: "800px" }}>
            <Chart type="bar" data={getChartData()} options={chartOptions} />
          </div>
        )}
      </center>
    </div>
  );
};

export default Insights;
