import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import config from "../../config";

const MobileUserCriteriaTable = () => {
  const [users, setUsers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState({
    syllabusId: null,
    mediumId: null,
    gradeId: null,
  });

  useEffect(() => {
    fetchFiltersData();
  }, []);

  const fetchFiltersData = async () => {
    try {
      await fetchSyllabuses();
      if (selectedIds.syllabusId) {
        await fetchMediums(selectedIds.syllabusId);
      }
      if (selectedIds.syllabusId && selectedIds.mediumId) {
        await fetchGrades(selectedIds.syllabusId, selectedIds.mediumId);
      }
    } catch (error) {
      console.error("Error fetching filter data:", error);
    }
  };

  const handleDropdownChange = async (e, filterName) => {
    const value = e.value;
    let newSelectedIds = { ...selectedIds, [filterName]: value };
    setSelectedIds(newSelectedIds);

    if (filterName === "syllabusId") {
      await fetchMediums(value);
      setGradeOptions([]);
      setSelectedIds((prev) => ({
        ...prev,
        mediumId: null,
        gradeId: null,
      }));
    }

    if (filterName === "mediumId") {
      await fetchGrades(newSelectedIds.syllabusId, value);
      setSelectedIds((prev) => ({
        ...prev,
        gradeId: null,
      }));
    }
  };

  const fetchAllUsers = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const params = {
        first,
        rows,
        syllabusId: selectedIds.syllabusId,
        mediumId: selectedIds.mediumId,
        gradeId: selectedIds.gradeId,
      };

      const response = await axios.get(`${config.apiUrl}/mobile/user`, {
        params,
        ...headersConfig,
      });

      setUsers(response.data.users);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSyllabuses = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/syllabus`,
        headersConfig
      );
      setSyllabusOptions(
        response.data.syllabuses.map((s) => ({ label: s.name, value: s._id }))
      );
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    }
  };

  const fetchMediums = async (syllabusId = null) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(`${config.apiUrl}/medium/bySyllabus`, {
        params: { syllabusId },
        ...headersConfig,
      });

      setMediumOptions(
        response.data.mediums.map((m) => ({ label: m.name, value: m._id }))
      );
    } catch (error) {
      console.error("Error fetching mediums:", error);
    }
  };

  const fetchGrades = async (syllabusId = null, mediumId = null) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const params = { syllabusId, mediumId };

      const response = await axios.get(
        `${config.apiUrl}/grade/bySyllabusAndMedium`,
        {
          params,
          ...headersConfig,
        }
      );

      setGradeOptions(
        response.data.grades.map((g) => ({ label: g.name, value: g._id }))
      );
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  const handleClearFilter = (idName) => {
    setSelectedIds((prevIds) => {
      const newSelectedIds = { ...prevIds, [idName]: null };

      if (idName === "syllabusId") {
        setMediumOptions([]);
        setGradeOptions([]);
        newSelectedIds.mediumId = null;
        newSelectedIds.gradeId = null;
      } else if (idName === "mediumId") {
        setGradeOptions([]);
        newSelectedIds.gradeId = null;
      }

      return newSelectedIds;
    });

    fetchAllUsers();
  };

  const handleClearAllFilters = () => {
    setSelectedIds({
      syllabusId: null,
      mediumId: null,
      gradeId: null,
    });
    setMediumOptions([]);
    setGradeOptions([]);
  };

  useEffect(() => {
    fetchAllUsers();
  }, [selectedIds]);

  const exportData = () => {
    const csvContent = generateCSVContent();
    downloadCSV(csvContent);
  };

  const generateCSVContent = () => {
    const headers = [
      "Name",
      "Phone",
      "Selected Syllabus",
      "Selected Medium",
      "Selected Grade",
    ];
    const columnHeaderRow = headers.join(",");
    const dataRows = users
      .map((user) =>
        [
          user.name,
          user.phone,
          user.syllabus?.name || "",
          user.medium?.name || "",
          user.grade?.name || "",
        ].join(",")
      )
      .join("\n");
    return columnHeaderRow + "\n" + dataRows;
  };

  const downloadCSV = (csvContent) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "mobile-users.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <h3
        style={{
          float: "left",
          color: "#808080",
        }}
      >
        Mobile Users' Selected Criterias
      </h3>
      <br />
      <br />
      <br />
      <br />
      <div className="filters-container">
        <div className="filter-row">
          <Dropdown
            value={selectedIds.syllabusId}
            options={syllabusOptions}
            onChange={(e) => handleDropdownChange(e, "syllabusId")}
            placeholder="Select Syllabus"
            showClear
            onClear={() => handleClearFilter("syllabusId")}
          />
          &nbsp;
        </div>
        <div className="filter-row">
          <Dropdown
            value={selectedIds.mediumId}
            options={mediumOptions}
            onChange={(e) => handleDropdownChange(e, "mediumId")}
            placeholder="Select Medium"
            disabled={!selectedIds.syllabusId}
            showClear
            onClear={() => handleClearFilter("mediumId")}
          />
          &nbsp;
        </div>
        <div className="filter-row">
          <Dropdown
            value={selectedIds.gradeId}
            options={gradeOptions}
            onChange={(e) => handleDropdownChange(e, "gradeId")}
            placeholder="Select Grade"
            disabled={!selectedIds.syllabusId}
            showClear
            onClear={() => handleClearFilter("gradeId")}
          />
          &nbsp;
        </div>
        &nbsp;
        <Button
          icon="pi pi-filter-slash"
          className="p-button-rounded"
          style={{
            background: "none",
            border: "none",
            borderColor: "#708db6",
            color: "#708db6",
            fontSize: "0.5rem",
            float: "right",
          }}
          onClick={handleClearAllFilters}
        />
      </div>
      <div style={{ float: "right", marginTop: "-50px" }}>
        <Button label="Export CSV" icon="pi pi-file-o" onClick={exportData} />
      </div>
      <br />
      <br />
      <div className="table-container">
        <DataTable value={users} style={{ marginBottom: "50px" }}>
          <Column field="name" header="Name" />
          <Column field="phone" header="Phone" />
          <Column field="syllabus.name" header="Selected Syllabus" />
          <Column field="medium.name" header="Selected Medium" />
          <Column field="grade.name" header="Selected Grade" />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          fetchAllUsers(e.first / e.rows + 1, e.rows);
        }}
      />
    </>
  );
};

export default MobileUserCriteriaTable;
