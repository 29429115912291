import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SyllabusTable from "./SyllabusTable";
import EditSyllabusForm from "./EditSyllabusForm";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import Resizer from "react-image-file-resizer";
import config from "../../../config";

// Component for managing syllabus operations
const Syllabus = () => {
  const [syllabuses, setSyllabuses] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editedSyllabus, setEditedSyllabus] = useState({
    country: "",
    name: "",
    image: "",
    visibility: "",
    isMedium: "",
    orderId: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [filePreview, setFilePreview] = useState(null);
  const [syllabusToDelete, setSyllabusToDelete] = useState(null);
  const fileUploadRef = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchAllSyllabuses();
  }, []);

  const fetchAllSyllabuses = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/syllabus?first=${first}&rows=${rows}`,
        headersConfig
      );
      setSyllabuses(response.data.syllabuses);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (event) => {
    fetchAllSyllabuses(event.first / event.rows + 1, event.rows);
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/country`);
      setCountryList(
        response.data.map((country) => ({
          label: country.name,
          value: country.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching country list:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch country list",
      });
    }
  };

  const handleFileUpload = (e) => {
    const file = e.files[0];
    const handleResizedImage = (resizedFile) => {
      const fileURL = URL.createObjectURL(resizedFile);
      setFilePreview(fileURL);

      onInputChange({
        target: {
          name: "image",
          value: resizedFile.name,
          files: [resizedFile],
        },
      });
    };

    Resizer.imageFileResizer(
      file,
      512,
      512,
      "png",
      100,
      0,
      handleResizedImage,
      "file"
    );
  };

  const handleClearImage = () => {
    setEditedSyllabus((prevState) => ({
      ...prevState,
      image: null,
    }));
    setFilePreview(null);
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const handleEdit = (syllabus) => {
    setSelectedSyllabus(syllabus);
    setEditedSyllabus({
      ...syllabus,
      id: syllabus._id,
    });
    setVisibleEditDialog(true);
  };

  const saveEditedSyllabus = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const formDataToSend = new FormData();
      for (const key in editedSyllabus) {
        if (key === "image" && editedSyllabus[key]) {
          formDataToSend.append("image", editedSyllabus[key]);
        } else {
          formDataToSend.append(key, editedSyllabus[key]);
        }
      }

      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      await axios.put(
        `${config.apiUrl}/syllabus/update/${editedSyllabus.id}`,
        formDataToSend,
        headersConfig
      );
      setVisibleEditDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Syllabus edited successfully",
      });
      fetchAllSyllabuses();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = async (e) => {
    const selectedCountry = e.value;
    setEditedSyllabus({ ...editedSyllabus, country: selectedCountry });
  };

  const handleClearCountry = () => {
    setEditedSyllabus({
      ...editedSyllabus,
      country: "All",
    });
  };

  const onInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setEditedSyllabus((prevState) => ({
        ...prevState,
        [name]: file,
      }));
      setFilePreview(URL.createObjectURL(file));
    } else {
      setEditedSyllabus((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleDelete = (syllabus) => {
    const userType = localStorage.getItem("userType");
    if (userType === "admin") {
      setSyllabusToDelete({
        ...syllabus,
        id: syllabus._id,
      });
      setVisibleDeleteDialog(true);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Unauthorized",
        detail: "You do not have permission to delete the record.",
      });
    }
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.apiUrl}/syllabus/${syllabusToDelete.id}`,
        headersConfig
      );
      setVisibleDeleteDialog(false);
      fetchAllSyllabuses();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    }
  };

  return (
    <div>
      <SyllabusTable
        syllabuses={syllabuses}
        totalRecords={totalRecords}
        first={first}
        setFirst={setFirst}
        rows={rows}
        setRows={setRows}
        onPageChange={onPageChange}
        loading={loading}
        selectedSyllabus={selectedSyllabus}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <EditSyllabusForm
        visible={visibleEditDialog}
        onHide={() => setVisibleEditDialog(false)}
        countryList={countryList}
        editedSyllabus={editedSyllabus}
        handleCountryChange={handleCountryChange}
        handleClearCountry={handleClearCountry}
        onInputChange={onInputChange}
        handleFileUpload={handleFileUpload}
        handleClearImage={handleClearImage}
        fileUploadRef={fileUploadRef}
        filePreview={filePreview}
        saveEditedSyllabus={saveEditedSyllabus}
        loading={loading}
      />
      <ConfirmDialog
        visible={visibleDeleteDialog}
        onHide={() => setVisibleDeleteDialog(false)}
        message="Are you sure you want to delete this syllabus?"
        header="Confirm"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        rejectClassName="p-button-secondary"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmDelete}
        reject={() => setVisibleDeleteDialog(false)}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default Syllabus;
