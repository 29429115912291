import React from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload } from "primereact/fileupload";
import { ProgressSpinner } from "primereact/progressspinner";
import config from "../../../config";

// Component for editing a grade
const EditGradeForm = ({
  visible,
  onHide,
  editedGrade,
  syllabusOptions,
  mediumOptions,
  onInputChange,
  handleFileUpload,
  handleClearImage,
  fileUploadRef,
  filePreview,
  saveEditedGrade,
  loading,
}) => {
  return (
    <Dialog
      header="Edit Grade"
      visible={visible}
      onHide={onHide}
      style={{ width: "700px", maxHeight: "600px" }}
    >
      <div className="p-fluid">
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", marginRight: "20px", width: "50%" }}
          >
            <label htmlFor="syllabusId">Syllabus Name</label>
            <Dropdown
              id="syllabusId"
              name="syllabusId"
              value={editedGrade.syllabusId}
              options={syllabusOptions}
              onChange={onInputChange}
              optionLabel="label"
              optionValue="value"
              style={{ marginTop: "10px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="mediumId">Medium Name</label>
            <Dropdown
              id="mediumId"
              name="mediumId"
              value={editedGrade.mediumId}
              options={mediumOptions}
              onChange={onInputChange}
              optionLabel="label"
              optionValue="value"
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", marginRight: "20px", width: "50%" }}
          >
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              name="name"
              value={editedGrade.name}
              onChange={onInputChange}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="orderId">Order ID</label>
            <InputText
              id="orderId"
              name="orderId"
              value={editedGrade.orderId}
              keyfilter="int"
              onChange={onInputChange}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div className="p-field" style={{ marginBottom: "20px" }}>
          <label htmlFor="image">Image</label>
          <FileUpload
            ref={fileUploadRef}
            name="image"
            accept="image/*"
            chooseLabel="Upload"
            uploadLabel="Upload"
            mode="basic"
            customUpload
            onSelect={handleFileUpload}
            style={{ marginTop: "10px" }}
          />
          {filePreview && (
            <>
              <img
                src={filePreview}
                alt="Uploaded"
                style={{ maxWidth: "150px", marginTop: "10px" }}
              />
              <i
                className="pi pi-times-circle"
                onClick={handleClearImage}
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  verticalAlign: "middle",
                  fontSize: "1rem",
                  color: "#bb2124",
                }}
              ></i>
            </>
          )}
          {!filePreview && editedGrade.image && (
            <>
              <img
                src={`${config.apiUrl}/uploads/${editedGrade.image}`}
                alt="ExistingImage"
                style={{ maxWidth: "150px", marginTop: "10px" }}
              />
              <div
                style={{
                  maxWidth: "150px",
                  marginTop: "10px",
                  color: "#198754",
                }}
              >
                <b>
                  <center>{editedGrade.image}</center>
                </b>
              </div>
            </>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="visibility">Visibility</label>
          <br></br>
          <RadioButton
            id="visibility"
            name="visibility"
            value={true}
            onChange={onInputChange}
            checked={editedGrade.visibility === true}
            style={{ marginTop: "10px" }}
          />{" "}
          Yes &nbsp;
          <RadioButton
            id="visibility"
            name="visibility"
            value={false}
            onChange={onInputChange}
            checked={editedGrade.visibility === false}
            style={{ marginTop: "10px" }}
          />{" "}
          No
        </div>
      </div>
      <div
        className="p-dialog-footer"
        style={{
          marginTop: "20px",
          marginBottom: "-20px",
          marginRight: "-24px",
        }}
      >
        <Button label="Cancel" onClick={onHide} severity="danger" outlined />
        <Button
          label={
            loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Saving...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Save"
            )
          }
          onClick={saveEditedGrade}
          severity="success"
          outlined
          disabled={loading}
        />
      </div>
    </Dialog>
  );
};

export default EditGradeForm;
