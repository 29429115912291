import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import config from "../../config";

const WebSettingsPage = () => {
  const [selectedDirectory, setSelectedDirectory] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [downloadBtnLoading, setDownloadBtnLoading] = useState(false);
  const [backupLoading, setBackupLoading] = useState(false);
  const [backupConfirmationVisible, setBackupConfirmationVisible] =
    useState(false);
  const [restoreLoading, setRestoreLoading] = useState(false);
  const [restoreConfirmationVisible, setRestoreConfirmationVisible] =
    useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    const formatDate = (date) => {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Colombo",
      };
      const formattedDate = date.toLocaleDateString("en-CA", options);
      return formattedDate;
    };

    const today = new Date();
    const formattedDate = formatDate(today);
    setSelectedDirectory(`/root/Examhub_Production/backup/${formattedDate}`);
  }, []);

  const renderDate = (rowData) => {
    const date = new Date(rowData.createdAt);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Colombo",
    });
  };

  const handleImageBackup = async () => {
    try {
      setDownloadBtnLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      };
      const response = await axios.get(
        `${config.apiUrl}/backups/image`,
        headersConfig
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "backup-images.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Image backup completed",
      });
    } catch (error) {
      console.error("Error downloading backup:", error);
    } finally {
      setDownloadBtnLoading(false);
    }
  };

  const confirmBackup = () => {
    setBackupConfirmationVisible(true);
  };

  const handleDatabaseBackup = async () => {
    try {
      if (!selectedDirectory) {
        setIsValid(false);
        return;
      }
      setBackupConfirmationVisible(false);
      setIsValid(true);
      setBackupLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${config.apiUrl}/backups/db?backupDir=${encodeURIComponent(
        selectedDirectory
      )}`;
      await axios.get(url, headersConfig);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Database backup completed",
      });
      fetchHistory();
    } catch (error) {
      console.error("Error sending backup request:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setBackupLoading(false);
    }
  };

  const hideBackupConfirmation = () => {
    setBackupConfirmationVisible(false);
  };

  const confirmRestore = () => {
    setRestoreConfirmationVisible(true);
  };

  const handleDatabaseRestore = async () => {
    try {
      if (!selectedDirectory) {
        setIsValid(false);
        return;
      }
      setRestoreConfirmationVisible(false);
      setIsValid(true);
      setRestoreLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${config.apiUrl}/backups/restore`;
      const requestBody = { backupDir: selectedDirectory };
      await axios.post(url, requestBody, headersConfig);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Database restore completed",
      });
      fetchHistory();
    } catch (error) {
      console.error("Error sending restore request:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setRestoreLoading(false);
    }
  };

  const hideRestoreConfirmation = () => {
    setRestoreConfirmationVisible(false);
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/backups?first=${first}&rows=${rows}`,
        headersConfig
      );

      setHistoryData(response.data.logs);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching backup/restore history:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderAction = (rowData) => {
    const actionLabel = rowData.action === "backup" ? "Backup" : "Restore";
    const actionSeverity = rowData.action === "backup" ? "info" : "success";

    return <Tag value={actionLabel} severity={actionSeverity} />;
  };

  const onPageChange = (event) => {
    fetchHistory(event.first / event.rows + 1, event.rows);
  };

  return (
    <>
      <h3
        style={{
          float: "left",
          color: "#808080",
        }}
      >
        Backup Options
      </h3>
      <br />
      <br />
      <br />
      <br />
      <div>
        <Button
          label={
            downloadBtnLoading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Downloading...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Download Images"
            )
          }
          onClick={handleImageBackup}
          severity="success"
          outlined
          disabled={downloadBtnLoading}
        />
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", marginTop: "40px" }}>
            <label
              htmlFor="selectedDirectory"
              style={{ color: "#808080", fontWeight: "bold" }}
            >
              Backup Folder Destination
            </label>
            <InputText
              variant="filled"
              value={selectedDirectory}
              onChange={(e) => setSelectedDirectory(e.target.value)}
              placeholder="Enter Backup Folder Destination"
              className={!isValid ? "p-invalid" : ""}
              style={{ width: "100%", marginTop: "20px" }}
            />
            {!isValid && (
              <small style={{ color: "red", marginRight: "20px" }}>
                Folder destination is required.
              </small>
            )}
          </div>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "50%", marginRight: "20px" }}>
            <Button
              label="Backup Database"
              onClick={confirmBackup}
              severity="info"
              outlined
              style={{ width: "100%", marginTop: "30px" }}
              disabled={!selectedDirectory}
            />
            {backupLoading && (
              <ProgressBar
                mode="indeterminate"
                style={{
                  marginTop: "20px",
                  height: "6px",
                }}
              />
            )}
            <ConfirmDialog
              visible={backupConfirmationVisible}
              onHide={hideBackupConfirmation}
              message="Are you sure you want to backup the database?"
              header="Confirmation"
              icon="pi pi-exclamation-triangle"
              acceptClassName="p-button-secondary"
              rejectClassName="p-button-danger"
              accept={handleDatabaseBackup}
              reject={hideBackupConfirmation}
            />
          </div>
          <div style={{ width: "50%" }}>
            <Button
              label="Restore Database"
              onClick={confirmRestore}
              severity="success"
              outlined
              style={{ width: "100%", marginTop: "30px" }}
              disabled
            />
            {restoreLoading && (
              <ProgressBar
                mode="indeterminate"
                style={{ marginTop: "20px", height: "6px" }}
              />
            )}
            <ConfirmDialog
              visible={restoreConfirmationVisible}
              onHide={hideRestoreConfirmation}
              message="Are you sure you want to restore the database?"
              header="Confirmation"
              icon="pi pi-exclamation-triangle"
              acceptClassName="p-button-secondary"
              rejectClassName="p-button-danger"
              accept={handleDatabaseRestore}
              reject={hideRestoreConfirmation}
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <h3
        style={{
          color: "#808080",
          marginTop: "60px",
          marginBottom: "30px",
        }}
      >
        Backup & Restore History
      </h3>
      <br />
      <br />
      <div className="table-container">
        <DataTable
          value={historyData}
          selectionMode="single"
          style={{ marginBottom: "50px" }}
        >
          <Column field="action" header="Action" body={renderAction} />
          <Column field="directory" header="Directory" />
          <Column field="createdAt" header="Date" body={renderDate} />
          <Column field="user.name" header="Done By" />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          onPageChange(e);
        }}
      />

      <Toast ref={toast} />
    </>
  );
};

export default WebSettingsPage;
