import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Paginator } from "primereact/paginator";
import { ProgressSpinner } from "primereact/progressspinner";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import config from "../../config";

const defaultSelectedColumns = [
  "syllabusName",
  "mediumName",
  "gradeName",
  "subjectName",
  "paperTypeName",
  "questionType",
];

// Table component for displaying questions
const QuestionTable = ({
  questions,
  totalRecords,
  first,
  setFirst,
  rows,
  setRows,
  onPageChange,
  loading,
  selectedQuestion,
  handleEdit,
  handleDelete,
  fetchAllQuestions,
}) => {
  const currentUserType = localStorage.getItem("userType");
  const isNotAdmin = currentUserType !== "admin";
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState(
    defaultSelectedColumns
  );
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const fileUploadRef = useRef(null);
  const toast = useRef(null);

  const navigateToAddQuestion = () => {
    navigate("/add-question");
  };

  const toggleColumn = (e) => {
    setSelectedColumns(e.value);
  };

  const exportData = () => {
    const csvContent = generateCSVContent();
    downloadCSV(csvContent);
  };

  const generateCSVContent = () => {
    const columnHeaderRow = selectedColumns
      .map((col) => col.replace(/^\w/, (c) => c.toUpperCase()))
      .join(",");
    const dataRows = questions
      .map((item) => selectedColumns.map((col) => item[col] || "").join(","))
      .join("\n");
    return columnHeaderRow + "\n" + dataRows;
  };

  const downloadCSV = (csvContent) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderImage = (rowData) => {
    if (rowData.questionImage) {
      return (
        <img
          src={`${config.apiUrl}/uploads/${rowData.questionImage}`}
          alt={rowData.questionImage}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return null;
    }
  };

  const renderOption = (value, columnName) => {
    if (columnName === "questionTitle") {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = value;
      return tempDiv.textContent || tempDiv.innerText || "";
    } else if (
      columnName.startsWith("option") &&
      typeof value === "string" &&
      !/\.(jpg|jpeg|png)$/.test(value)
    ) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = value;
      return tempDiv.textContent || tempDiv.innerText || "";
    } else if (typeof value === "string" && /\.(jpg|jpeg|png)$/.test(value)) {
      return (
        <img
          src={`${config.apiUrl}/uploads/${value}`}
          alt={`${columnName} Preview`}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return value;
    }
  };

  const renderQuestionType = (value) => {
    switch (value) {
      case "truefalse":
        return "True False";
      case "textonly":
        return "Text Only";
      case "image":
        return "Image";
      case "imageanswer":
        return "Image Answer";
      default:
        return value;
    }
  };

  const onUpload = async (event) => {
    const file = event.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setUploading(true);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found in local storage");
      }

      const headersConfig = {
        Authorization: `Bearer ${token}`,
      };

      const response = await fetch(`${config.apiUrl}/question/upload-csv`, {
        method: "POST",
        body: formData,
        headers: headersConfig,
      });

      if (response.ok) {
        const data = await response.json();
        const formattedMessage = data.message.replace(/\n/g, "<br/>");
        toast.current.show({
          severity: "success",
          summary: "Upload Successful",
          detail: (
            <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />
          ),
          life: 3000,
        });
        fetchAllQuestions();
      } else {
        const errorData = await response.json();
        const formattedMessage = errorData.message.replace(/\n/g, "<br/>");
        console.error("Upload failed!", errorData);
        toast.current.show({
          severity: "error",
          summary: "Upload Failed",
          detail: (
            <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />
          ),
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.current.show({
        severity: "error",
        summary: "Upload Error",
        detail: error.message,
        life: 3000,
      });
    } finally {
      setUploading(false);
      if (fileUploadRef.current) {
        fileUploadRef.current.clear();
      }
    }
  };

  const header = (
    <div className="table-header">
      <MultiSelect
        value={selectedColumns}
        options={[
          "syllabusName",
          "mediumName",
          "gradeName",
          "subjectName",
          "paperTypeName",
          "paperInfo",
          "questionType",
          "questionTitle",
          "questionImage",
          "optionA",
          "optionB",
          "optionC",
          "optionD",
          "optionE",
          "answer",
          "note",
          "addedBy",
        ]}
        onChange={toggleColumn}
        display="chip"
        placeholder="Filter by"
        style={{ width: "210px", marginBottom: "20px", marginRight: "10px" }}
      />
      <div
        style={{
          position: "relative",
          float: "right",
          marginRight: "10px",
          marginLeft: "10px",
        }}
      >
        <FileUpload
          name="file"
          accept=".csv"
          customUpload
          uploadHandler={onUpload}
          mode="basic"
          auto
          chooseLabel="Upload CSV"
          disabled={isNotAdmin || uploading}
          ref={fileUploadRef}
        />
        {uploading && (
          <ProgressSpinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "20px",
              height: "20px",
              marginTop: "-10px",
              marginLeft: "-10px",
            }}
            strokeWidth="8"
            animationDuration=".5s"
          />
        )}
      </div>
      <Button
        label="Export CSV"
        icon="pi pi-file-o"
        onClick={exportData}
        style={{ float: "right" }}
      />
      <Button
        label="+ New Question"
        onClick={navigateToAddQuestion}
        style={{ float: "right", marginRight: "10px" }}
        rounded
        text
        raised
      />
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      <h3
        style={{
          color: "#808080",
          marginTop: "60px",
          marginBottom: "30px",
        }}
      >
        Questions
      </h3>
      <div className="table-container">
        <DataTable
          value={questions}
          selectionMode="multiple"
          selection={selectedQuestion}
          globalFilter={globalFilter}
          header={header}
          style={{ marginBottom: "50px" }}
        >
          {selectedColumns.map((column) => (
            <Column
              key={column}
              field={column}
              header={column.replace(/^\w/, (c) => c.toUpperCase())}
              body={(rowData) =>
                column === "syllabusName"
                  ? rowData.syllabus
                    ? rowData.syllabus.name
                    : ""
                  : column === "mediumName"
                  ? rowData.medium
                    ? rowData.medium.name
                    : ""
                  : column === "gradeName"
                  ? rowData.grade
                    ? rowData.grade.name
                    : ""
                  : column === "subjectName"
                  ? rowData.subject
                    ? rowData.subject.name
                    : ""
                  : column === "paperTypeName"
                  ? rowData.paperType
                    ? rowData.paperType.paperTypeName
                    : ""
                  : column === "paperInfo"
                  ? rowData.paperInfo
                    ? rowData.paperInfo.paperInfo
                    : ""
                  : column === "questionImage"
                  ? renderImage(rowData)
                  : column === "questionType"
                  ? renderQuestionType(rowData[column])
                  : renderOption(rowData[column], column)
              }
            />
          ))}
          <Column
            header="Actions"
            body={(rowData) => (
              <div>
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-success"
                  onClick={() => handleEdit(rowData)}
                />
                &nbsp;&nbsp;
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-danger"
                  onClick={() => handleDelete(rowData)}
                />
              </div>
            )}
          />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          onPageChange(e);
        }}
      />
    </>
  );
};

export default QuestionTable;
