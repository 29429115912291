import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload } from "primereact/fileupload";
import { ProgressSpinner } from "primereact/progressspinner";
import Resizer from "react-image-file-resizer";
import config from "../../../config";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// Component for adding a paper info
const AddPaperInfoForm = ({ paperInfo }) => {
  const [formData, setFormData] = useState(
    paperInfo || {
      syllabusId: "",
      mediumId: null,
      gradeId: "",
      subjectId: "",
      paperTypeId: "",
      paperInfo: "",
      image: null,
      existingImageName: "",
      visibility: true,
      imageType: "new",
    }
  );
  const [syllabuses, setSyllabuses] = useState([]);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [grades, setGrades] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [paperTypeOptions, setPaperTypeOptions] = useState([]);
  const [paperInfos, setPaperInfos] = useState([]);
  const [filteredPaperInfos, setFilteredPaperInfos] = useState([]);
  const [copiedImageName, setCopiedImageName] = useState("");
  const fileUploadRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();

  const navigateToPaperInfoTable = () => {
    navigate("/paper-info");
  };

  useEffect(() => {
    const fetchSyllabusData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${config.apiUrl}/syllabus`,
          headersConfig
        );
        const fetchedSyllabuses = response.data.syllabuses;
        setSyllabuses(fetchedSyllabuses);
        setSyllabusOptions(
          fetchedSyllabuses.map((syllabus) => ({
            label: syllabus.name,
            value: syllabus._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching syllabuses:", error);
      }
    };

    fetchSyllabusData();
  }, []);

  useEffect(() => {
    if (formData.syllabusId) {
      const fetchMediumData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const selectedSyllabus = formData.syllabusId;
          const response = await axios.get(
            `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
            headersConfig
          );
          const fetchedMediums = response.data.mediums;
          if (fetchedMediums && fetchedMediums.length > 0) {
            setMediums(fetchedMediums);
            setMediumOptions(
              fetchedMediums.map((medium) => ({
                label: medium.name,
                value: medium._id,
              }))
            );
          } else {
            setMediums([]);
            setMediumOptions([]);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setMediums([]);
            setMediumOptions([]);
          } else {
            console.error("Error fetching mediums:", error);
          }
        }
      };

      fetchMediumData();
    }
  }, [formData.syllabusId]);

  useEffect(() => {
    if (formData.syllabusId) {
      const fetchGradeData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const selectedSyllabus = formData.syllabusId;
          let url = `${config.apiUrl}/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

          if (formData.mediumId) {
            url += `&mediumId=${formData.mediumId}`;
          }

          const response = await axios.get(url, headersConfig);
          const fetchedGrades = response.data.grades;
          setGrades(fetchedGrades);
          setGradeOptions(
            fetchedGrades.map((grade) => ({
              label: grade.name,
              value: grade._id,
            }))
          );
        } catch (error) {
          console.error("Error fetching grades:", error);
        }
      };

      fetchGradeData();
    }
  }, [formData.syllabusId, formData.mediumId]);

  useEffect(() => {
    const fetchSubjectData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const selectedSyllabus = formData.syllabusId;
        let url = `${config.apiUrl}/subject/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

        if (formData.mediumId) {
          url += `&mediumId=${formData.mediumId}`;
        }
        const response = await axios.get(url, headersConfig);
        const fetchedSubjects = response.data.subjects;
        setSubjects(fetchedSubjects);
        setSubjectOptions(
          fetchedSubjects.map((subject) => ({
            label: subject.name,
            value: subject._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };
    if (formData.syllabusId) {
      fetchSubjectData();
    }
  }, [formData.syllabusId, formData.mediumId]);

  useEffect(() => {
    const fetchPaperTypeData = async () => {
      try {
        if (
          !formData.syllabusId &&
          !formData.mediumId &&
          !formData.gradeId &&
          !formData.subjectId
        ) {
          return;
        }
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        let url = `${config.apiUrl}/paperType/byFilter?`;

        if (formData.syllabusId) {
          url += `syllabusId=${encodeURIComponent(formData.syllabusId)}&`;
        }

        if (formData.mediumId !== null) {
          url += `mediumId=${encodeURIComponent(formData.mediumId)}&`;
        }

        if (formData.gradeId) {
          url += `gradeId=${encodeURIComponent(formData.gradeId)}&`;
        }

        if (formData.subjectId) {
          url += `subjectId=${encodeURIComponent(formData.subjectId)}`;
        }

        const response = await axios.get(url, headersConfig);
        const fetchedPaperTypes = response.data.paperTypes;
        setPaperTypes(fetchedPaperTypes);
        setPaperTypeOptions(
          fetchedPaperTypes.map((paperType) => ({
            label: paperType.paperTypeName,
            value: paperType._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching paper types:", error);
      }
    };

    fetchPaperTypeData();
  }, [
    formData.syllabusId,
    formData.mediumId,
    formData.gradeId,
    formData.subjectId,
  ]);

  const fetchPaperInfos = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/paperInfo`,
        headersConfig
      );
      const fetchedPaperInfos = response.data.paperInfos;
      const filtered = fetchedPaperInfos.filter((paperInfo) => paperInfo.image);
      setPaperInfos(filtered);
      setFilteredPaperInfos(filtered);
    } catch (error) {
      console.error("Error fetching paper infos:", error);
    }
  };

  useEffect(() => {
    fetchPaperInfos();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (name, files) => {
    const file = files[0];
    Resizer.imageFileResizer(
      file,
      512,
      512,
      "png",
      100,
      0,
      (resizedFile) => {
        const imageUrl = URL.createObjectURL(resizedFile);
        setFormData({
          ...formData,
          [name]: resizedFile,
          [`${name}Preview`]: imageUrl,
          [`${name}EncryptedName`]: resizedFile.name,
        });
      },
      "file"
    );
  };

  const handleClearImage = () => {
    setFormData({
      ...formData,
      image: null,
      imagePreview: null,
      imageEncryptedName: null,
    });
    fileUploadRef.current.clear();
  };

  const handleClearSpecific = () => {
    setFormData({
      ...formData,
      existingImageName: "",
      imageName: "",
      image: null,
      imagePreview: null,
      imageEncryptedName: null,
      paperInfo: "",
    });
    setCopiedImageName("");
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const handleResetAllValues = () => {
    setFormData({
      syllabusId: "",
      mediumId: "",
      gradeId: "",
      subjectId: "",
      paperTypeId: "",
      paperInfo: "",
      imageType: "new",
      visibility: true,
      existingImageName: "",
      imageName: "",
      image: null,
      imagePreview: null,
      imageEncryptedName: null,
    });
    setCopiedImageName("");
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("syllabusId", formData.syllabusId);
      if (formData.mediumId !== null) {
        formDataToSend.append("mediumId", formData.mediumId);
      }
      formDataToSend.append("gradeId", formData.gradeId);
      formDataToSend.append("subjectId", formData.subjectId);
      formDataToSend.append("paperTypeId", formData.paperTypeId);
      formDataToSend.append("paperInfo", formData.paperInfo);
      formDataToSend.append("visibility", formData.visibility);

      if (formData.imageType === "new") {
        if (formData.image) {
          formDataToSend.append("image", formData.image);
        }
      } else if (formData.imageType === "existing") {
        formDataToSend.append("imageName", copiedImageName);
      } else {
        formDataToSend.append("image", null);
      }

      const response = await axios.post(
        `${config.apiUrl}/paperInfo`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Paper info added successfully",
      });
      handleClearSpecific();
      fetchPaperInfos();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleImageTypeChange = (e) => {
    if (e.target.value === "existing") {
      setFormData({
        ...formData,
        imageType: e.target.value,
        imageName: copiedImageName,
      });
    } else {
      setFormData({
        ...formData,
        imageType: e.target.value,
        existingImageName: "",
      });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    const filtered = paperInfos.filter((paperInfo) => {
      return (
        paperInfo.paperInfo.toLowerCase().includes(query) ||
        (paperInfo.syllabus &&
          paperInfo.syllabus.name.toLowerCase().includes(query)) ||
        (paperInfo.medium &&
          paperInfo.medium.name.toLowerCase().includes(query)) ||
        (paperInfo.grade &&
          paperInfo.grade.name.toLowerCase().includes(query)) ||
        (paperInfo.subject &&
          paperInfo.subject.name.toLowerCase().includes(query)) ||
        (paperInfo.paperTypeName &&
          paperInfo.paperType.paperTypeName.toLowerCase().includes(query))
      );
    });
    setFilteredPaperInfos(filtered);
  };

  const handleCopyImageName = (imageName) => {
    navigator.clipboard.writeText(imageName);
    toast.current.show({
      severity: "success",
      summary: "Copied",
      detail: "Image name copied to clipboard",
      life: 3000,
    });
  };

  const handlePasteImageName = (e) => {
    const imageName = e.target.value;
    setCopiedImageName(imageName);
    setFormData({
      ...formData,
      existingImageName: imageName,
      imageName: imageName,
    });
  };

  const renderImage = (rowData) => {
    if (rowData.image && typeof rowData.image === "string") {
      return (
        <img
          src={
            rowData.image.startsWith("http")
              ? rowData.image
              : `${config.apiUrl}/uploads/${rowData.image}`
          }
          alt={rowData.name}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <h3
        style={{
          float: "left",
          color: "#808080",
        }}
      >
        Add Paper Info
      </h3>
      <Button
        label="Paper Infos"
        onClick={navigateToPaperInfoTable}
        style={{ float: "right" }}
        rounded
        text
        raised
      />
      <br />
      <br />
      <br />
      <br />
      <div className="p-fluid" style={{ marginBottom: "50px" }}>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="syllabusId">Syllabus</label>
            <Dropdown
              id="syllabusId"
              name="syllabusId"
              value={formData.syllabusId}
              options={syllabusOptions}
              onChange={handleChange}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Syllabus"
              style={{ marginTop: "10px", marginRight: "20px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="mediumId">Medium</label>
            <Dropdown
              id="mediumId"
              name="mediumId"
              value={formData.mediumId}
              options={mediumOptions}
              onChange={handleChange}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Medium"
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="gradeId">Grade</label>
            <Dropdown
              id="gradeId"
              name="gradeId"
              value={formData.gradeId}
              options={gradeOptions}
              onChange={handleChange}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Grade"
              style={{ marginTop: "10px", marginRight: "20px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="subjectId">Subject</label>
            <Dropdown
              id="subjectId"
              name="subjectId"
              value={formData.subjectId}
              options={subjectOptions}
              onChange={handleChange}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Subject"
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{
              marginBottom: "20px",
              width: "50%",
            }}
          >
            <label htmlFor="paperTypeId">Paper Type</label>
            <Dropdown
              id="paperTypeId"
              name="paperTypeId"
              value={formData.paperTypeId}
              options={paperTypeOptions}
              onChange={handleChange}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Paper Type"
              style={{ marginTop: "10px", marginRight: "20px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="paperInfo">Paper Info</label>
            <InputText
              id="paperInfo"
              name="paperInfo"
              value={formData.paperInfo}
              onChange={handleChange}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div className="p-field" style={{ marginBottom: "20px" }}>
          <label>Image Type</label>
          <div>
            <RadioButton
              id="newImage"
              name="imageType"
              value="new"
              onChange={handleImageTypeChange}
              checked={formData.imageType === "new"}
              style={{ marginTop: "10px" }}
            />{" "}
            New Image &nbsp;
            <RadioButton
              id="existingImage"
              name="imageType"
              value="existing"
              onChange={handleImageTypeChange}
              checked={formData.imageType === "existing"}
              style={{ marginTop: "10px" }}
            />{" "}
            Existing Image
          </div>
        </div>
        {formData.imageType === "new" && (
          <div
            className="p-field"
            style={{ marginTop: "30px", marginBottom: "20px" }}
          >
            <FileUpload
              ref={fileUploadRef}
              name="image"
              accept="image/*"
              chooseLabel="Upload"
              uploadLabel="Upload"
              mode="basic"
              customUpload
              onSelect={(e) => handleFileUpload("image", e.files)}
              style={{ marginTop: "10px" }}
            />
            {formData.imagePreview && (
              <>
                <img
                  src={formData.imagePreview}
                  alt="Uploaded"
                  style={{ maxWidth: "150px", marginTop: "10px" }}
                />
                <i
                  className="pi pi-times-circle"
                  onClick={handleClearImage}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    verticalAlign: "middle",
                    fontSize: "1rem",
                    color: "#bb2124",
                  }}
                ></i>
              </>
            )}
          </div>
        )}
        {formData.imageType === "existing" && (
          <div className="p-field">
            <InputText
              id="existingImageName"
              value={copiedImageName}
              onChange={handlePasteImageName}
              style={{
                marginTop: "10px",
                marginBottom: "20px",
                marginRight: "20px",
                width: "48%",
              }}
            />
          </div>
        )}
        <div className="p-field">
          <label htmlFor="visibility">Visibility</label>
          <br></br>
          <RadioButton
            id="visibility"
            name="visibility"
            value={true}
            onChange={handleChange}
            checked={formData.visibility === true}
            style={{ marginTop: "10px" }}
          />{" "}
          Yes &nbsp;
          <RadioButton
            id="visibility"
            name="visibility"
            value={false}
            onChange={handleChange}
            checked={formData.visibility === false}
            style={{ marginTop: "10px" }}
          />{" "}
          No
        </div>
      </div>
      <div
        className="p-dialog-footer"
        style={{
          marginTop: "20px",
          float: "right",
          marginBottom: "50px",
        }}
      >
        <Button
          label="Cancel"
          onClick={navigateToPaperInfoTable}
          severity="danger"
          outlined
        />{" "}
        &nbsp;
        <Button
          label={
            loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Saving...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Save"
            )
          }
          onClick={handleSubmit}
          severity="success"
          outlined
          disabled={loading}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          float: "left",
          marginBottom: "50px",
          marginRight: "10px",
        }}
      >
        <Button
          label="Reset"
          severity="warning"
          onClick={handleResetAllValues}
          outlined
        />
      </div>
      {formData.imageType === "existing" && (
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3
            style={{
              float: "left",
              color: "#808080",
            }}
          >
            Image List of Existing Paper Infos
          </h3>
          <br />
          <br />
          <br />
          <br />
          <InputText
            placeholder="Search"
            onChange={handleSearch}
            style={{ marginBottom: "20px", width: "30%", float: "right" }}
          />{" "}
          <br />
          <br />
          <DataTable
            value={filteredPaperInfos}
            paginator
            rows={10}
            style={{ marginTop: "50px", marginBottom: "50px" }}
          >
            <Column field="syllabus.name" header="Syllabus" />
            <Column field="medium.name" header="Medium" />
            <Column field="grade.name" header="Grade" />
            <Column field="subject.name" header="Subject" />
            <Column field="paperType.paperTypeName" header="Paper Type" />
            <Column field="paperInfo" header="Paper Info" />
            <Column field="image" header="Image" body={renderImage} />
            <Column
              field="image"
              header="Actions"
              body={(rowData) => (
                <span>
                  <Button
                    icon="pi pi-copy"
                    className="p-button-rounded"
                    onClick={() => handleCopyImageName(rowData.image)}
                    style={{
                      backgroundColor: "#aaaaaa",
                      borderColor: "#aaaaaa",
                      color: "#ffffff",
                    }}
                  />
                </span>
              )}
            />
          </DataTable>
        </div>
      )}
      <Toast ref={toast} />
    </div>
  );
};

export default AddPaperInfoForm;
