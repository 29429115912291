import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { ProgressSpinner } from "primereact/progressspinner";
import UserType from "../../types/UserType";
import UserStatus from "../../types/UserStatus";
import config from "../../config";

const AddUserForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    password: "",
    confirmPassword: "",
    userType: "user",
    userStatus: "active",
  });
  const [passwordError, setPasswordError] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [dialCode, setDialCode] = useState("");
  const [loading, setLoading] = useState(false);

  const userTypeOptions = Object.keys(UserType).map((key) => ({
    label: UserType[key].charAt(0).toUpperCase() + UserType[key].slice(1),
    value: UserType[key],
  }));

  const userStatusOptions = Object.keys(UserStatus).map((key) => ({
    label: UserStatus[key].charAt(0).toUpperCase() + UserStatus[key].slice(1),
    value: UserStatus[key],
  }));
  const navigate = useNavigate();
  const navigateToUserList = () => {
    navigate("/users");
  };

  const toast = useRef(null);

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/country`);
      setCountryList(
        response.data.map((country) => ({
          label: country.name,
          value: country.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching country list:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch country list",
      });
    }
  };

  const handleCountryChange = async (e) => {
    const selectedCountry = e.value;
    setFormData({ ...formData, country: selectedCountry });

    try {
      const response = await axios.get(
        `${config.apiUrl}/country/dial-code/${selectedCountry}`
      );
      const countryDialCode = response.data.dialCode;
      setDialCode(countryDialCode);
      setFormData({
        ...formData,
        country: selectedCountry,
        phone: countryDialCode,
      });
    } catch (error) {
      console.error("Error fetching dial code:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch dial code for the selected country",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "confirmPassword") {
      if (formData.password !== value) {
        setPasswordError("Passwords do not match");
      } else {
        setPasswordError("");
      }
    }
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      const { confirmPassword, ...dataToSend } = formData;
      const response = await axios.post(
        `${config.apiUrl}/user/signup`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User registration successful",
      });
      navigateToUserList();
    } catch (error) {
      console.error("Error adding user:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      country: "",
      password: "",
      confirmPassword: "",
      userType: "user",
      userStatus: "active",
    });
  };

  return (
    <div>
      <div>
        <h3
          style={{
            float: "left",
            color: "#808080",
            marginLeft: "20px",
          }}
        >
          User Registration
        </h3>
        <Button
          label="Users"
          onClick={navigateToUserList}
          style={{ float: "right", marginRight: "20px" }}
          rounded
          text
          raised
        />
        <br />
        <br />
        <br />
      </div>
      <div className="p-fluid" style={{ padding: "20px" }}>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ width: "50%", marginRight: "20px", marginBottom: "20px" }}
          >
            <label htmlFor="country">Country</label>
            <Dropdown
              id="country"
              name="country"
              value={formData.country}
              options={countryList}
              onChange={handleCountryChange}
              placeholder="Select Country"
              style={{ marginTop: "10px" }}
              filter
            />
          </div>
          <div
            className="p-field"
            style={{ width: "50%", marginBottom: "20px" }}
          >
            <label htmlFor="name">Full Name</label>
            <InputText
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ width: "50%", marginRight: "20px", marginBottom: "20px" }}
          >
            <label htmlFor="email">Email Address</label>
            <InputText
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ width: "50%", marginBottom: "20px" }}
          >
            <label htmlFor="phone">Phone Number</label>
            <InputText
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              style={{ marginTop: "10px" }}
              prefix={dialCode}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ width: "50%", marginRight: "20px", marginBottom: "20px" }}
          >
            <label htmlFor="password">Password</label>
            <Password
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              style={{ marginTop: "10px" }}
              toggleMask
            />
          </div>
          <div
            className="p-field"
            style={{ width: "50%", marginBottom: "20px" }}
          >
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Password
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              style={{ marginTop: "10px" }}
              toggleMask
            />
            {passwordError && (
              <small style={{ color: "red" }}>{passwordError}</small>
            )}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ width: "50%", marginRight: "20px", marginBottom: "20px" }}
          >
            <label htmlFor="userType">User Type</label>
            <Dropdown
              id="userType"
              name="userType"
              optionLabel="label"
              optionValue="value"
              value={formData.userType}
              onChange={handleChange}
              options={userTypeOptions}
              style={{ marginTop: "10px", width: "100%" }}
            />
          </div>
          <div
            className="p-field"
            style={{ width: "50%", marginBottom: "20px" }}
          >
            <label htmlFor="userStatus">User Status</label>
            <Dropdown
              id="userStatus"
              name="userStatus"
              optionLabel="label"
              optionValue="value"
              value={formData.userStatus}
              onChange={handleChange}
              options={userStatusOptions}
              style={{ marginTop: "10px", width: "100%" }}
              disabled
            />
          </div>
        </div>
      </div>
      <div
        className="p-dialog-footer"
        style={{
          float: "right",
          marginTop: "10px",
          marginBottom: "20px",
          paddingRight: "20px",
        }}
      >
        <Button
          label="Cancel"
          onClick={handleCancel}
          severity="danger"
          outlined
        />
        &nbsp;&nbsp;
        <Button
          label={
            loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Saving...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Save"
            )
          }
          onClick={handleSubmit}
          severity="success"
          outlined
          disabled={loading}
        />
      </div>
      <Toast ref={toast} />
    </div>
  );
};

export default AddUserForm;
