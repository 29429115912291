import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import config from "../../config";

const EditCountryOrderId = () => {
  const [countryList, setCountryList] = useState([]);
  const [filteredCountryList, setFilteredCountryList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const toast = useRef(null);

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/country`);
      setCountryList(response.data);
      setFilteredCountryList(response.data);
    } catch (error) {
      console.error("Error fetching country list:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch country list",
      });
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    setFilteredCountryList(
      countryList.filter((country) =>
        country.name.toLowerCase().includes(searchValue)
      )
    );
  };

  const onRowEditComplete = async (e) => {
    const { newData, index } = e;
    let _countryList = [...countryList];
    _countryList[index] = newData;

    try {
      await axios.put(`${config.apiUrl}/country/update`, newData);
      setCountryList(_countryList);
      setFilteredCountryList(_countryList);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Country order ID updated successfully",
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.error,
      });
    }
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  return (
    <div>
      <h3
        style={{
          float: "left",
          color: "#808080",
          marginLeft: "20px",
        }}
      >
        Update Country Order ID
      </h3>
      <br />
      <br />
      <br />
      <br />
      <div style={{ marginBottom: "20px", marginLeft: "20px" }}>
        <InputText
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search by Country Name"
          style={{ width: "250px" }}
        />
      </div>

      <div style={{ marginLeft: "20px" }}>
        <DataTable
          value={filteredCountryList}
          editMode="row"
          dataKey="id"
          onRowEditComplete={onRowEditComplete}
          style={{ marginBottom: "100px" }}
        >
          <Column field="name" header="Name"></Column>
          <Column field="dial_code" header="Dial Code"></Column>
          <Column field="code" header="Code"></Column>
          <Column
            field="orderId"
            header="Order ID"
            editor={(options) => textEditor(options)}
            editable
          ></Column>
          <Column
            rowEditor
            headerStyle={{ width: "7rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        </DataTable>
      </div>

      <Toast ref={toast} />
    </div>
  );
};

export default EditCountryOrderId;
