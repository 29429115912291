import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MediumTable from "./MediumTable";
import EditMediumForm from "./EditMediumForm";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import Resizer from "react-image-file-resizer";
import config from "../../../config";

// Component for managing medium operations
const Medium = () => {
  const [mediums, setMediums] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editedMedium, setEditedMedium] = useState({
    syllabusId: "",
    name: "",
    image: "",
    visibility: "",
    orderId: "",
  });
  const [filePreview, setFilePreview] = useState(null);
  const [mediumToDelete, setMediumToDelete] = useState(null);
  const fileUploadRef = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchAllMediums();
  }, [selectedSyllabus]);

  const fetchAllMediums = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let response;
      if (selectedSyllabus) {
        response = await axios.get(
          `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}&first=${first}&rows=${rows}`,
          headersConfig
        );
      } else {
        response = await axios.get(
          `${config.apiUrl}/medium?first=${first}&rows=${rows}`,
          headersConfig
        );
      }
      setMediums(response.data.mediums);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching mediums:", error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (event) => {
    fetchAllMediums(event.first / event.rows + 1, event.rows);
  };

  useEffect(() => {
    fetchSyllabuses();
  }, []);

  const fetchSyllabuses = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/syllabus`,
        headersConfig
      );
      const fetchedSyllabuses = response.data.syllabuses.filter(
        (syllabus) => syllabus.isMedium === true
      );
      setSyllabusOptions(
        fetchedSyllabuses.map((syllabus) => ({
          label: syllabus.name,
          value: syllabus._id,
        }))
      );
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.files[0];
    const handleResizedImage = (resizedFile) => {
      const fileURL = URL.createObjectURL(resizedFile);
      setFilePreview(fileURL);

      onInputChange({
        target: {
          name: "image",
          value: resizedFile.name,
          files: [resizedFile],
        },
      });
    };

    Resizer.imageFileResizer(
      file,
      512,
      512,
      "png",
      100,
      0,
      handleResizedImage,
      "file"
    );
  };

  const handleClearImage = () => {
    setEditedMedium((prevState) => ({
      ...prevState,
      image: null,
    }));
    setFilePreview(null);
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const handleEdit = (medium) => {
    setEditedMedium({
      ...medium,
      id: medium._id,
      syllabusId: medium.syllabus._id,
    });
    setVisibleEditDialog(true);
  };

  const saveEditedMedium = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const formDataToSend = new FormData();
      for (const key in editedMedium) {
        if (key === "image" && editedMedium[key]) {
          formDataToSend.append("image", editedMedium[key]);
        } else {
          formDataToSend.append(key, editedMedium[key]);
        }
      }

      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.put(
        `${config.apiUrl}/medium/update/${editedMedium.id}`,
        formDataToSend,
        headersConfig
      );
      setVisibleEditDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Medium edited successfully",
      });
      fetchAllMediums();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setEditedMedium((prevState) => ({
        ...prevState,
        [name]: file,
      }));
      setFilePreview(URL.createObjectURL(file));
    } else {
      setEditedMedium((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleDelete = (medium) => {
    const userType = localStorage.getItem("userType");
    if (userType === "admin") {
      setMediumToDelete({
        ...medium,
        id: medium._id,
      });
      setVisibleDeleteDialog(true);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Unauthorized",
        detail: "You do not have permission to delete the record.",
      });
    }
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.apiUrl}/medium/${mediumToDelete.id}`,
        headersConfig
      );
      setVisibleDeleteDialog(false);
      fetchAllMediums();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    }
  };

  const handleSyllabusChange = (e) => {
    setSelectedSyllabus(e.value);
    setSelectedMedium(null);
  };

  const handleClearFilter = () => {
    setSelectedSyllabus(null);
  };

  return (
    <div>
      <MediumTable
        mediums={mediums}
        totalRecords={totalRecords}
        first={first}
        setFirst={setFirst}
        rows={rows}
        setRows={setRows}
        onPageChange={onPageChange}
        loading={loading}
        selectedMedium={selectedMedium}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        syllabusOptions={syllabusOptions}
        selectedSyllabus={selectedSyllabus}
        handleSyllabusChange={handleSyllabusChange}
        handleClearFilter={handleClearFilter}
      />
      <EditMediumForm
        visible={visibleEditDialog}
        onHide={() => setVisibleEditDialog(false)}
        editedMedium={editedMedium}
        syllabusOptions={syllabusOptions}
        onInputChange={onInputChange}
        handleFileUpload={handleFileUpload}
        handleClearImage={handleClearImage}
        fileUploadRef={fileUploadRef}
        filePreview={filePreview}
        saveEditedMedium={saveEditedMedium}
        loading={loading}
      />
      <ConfirmDialog
        visible={visibleDeleteDialog}
        onHide={() => setVisibleDeleteDialog(false)}
        message="Are you sure you want to delete this medium?"
        header="Confirm"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        rejectClassName="p-button-secondary"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmDelete}
        reject={() => setVisibleDeleteDialog(false)}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default Medium;
