import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import GradeTable from "./GradeTable";
import EditGradeForm from "./EditGradeForm";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import Resizer from "react-image-file-resizer";
import config from "../../../config";

// Component for managing grade operations
const Grade = () => {
  const [grades, setGrades] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editedGrade, setEditedGrade] = useState({
    syllabusId: "",
    mediumId: "",
    name: "",
    image: "",
    visibility: "",
    orderId: "",
  });
  const [filePreview, setFilePreview] = useState(null);
  const [gradeToDelete, setGradeToDelete] = useState(null);
  const fileUploadRef = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchAllGrades();
  }, [selectedSyllabus, selectedMedium]);

  const fetchAllGrades = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let response;
      let url;

      if (selectedSyllabus) {
        url = `${config.apiUrl}/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}&first=${first}&rows=${rows}`;
        if (selectedMedium) {
          url += `&mediumId=${selectedMedium}`;
        }
      } else {
        url = `${config.apiUrl}/grade?first=${first}&rows=${rows}`;
      }

      response = await axios.get(url, headersConfig);

      setGrades(response.data.grades);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching grades:", error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (event) => {
    fetchAllGrades(event.first / event.rows + 1, event.rows);
  };

  useEffect(() => {
    fetchSyllabuses();
  }, []);

  const fetchSyllabuses = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/syllabus`,
        headersConfig
      );
      const fetchedSyllabuses = response.data.syllabuses;
      setSyllabusOptions(
        fetchedSyllabuses.map((syllabus) => ({
          label: syllabus.name,
          value: syllabus._id,
        }))
      );
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    }
  };

  useEffect(() => {
    if (editedGrade.syllabusId) {
      fetchMediums();
    }
  }, [editedGrade.syllabusId]);

  const fetchMediums = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedGrade.syllabusId;
      const response = await axios.get(
        `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
        headersConfig
      );
      const fetchedMediums = response.data.mediums;
      setMediumOptions(
        fetchedMediums.map((medium) => ({
          label: medium.name,
          value: medium._id,
        }))
      );
      const selectedMedium = fetchedMediums.find(
        (medium) => medium._id === editedGrade.mediumId
      );
      if (selectedMedium) {
        setEditedGrade((prevState) => ({
          ...prevState,
          mediumId: editedGrade.mediumId,
        }));
      } else {
        setEditedGrade((prevState) => ({
          ...prevState,
          mediumId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching mediums:", error);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.files[0];
    const handleResizedImage = (resizedFile) => {
      const fileURL = URL.createObjectURL(resizedFile);
      setFilePreview(fileURL);

      onInputChange({
        target: {
          name: "image",
          value: resizedFile.name,
          files: [resizedFile],
        },
      });
    };

    Resizer.imageFileResizer(
      file,
      512,
      512,
      "png",
      100,
      0,
      handleResizedImage,
      "file"
    );
  };

  const handleClearImage = () => {
    setEditedGrade((prevState) => ({
      ...prevState,
      image: null,
    }));
    setFilePreview(null);
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const handleEdit = (grade) => {
    setEditedGrade({
      ...grade,
      id: grade._id,
      syllabusId: grade.syllabus._id,
      mediumId: grade.medium ? grade.medium._id : null,
    });
    setSelectedGrade(grade);
    setVisibleEditDialog(true);
  };

  const saveEditedGrade = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const formDataToSend = new FormData();
      for (const key in editedGrade) {
        if (key === "image" && editedGrade[key]) {
          formDataToSend.append("image", editedGrade[key]);
        } else {
          formDataToSend.append(key, editedGrade[key]);
        }
      }
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.put(
        `${config.apiUrl}/grade/update/${editedGrade.id}`,
        editedGrade,
        headersConfig
      );
      setVisibleEditDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Grade edited successfully",
      });
      fetchAllGrades();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setEditedGrade((prevState) => ({
        ...prevState,
        [name]: file,
      }));
      setFilePreview(URL.createObjectURL(file));
    } else {
      setEditedGrade((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleDelete = (grade) => {
    const userType = localStorage.getItem("userType");
    if (userType === "admin") {
      setGradeToDelete({
        ...grade,
        id: grade._id,
      });
      setVisibleDeleteDialog(true);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Unauthorized",
        detail: "You do not have permission to delete the record.",
      });
    }
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.apiUrl}/grade/${gradeToDelete.id}`,
        headersConfig
      );
      setVisibleDeleteDialog(false);
      fetchAllGrades();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    }
  };

  const handleSyllabusChange = (e) => {
    setSelectedSyllabus(e.value);
    setSelectedMedium(null);
    setSelectedGrade(null);
  };

  const handleMediumChange = (e) => {
    setSelectedMedium(e.value);
    setSelectedGrade(null);
  };

  const handleClearFilter = () => {
    setSelectedSyllabus(null);
    setSelectedMedium(null);
  };

  return (
    <div>
      <GradeTable
        grades={grades}
        totalRecords={totalRecords}
        first={first}
        setFirst={setFirst}
        rows={rows}
        setRows={setRows}
        onPageChange={onPageChange}
        loading={loading}
        selectedGrade={selectedGrade}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        syllabusOptions={syllabusOptions}
        selectedSyllabus={selectedSyllabus}
        handleSyllabusChange={handleSyllabusChange}
        selectedMedium={selectedMedium}
        handleMediumChange={handleMediumChange}
        handleClearFilter={handleClearFilter}
      />
      <EditGradeForm
        visible={visibleEditDialog}
        onHide={() => setVisibleEditDialog(false)}
        editedGrade={editedGrade}
        syllabusOptions={syllabusOptions}
        mediumOptions={mediumOptions}
        onInputChange={onInputChange}
        handleFileUpload={handleFileUpload}
        handleClearImage={handleClearImage}
        fileUploadRef={fileUploadRef}
        filePreview={filePreview}
        saveEditedGrade={saveEditedGrade}
        loading={loading}
      />
      <ConfirmDialog
        visible={visibleDeleteDialog}
        onHide={() => setVisibleDeleteDialog(false)}
        message="Are you sure you want to delete this grade?"
        header="Confirm"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        rejectClassName="p-button-secondary"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmDelete}
        reject={() => setVisibleDeleteDialog(false)}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default Grade;
