import React from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from "primereact/paginator";

// Table component for displaying users
const UserTable = ({
  users,
  totalRecords,
  first,
  setFirst,
  rows,
  setRows,
  onPageChange,
  loading,
  selectedUser,
  handleEdit,
  handleDelete,
}) => {
  const navigate = useNavigate();
  const navigateToAddUser = () => {
    navigate("/add-user");
  };
  return (
    <>
      <h3
        style={{
          float: "left",
          color: "#808080",
        }}
      >
        User List
      </h3>
      <Button
        label="+ New User"
        onClick={navigateToAddUser}
        style={{ float: "right", marginRight: "10px" }}
        rounded
        text
        raised
      />
      <br />
      <br />
      <br />
      <div className="table-container">
        <DataTable
          value={users}
          selectionMode="single"
          selection={selectedUser}
          style={{ marginBottom: "50px" }}
        >
          <Column field="country" header="Country" />
          <Column field="name" header="Name" />
          <Column field="email" header="Email Address" />
          <Column field="phone" header="Phone Number" />
          <Column field="userType" header="User Type" />
          <Column field="userStatus" header="User Status" />
          <Column
            header="Actions"
            body={(rowData) => (
              <div>
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-success"
                  onClick={() => handleEdit(rowData)}
                />
                &nbsp;&nbsp;
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-danger"
                  onClick={() => handleDelete(rowData)}
                />
              </div>
            )}
          />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          onPageChange(e);
        }}
      />
    </>
  );
};

export default UserTable;
