import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import QuestionTable from "./QuestionTable";
import EditQuestionForm from "./EditQuestionForm";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import Resizer from "react-image-file-resizer";
import config from "../../config";

// Component for managing question operations
const Question = () => {
  const [questions, setQuestions] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [paperTypeOptions, setPaperTypeOptions] = useState([]);
  const [paperInfoOptions, setPaperInfoOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState({
    syllabusId: "",
    mediumId: null,
    gradeId: "",
    subjectId: "",
    paperTypeId: "",
    paperInfoId: "",
    questionType: "",
    questionTitle: "",
    questionImage: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    optionE: "",
    optionImageA: "",
    optionImageB: "",
    optionImageC: "",
    optionImageD: "",
    optionImageE: "",
    answer: "",
    note: "",
    addedBy: "",
  });
  const [filePreviews, setFilePreviews] = useState({});
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const questionImageRef = useRef(null);
  const optionImageARef = useRef(null);
  const optionImageBRef = useRef(null);
  const optionImageCRef = useRef(null);
  const optionImageDRef = useRef(null);
  const optionImageERef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [fetchOption, setFetchOption] = useState("");
  const toast = useRef(null);

  useEffect(() => {
    fetchAllQuestions();
    fetchCountryData();
    fetchSyllabusData();
  }, []);

  useEffect(() => {
    if (selectedQuestion) {
      if (selectedQuestion.syllabus.country === "All") {
        setFetchOption("all");
      } else {
        setFetchOption("specific");
        setSelectedCountry(selectedQuestion.syllabus.country);
      }
    }
  }, [selectedQuestion]);

  const fetchAllQuestions = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const userType = localStorage.getItem("userType");
      const userName = localStorage.getItem("userName");

      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let response;

      if (userType === "admin") {
        response = await axios.get(
          `${config.apiUrl}/question?first=${first}&rows=${rows}`,
          headersConfig
        );
      } else {
        response = await axios.get(
          `${config.apiUrl}/question/byUser?addedBy=${userName}&first=${first}&rows=${rows}`,
          headersConfig
        );
      }

      setQuestions(response.data.questions);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (event) => {
    fetchAllQuestions(event.first / event.rows + 1, event.rows);
  };

  const fetchCountryData = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/country`);
      setCountries(
        response.data.map((country) => ({
          label: country.name,
          value: country.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching country list:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch country list",
      });
    }
  };

  const fetchSyllabusData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let url = `${config.apiUrl}/syllabus/`;

      if (fetchOption === "all") {
        url = `${config.apiUrl}/syllabus/all-countries`;
      } else if (fetchOption === "specific") {
        url = `${config.apiUrl}/syllabus/by-country/${encodeURIComponent(
          selectedCountry
        )}`;
      }
      const response = await axios.get(url, headersConfig);
      const fetchedSyllabuses = response.data.syllabuses;
      setSyllabusOptions(
        fetchedSyllabuses.map((syllabus) => ({
          label: syllabus.name,
          value: syllabus._id,
        }))
      );
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    }
  };

  useEffect(() => {
    if (fetchOption === "all") {
      fetchSyllabusData();
    } else if (fetchOption === "specific" && selectedCountry) {
      fetchSyllabusData();
    }
  }, [fetchOption, selectedCountry]);

  const handleCountrySelection = (e) => {
    const selectedValue = e.value;
    setSelectedCountry(selectedValue);
  };

  useEffect(() => {
    if (editedQuestion.syllabusId) {
      fetchMediums();
    }
  }, [editedQuestion.syllabusId]);

  const fetchMediums = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedQuestion.syllabusId;
      const response = await axios.get(
        `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
        headersConfig
      );
      const fetchedMediums = response.data.mediums;
      setMediumOptions(
        fetchedMediums.map((medium) => ({
          label: medium.name,
          value: medium._id,
        }))
      );
      if (
        fetchedMediums.some((medium) => medium._id === editedQuestion.mediumId)
      ) {
        setEditedQuestion((prevState) => ({
          ...prevState,
          mediumId: editedQuestion.mediumId,
        }));
      } else {
        setEditedQuestion((prevState) => ({
          ...prevState,
          mediumId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching mediums:", error);
    }
  };

  useEffect(() => {
    if (editedQuestion.syllabusId) {
      fetchGrades();
    }
  }, [editedQuestion.syllabusId, editedQuestion.mediumId]);

  const fetchGrades = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedQuestion.syllabusId;
      const selectedMedium = editedQuestion.mediumId;
      const response = await axios.get(
        `${config.apiUrl}/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}&mediumId=${selectedMedium}`,
        headersConfig
      );
      const fetchedGrades = response.data.grades;
      setGradeOptions(
        fetchedGrades.map((grade) => ({
          label: grade.name,
          value: grade._id,
        }))
      );

      if (fetchedGrades.some((grade) => grade._id === editedQuestion.gradeId)) {
        setEditedQuestion((prevState) => ({
          ...prevState,
          gradeId: editedQuestion.gradeId,
        }));
      } else {
        setEditedQuestion((prevState) => ({
          ...prevState,
          gradeId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  useEffect(() => {
    if (editedQuestion.syllabusId) {
      fetchSubjects();
    }
  }, [editedQuestion.syllabusId, editedQuestion.mediumId]);

  const fetchSubjects = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedQuestion.syllabusId;
      let url = `${config.apiUrl}/subject/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

      if (editedQuestion.mediumId) {
        url += `&mediumId=${editedQuestion.mediumId}`;
      }
      const response = await axios.get(url, headersConfig);
      const fetchedSubjects = response.data.subjects;
      setSubjectOptions(
        fetchedSubjects.map((subject) => ({
          label: subject.name,
          value: subject._id,
        }))
      );
      if (
        fetchedSubjects.some(
          (subject) => subject._id === editedQuestion.subjectId
        )
      ) {
        setEditedQuestion((prevState) => ({
          ...prevState,
          subjectId: editedQuestion.subjectId,
        }));
      } else {
        setEditedQuestion((prevState) => ({
          ...prevState,
          subjectId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  useEffect(() => {
    if (
      editedQuestion.syllabusId &&
      editedQuestion.gradeId !== "" &&
      editedQuestion.subjectId !== ""
    ) {
      fetchPaperTypes();
    }
  }, [
    editedQuestion.syllabusId,
    editedQuestion.mediumId,
    editedQuestion.gradeId,
    editedQuestion.subjectId,
  ]);

  const fetchPaperTypes = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let url = `${config.apiUrl}/paperType/byFilter?`;

      if (editedQuestion.syllabusId) {
        url += `syllabusId=${encodeURIComponent(editedQuestion.syllabusId)}&`;
      }

      if (editedQuestion.mediumId !== null) {
        url += `mediumId=${encodeURIComponent(editedQuestion.mediumId)}&`;
      }

      if (editedQuestion.gradeId) {
        url += `gradeId=${encodeURIComponent(editedQuestion.gradeId)}&`;
      }

      if (editedQuestion.subjectId) {
        url += `subjectId=${encodeURIComponent(editedQuestion.subjectId)}`;
      }

      const response = await axios.get(url, headersConfig);
      const fetchedPaperTypes = response.data.paperTypes;
      setPaperTypeOptions(
        fetchedPaperTypes.map((paperType) => ({
          label: paperType.paperTypeName,
          value: paperType._id,
        }))
      );
      if (
        fetchedPaperTypes.some(
          (paperType) => paperType._id === editedQuestion.paperTypeId
        )
      ) {
        setEditedQuestion((prevState) => ({
          ...prevState,
          paperTypeId: editedQuestion.paperTypeId,
        }));
      } else {
        setEditedQuestion((prevState) => ({
          ...prevState,
          paperTypeId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching paper types:", error);
    }
  };

  useEffect(() => {
    setEditedQuestion((prevState) => ({
      ...prevState,
      paperInfoId: editedQuestion.paperInfoId,
    }));
  }, [editedQuestion.paperInfoId]);

  useEffect(() => {
    if (
      editedQuestion.syllabusId &&
      editedQuestion.gradeId !== "" &&
      editedQuestion.subjectId !== "" &&
      editedQuestion.paperTypeId !== ""
    ) {
      fetchPaperInfos();
    }
  }, [
    editedQuestion.syllabusId,
    editedQuestion.mediumId,
    editedQuestion.gradeId,
    editedQuestion.subjectId,
    editedQuestion.paperTypeId,
  ]);

  const fetchPaperInfos = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let url = `${config.apiUrl}/paperInfo/byFilter?`;

      if (editedQuestion.syllabusId) {
        url += `syllabusId=${encodeURIComponent(editedQuestion.syllabusId)}&`;
      }

      if (editedQuestion.mediumId !== null) {
        url += `mediumId=${encodeURIComponent(editedQuestion.mediumId)}&`;
      }

      if (editedQuestion.gradeId) {
        url += `gradeId=${encodeURIComponent(editedQuestion.gradeId)}&`;
      }

      if (editedQuestion.subjectId) {
        url += `subjectId=${encodeURIComponent(editedQuestion.subjectId)}&`;
      }

      if (editedQuestion.paperTypeId) {
        url += `paperTypeId=${encodeURIComponent(editedQuestion.paperTypeId)}`;
      }
      const response = await axios.get(url, headersConfig);
      const fetchedPaperInfos = response.data.paperInfos;

      setPaperInfoOptions(
        fetchedPaperInfos.map((paperInfo) => ({
          label: paperInfo.paperInfo,
          value: paperInfo._id,
        }))
      );

      if (
        fetchedPaperInfos.some(
          (paperInfo) => paperInfo._id === editedQuestion.paperInfoId
        )
      ) {
        setEditedQuestion((prevState) => ({
          ...prevState,
          paperInfoId: editedQuestion.paperInfoId,
        }));
      } else {
        setEditedQuestion((prevState) => ({
          ...prevState,
          paperInfoId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching paper infos:", error);
    }
  };

  const handleClearImage = (imageName) => {
    setEditedQuestion((prevState) => ({
      ...prevState,
      [imageName]: "",
    }));
    setFilePreviews((prevState) => {
      const updatedPreviews = { ...prevState };
      delete updatedPreviews[imageName];
      return updatedPreviews;
    });
    if (questionImageRef.current) {
      questionImageRef.current.clear();
    }
    if (optionImageARef.current) {
      optionImageARef.current.clear();
    }
    if (optionImageBRef.current) {
      optionImageBRef.current.clear();
    }
    if (optionImageCRef.current) {
      optionImageCRef.current.clear();
    }
    if (optionImageDRef.current) {
      optionImageDRef.current.clear();
    }
    if (optionImageERef.current) {
      optionImageERef.current.clear();
    }
  };

  const refreshEditForm = () => {
    if (selectedQuestion) {
      setEditedQuestion({
        ...selectedQuestion,
        id: selectedQuestion._id,
        syllabusId: selectedQuestion.syllabus._id,
        mediumId: selectedQuestion.medium ? selectedQuestion.medium._id : null,
        gradeId: selectedQuestion.grade._id ? selectedQuestion.grade._id : null,
        subjectId: selectedQuestion.subject._id
          ? selectedQuestion.subject._id
          : null,
        paperTypeId: selectedQuestion.paperType._id
          ? selectedQuestion.paperType._id
          : null,
        paperInfoId: selectedQuestion.paperInfo._id
          ? selectedQuestion.paperInfo._id
          : null,
      });
      if (selectedQuestion.syllabus.country === "All") {
        setFetchOption("all");
      } else {
        setFetchOption("specific");
        setSelectedCountry(selectedQuestion.syllabus.country);
      }
      fetchSyllabusData();
    }
  };

  const handleEdit = (question) => {
    const filePreviews = {
      questionImage: question.questionImageURL,
      optionImageA: question.optionImageAURL,
      optionImageB: question.optionImageBURL,
      optionImageC: question.optionImageCURL,
      optionImageD: question.optionImageDURL,
      optionImageE: question.optionImageEURL,
    };
    setFilePreviews(filePreviews);
    setEditedQuestion({
      ...question,
      id: question._id,
      syllabusId: question.syllabus._id,
      mediumId: question.medium ? question.medium._id : null,
      gradeId: question.grade._id ? question.grade._id : null,
      subjectId: question.subject._id ? question.subject._id : null,
      paperTypeId: question.paperType._id ? question.paperType._id : null,
      paperInfoId: question.paperInfo._id ? question.paperInfo._id : null,
    });
    setSelectedQuestion(question);
    setVisibleEditDialog(true);
  };

  const handleFileUpload = (e, optionName) => {
    const file = e.files[0];

    const handleResizedImage = (resizedFile) => {
      const fileURL = URL.createObjectURL(resizedFile);

      setFilePreviews((prevPreviews) => ({
        ...prevPreviews,
        [optionName]: fileURL,
      }));

      setEditedQuestion((prevQuestion) => ({
        ...prevQuestion,
        [optionName]: resizedFile,
        [`${optionName}Image`]: resizedFile.name,
      }));
    };

    Resizer.imageFileResizer(
      file,
      512,
      384,
      "png",
      100,
      0,
      handleResizedImage,
      "file"
    );
  };

  const saveEditedQuestion = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const formDataToSend = new FormData();

      formDataToSend.append("syllabusId", editedQuestion.syllabusId);
      formDataToSend.append("mediumId", editedQuestion.mediumId);
      formDataToSend.append("gradeId", editedQuestion.gradeId);
      formDataToSend.append("subjectId", editedQuestion.subjectId);
      formDataToSend.append("paperTypeId", editedQuestion.paperTypeId);
      formDataToSend.append("paperInfoId", editedQuestion.paperInfoId);
      formDataToSend.append("questionType", editedQuestion.questionType);
      formDataToSend.append("questionTitle", editedQuestion.questionTitle);
      formDataToSend.append("optionA", editedQuestion.optionA);
      formDataToSend.append("optionB", editedQuestion.optionB);
      formDataToSend.append("optionC", editedQuestion.optionC);
      formDataToSend.append("optionD", editedQuestion.optionD);
      formDataToSend.append("optionE", editedQuestion.optionE);
      formDataToSend.append("answer", editedQuestion.answer);
      formDataToSend.append("note", editedQuestion.note);

      if (editedQuestion.questionImage) {
        formDataToSend.append("questionImage", editedQuestion.questionImage);
      }

      if (editedQuestion.optionImageA) {
        formDataToSend.append("optionA", editedQuestion.optionImageA);
      }
      if (editedQuestion.optionImageB) {
        formDataToSend.append("optionB", editedQuestion.optionImageB);
      }
      if (editedQuestion.optionImageC) {
        formDataToSend.append("optionC", editedQuestion.optionImageC);
      }
      if (editedQuestion.optionImageD) {
        formDataToSend.append("optionD", editedQuestion.optionImageD);
      }
      if (editedQuestion.optionImageE) {
        formDataToSend.append("optionE", editedQuestion.optionImageE);
      }
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.put(
        `${config.apiUrl}/question/update/${editedQuestion.id}`,
        formDataToSend,
        headersConfig
      );

      setVisibleEditDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Question edited successfully",
      });

      fetchAllQuestions();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setEditedQuestion((prevState) => ({
        ...prevState,
        [name]: file.name,
        imageName: file.name,
      }));
    } else {
      setEditedQuestion((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const onInputQuestionTitleChange = (e) => {
    setEditedQuestion((prevState) => ({
      ...prevState,
      questionTitle: e.htmlValue,
    }));
  };

  const onInputOptionChange = (option, e) => {
    setEditedQuestion((prevState) => ({
      ...prevState,
      [option]: e.htmlValue,
    }));
  };

  const handleDelete = (question) => {
    setQuestionToDelete({
      ...question,
      id: question._id,
    });
    setVisibleDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.apiUrl}/question/${questionToDelete.id}`,
        headersConfig
      );
      setVisibleDeleteDialog(false);
      fetchAllQuestions();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    }
  };
  return (
    <div>
      <QuestionTable
        questions={questions}
        totalRecords={totalRecords}
        first={first}
        setFirst={setFirst}
        rows={rows}
        setRows={setRows}
        loading={loading}
        onPageChange={onPageChange}
        selectedQuestion={selectedQuestion}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        fetchAllQuestions={fetchAllQuestions}
      />
      <EditQuestionForm
        visible={visibleEditDialog}
        onHide={() => setVisibleEditDialog(false)}
        editedQuestion={editedQuestion}
        fetchOption={fetchOption}
        setFetchOption={setFetchOption}
        syllabusOptions={syllabusOptions}
        mediumOptions={mediumOptions}
        gradeOptions={gradeOptions}
        subjectOptions={subjectOptions}
        paperTypeOptions={paperTypeOptions}
        paperInfoOptions={paperInfoOptions}
        countries={countries}
        selectedCountry={selectedCountry}
        onInputChange={onInputChange}
        handleCountrySelection={handleCountrySelection}
        setSelectedCountry={setSelectedCountry}
        onInputQuestionTitleChange={onInputQuestionTitleChange}
        onInputOptionChange={onInputOptionChange}
        handleFileUpload={handleFileUpload}
        handleClearImage={handleClearImage}
        questionImageRef={questionImageRef}
        optionImageARef={optionImageARef}
        optionImageBRef={optionImageBRef}
        optionImageCRef={optionImageCRef}
        optionImageDRef={optionImageDRef}
        optionImageERef={optionImageERef}
        filePreviews={filePreviews}
        saveEditedQuestion={saveEditedQuestion}
        refreshEditForm={refreshEditForm}
        loading={loading}
      />
      <ConfirmDialog
        visible={visibleDeleteDialog}
        onHide={() => setVisibleDeleteDialog(false)}
        message="Are you sure you want to delete this question?"
        header="Confirm"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        rejectClassName="p-button-secondary"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmDelete}
        reject={() => setVisibleDeleteDialog(false)}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default Question;
