import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import config from "../../config";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");

        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${config.apiUrl}/user/profile/${userId}`,
          headersConfig
        );

        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const getInitials = (name) => {
    if (!name) return "";
    const initials = name
      .split(" ")
      .map((part) => part[0])
      .join("");
    return initials.toUpperCase();
  };

  return (
    <center>
      <Card
        style={{
          width: "30rem",
          height: "32rem",
          marginTop: "50px",
          background: "#f8f8ff",
          borderRadius: "5%",
          padding: "20px",
          borderLeft: "2px solid #4caf50",
          borderBottom: "2px solid #708db6",
          borderRight: "2px solid #4caf50",
          borderTop: "2px solid #708db6",
          boxShadow: "0 8px 24px #f8f8ff",
        }}
      >
        {userData ? (
          <div className="p-d-flex p-flex-column p-ai-center">
            {userData.avatar ? (
              <Avatar
                image={userData.avatar}
                size="xlarge"
                shape="circle"
                className="p-mb-3"
              />
            ) : (
              <Avatar
                label={getInitials(userData.name)}
                size="xlarge"
                shape="circle"
                className="p-mb-3"
                style={{ backgroundColor: "#708db6", color: "#ffffff" }}
              />
            )}
            <h2>{userData.name}</h2>
            <br />
            <Divider />
            <br />
            <p style={{ fontSize: "1.1rem" }}>{userData.email}</p>
            <p style={{ fontSize: "1.1rem" }}>{userData.phone}</p>
            <p style={{ fontSize: "1.1rem" }}>{userData.country}</p>
            <br />
            <br />
            <Badge
              value={userData.userType.toUpperCase()}
              style={{ backgroundColor: "#708db6" }}
            />
            &nbsp;&nbsp;
            <Badge
              value={userData.userStatus.toUpperCase()}
              style={{ backgroundColor: "#4caf50" }}
            />
          </div>
        ) : (null)}
      </Card>
      {loading && (
        <ProgressSpinner
          style={{ width: "50px", height: "50px", marginTop: "20px" }}
          strokeWidth="5"
          animationDuration=".5s"
        />
      )}
    </center>
  );
};

export default Profile;
