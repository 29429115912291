import React from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

// Component for editing a user
const EditUserForm = ({
  visible,
  onHide,
  editedUser,
  countryList,
  userTypeOptions,
  userStatusOptions,
  onInputChange,
  saveEditedUser,
  loading,
}) => {
  return (
    <Dialog
      header="Edit User"
      visible={visible}
      onHide={onHide}
      style={{ width: "600px", maxHeight: "600px" }}
    >
      <div className="p-fluid" style={{ padding: "20px" }}>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{
              width: "50%",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            <label htmlFor="country">Country</label>
            <Dropdown
              id="country"
              name="country"
              value={editedUser.country}
              options={countryList}
              placeholder="Select Country"
              style={{ marginTop: "10px" }}
              disabled
            />
          </div>
          <div
            className="p-field"
            style={{ width: "50%", marginBottom: "20px" }}
          >
            <label htmlFor="name">Full Name</label>
            <InputText
              id="name"
              name="name"
              value={editedUser.name}
              style={{ marginTop: "10px" }}
              disabled
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{
              width: "50%",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            <label htmlFor="email">Email Address</label>
            <InputText
              id="email"
              name="email"
              value={editedUser.email}
              style={{ marginTop: "10px" }}
              disabled
            />
          </div>
          <div
            className="p-field"
            style={{ width: "50%", marginBottom: "20px" }}
          >
            <label htmlFor="phone">Phone Number</label>
            <InputText
              id="phone"
              name="phone"
              value={editedUser.phone}
              style={{ marginTop: "10px" }}
              disabled
            />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{
              width: "50%",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            <label htmlFor="userType">User Type</label>
            <Dropdown
              id="userType"
              name="userType"
              optionLabel="label"
              optionValue="value"
              value={editedUser.userType}
              options={userTypeOptions}
              style={{ marginTop: "10px", width: "100%" }}
              disabled
            />
          </div>
          <div
            className="p-field"
            style={{ width: "50%", marginBottom: "20px" }}
          >
            <label htmlFor="userStatus">User Status</label>
            <Dropdown
              id="userStatus"
              name="userStatus"
              optionLabel="label"
              optionValue="value"
              value={editedUser.userStatus}
              onChange={onInputChange}
              options={userStatusOptions}
              style={{ marginTop: "10px", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div
        className="p-dialog-footer"
        style={{
          marginTop: "20px",
          marginBottom: "-20px",
          marginRight: "-24px",
        }}
      >
        <Button label="Cancel" onClick={onHide} severity="danger" outlined />
        <Button
          label={
            loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Saving...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Save"
            )
          }
          onClick={saveEditedUser}
          severity="success"
          outlined
          disabled={loading}
        />
      </div>
    </Dialog>
  );
};

export default EditUserForm;
