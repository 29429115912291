import React from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload } from "primereact/fileupload";
import { Editor } from "primereact/editor";
import { ProgressSpinner } from "primereact/progressspinner";
import QuestionType from "../../types/QuestionType";
import config from "../../config";

// Component for editing a question
const EditQuestionForm = ({
  visible,
  onHide,
  editedQuestion,
  fetchOption,
  setFetchOption,
  syllabusOptions,
  mediumOptions,
  gradeOptions,
  subjectOptions,
  paperTypeOptions,
  paperInfoOptions,
  countries,
  selectedCountry,
  onInputChange,
  handleCountrySelection,
  onInputQuestionTitleChange,
  onInputOptionChange,
  handleFileUpload,
  handleClearImage,
  questionImageRef,
  optionImageARef,
  optionImageBRef,
  optionImageCRef,
  optionImageDRef,
  optionImageERef,
  filePreviews,
  saveEditedQuestion,
  refreshEditForm,
  loading,
}) => {
  const allAnswerOptions = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" },
  ];

  const limitedAnswerOptions = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
  ];

  const answerOptions =
    editedQuestion.questionType === QuestionType.TRUE_FALSE
      ? limitedAnswerOptions
      : allAnswerOptions;

  const handleRadioChange = (e) => {
    const value = e.value;
    setFetchOption(value);
    if (value === "specific" && selectedCountry) {
      handleCountrySelection(selectedCountry);
    }
  };

  const handleUndo = () => {
    refreshEditForm();
  };

  return (
    <Dialog
      header="Edit Question"
      visible={visible}
      onHide={onHide}
      style={{ width: "1200px", height: "680px" }}
      maximizable
    >
      <div className="p-fluid">
        <div>
          <div className="p-grid p-nogutter">
            <div className="p-col">
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <RadioButton
                    id="allCountries"
                    name="countryFilter"
                    value="all"
                    checked={fetchOption === "all"}
                    onChange={handleRadioChange}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="allCountries" className="p-radiobutton-label">
                    All Countries
                  </label>
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <RadioButton
                    id="specificCountry"
                    name="countryFilter"
                    value="specific"
                    checked={fetchOption === "specific"}
                    onChange={handleRadioChange}
                  />
                  &nbsp;&nbsp;
                  <label
                    htmlFor="specificCountry"
                    className="p-radiobutton-label"
                  >
                    Specific Country
                  </label>
                </div>
                <Button
                  icon="pi pi-refresh"
                  className="p-button-rounded p-button-text"
                  onClick={handleUndo}
                  style={{
                    color: "#708db6",
                    backgroundColor: "transparent",
                    marginLeft: "20px",
                  }}
                />
              </div>
            </div>
          </div>
          {fetchOption === "specific" && (
            <div
              className="p-field"
              style={{
                marginTop: "40px",
                marginBottom: "20px",
                width: "48%",
              }}
            >
              <label htmlFor="country">Country</label>
              <Dropdown
                id="country"
                name="country"
                value={selectedCountry}
                options={countries}
                onChange={(e) => handleCountrySelection(e)}
                optionLabel="label"
                optionValue="value"
                placeholder="Select Country"
                style={{ marginTop: "10px" }}
                filter
              />
            </div>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="syllabusId">Syllabus</label>
            <Dropdown
              id="syllabusId"
              name="syllabusId"
              value={editedQuestion.syllabusId}
              options={syllabusOptions}
              onChange={onInputChange}
              optionLabel="label"
              optionValue="value"
              style={{ marginTop: "10px", marginRight: "20px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="mediumId">Medium</label>
            <Dropdown
              id="mediumId"
              name="mediumId"
              value={editedQuestion.mediumId}
              options={mediumOptions}
              onChange={onInputChange}
              optionLabel="label"
              optionValue="value"
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="gradeId">Grade</label>
            <Dropdown
              id="gradeId"
              name="gradeId"
              value={editedQuestion.gradeId}
              options={gradeOptions}
              onChange={onInputChange}
              optionLabel="label"
              optionValue="value"
              style={{ marginTop: "10px", marginRight: "20px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="subjectId">Subject</label>
            <Dropdown
              id="subjectId"
              name="subjectId"
              value={editedQuestion.subjectId}
              options={subjectOptions}
              onChange={onInputChange}
              optionLabel="label"
              optionValue="value"
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="paperTypeId">Paper Type</label>
            <Dropdown
              id="paperTypeId"
              name="paperTypeId"
              value={editedQuestion.paperTypeId}
              options={paperTypeOptions}
              onChange={onInputChange}
              optionLabel="label"
              optionValue="value"
              style={{ marginTop: "10px", marginRight: "20px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="paperInfoId">Paper Info</label>
            <Dropdown
              id="paperInfoId"
              name="paperInfoId"
              value={editedQuestion.paperInfoId}
              options={paperInfoOptions}
              onChange={onInputChange}
              optionLabel="label"
              optionValue="value"
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div className="p-field" style={{ marginBottom: "20px" }}>
          <label htmlFor="questionType">Question Type</label>
          <br></br>
          <RadioButton
            id="questionType"
            name="questionType"
            value={QuestionType.TRUE_FALSE}
            onChange={onInputChange}
            checked={editedQuestion.questionType === QuestionType.TRUE_FALSE}
            style={{ marginTop: "10px" }}
          />
          &nbsp;&nbsp;True False&nbsp;&nbsp;
          <RadioButton
            id="questionType"
            name="questionType"
            value={QuestionType.IMAGE}
            onChange={onInputChange}
            checked={editedQuestion.questionType === QuestionType.IMAGE}
            style={{ marginTop: "10px" }}
          />
          &nbsp;&nbsp;Image&nbsp;&nbsp;
          <RadioButton
            id="questionType"
            name="questionType"
            value={QuestionType.TEXT_ONLY}
            onChange={onInputChange}
            checked={editedQuestion.questionType === QuestionType.TEXT_ONLY}
            style={{ marginTop: "10px" }}
          />
          &nbsp;&nbsp;Text Only&nbsp;&nbsp;
          <RadioButton
            id="questionType"
            name="questionType"
            value={QuestionType.IMAGE_ANSWER}
            onChange={onInputChange}
            checked={editedQuestion.questionType === QuestionType.IMAGE_ANSWER}
            style={{ marginTop: "10px" }}
          />
          &nbsp;&nbsp;Image Answer
        </div>
        <div className="p-field" style={{ marginBottom: "20px" }}>
          <label htmlFor="questionTitle">Question Title</label>
          <br />
          <br />
          <Editor
            id="questionTitle"
            name="questionTitle"
            value={editedQuestion.questionTitle}
            onTextChange={onInputQuestionTitleChange}
            style={{ marginTop: "10px", width: "100%" }}
          />
        </div>
        {editedQuestion.questionType !== QuestionType.TEXT_ONLY && (
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="questionImage">
              Question Image&nbsp;
              {editedQuestion.questionType === QuestionType.IMAGE_ANSWER && (
                <span style={{ fontSize: "small" }}>(optional)</span>
              )}
              {editedQuestion.questionType === QuestionType.TRUE_FALSE && (
                <span style={{ fontSize: "small" }}>(optional)</span>
              )}
            </label>
            <FileUpload
              ref={questionImageRef}
              name="questionImage"
              accept="image/*"
              chooseLabel="Upload"
              uploadLabel="Upload"
              mode="basic"
              customUpload
              onSelect={(e) => handleFileUpload(e, "questionImage")}
              style={{ marginTop: "10px" }}
            />
            {filePreviews["questionImage"] && editedQuestion.questionImage && (
              <>
                <img
                  src={filePreviews["questionImage"]}
                  alt="Uploaded"
                  style={{ maxWidth: "150px", marginTop: "10px" }}
                />
                <i
                  className="pi pi-times-circle"
                  onClick={() => handleClearImage("questionImage")}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    verticalAlign: "middle",
                    fontSize: "1rem",
                    color: "#bb2124",
                  }}
                ></i>
              </>
            )}
            {!filePreviews["questionImage"] && editedQuestion.questionImage && (
              <>
                <img
                  src={`${config.apiUrl}/uploads/${editedQuestion.questionImage}`}
                  alt="ExistingImage"
                  style={{ maxWidth: "150px", marginTop: "10px" }}
                />
                <div
                  style={{
                    maxWidth: "150px",
                    marginTop: "10px",
                    color: "#198754",
                  }}
                >
                  <b>{editedQuestion.questionImage}</b>
                </div>
              </>
            )}
          </div>
        )}
        {editedQuestion.questionType !== QuestionType.IMAGE_ANSWER && (
          <>
            <div style={{ display: "flex" }}>
              <div
                className="p-field"
                style={{ marginBottom: "20px", marginRight: "20px" }}
              >
                <label htmlFor="optionA">Option A</label>
                <br />
                <br />
                <Editor
                  id="optionA"
                  name="optionA"
                  value={editedQuestion.optionA}
                  onTextChange={(e) => onInputOptionChange("optionA", e)}
                  style={{ marginTop: "10px", width: "100%" }}
                />
              </div>
              <div className="p-field" style={{ marginBottom: "20px" }}>
                <label htmlFor="optionB">Option B</label>
                <br />
                <br />
                <Editor
                  id="optionB"
                  name="optionB"
                  value={editedQuestion.optionB}
                  onTextChange={(e) => onInputOptionChange("optionB", e)}
                  style={{ marginTop: "10px", width: "100%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              {editedQuestion.questionType !== QuestionType.TRUE_FALSE && (
                <div
                  className="p-field"
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                >
                  <label htmlFor="optionC">Option C</label>
                  <br />
                  <br />
                  <Editor
                    id="optionC"
                    name="optionC"
                    value={editedQuestion.optionC}
                    onTextChange={(e) => onInputOptionChange("optionC", e)}
                    style={{ marginTop: "10px", width: "100%" }}
                  />
                </div>
              )}

              {editedQuestion.questionType !== QuestionType.TRUE_FALSE && (
                <div className="p-field" style={{ marginBottom: "20px" }}>
                  <label htmlFor="optionD">Option D</label>
                  <br />
                  <br />
                  <Editor
                    id="optionD"
                    name="optionD"
                    value={editedQuestion.optionD}
                    onTextChange={(e) => onInputOptionChange("optionD", e)}
                    style={{ marginTop: "10px", width: "100%" }}
                  />
                </div>
              )}
            </div>
            <div style={{ display: "flex" }}>
              {editedQuestion.questionType !== QuestionType.TRUE_FALSE && (
                <div className="p-field" style={{ marginBottom: "20px" }}>
                  <label htmlFor="optionE">Option E</label>
                  <br />
                  <br />
                  <Editor
                    id="optionE"
                    name="optionE"
                    value={editedQuestion.optionE}
                    onTextChange={(e) => onInputOptionChange("optionE", e)}
                    style={{ marginTop: "10px", width: "100%" }}
                  />
                </div>
              )}
            </div>
          </>
        )}
        {editedQuestion.questionType === QuestionType.IMAGE_ANSWER && (
          <>
            <div style={{ display: "flex" }}>
              <div
                className="p-field"
                style={{ marginBottom: "20px", marginRight: "20px" }}
              >
                <label htmlFor="optionImageA">Option Image A</label>
                <FileUpload
                  ref={optionImageARef}
                  name="optionImageA"
                  accept="image/*"
                  chooseLabel="Upload"
                  uploadLabel="Upload"
                  mode="basic"
                  customUpload
                  onSelect={(e) => handleFileUpload(e, "optionImageA")}
                  style={{ marginTop: "10px" }}
                />
                {filePreviews["optionImageA"] && (
                  <>
                    <img
                      src={filePreviews["optionImageA"]}
                      alt="Uploaded"
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                    <i
                      className="pi pi-times-circle"
                      onClick={() => handleClearImage("optionImageA")}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                        fontSize: "1rem",
                        color: "#bb2124",
                      }}
                    ></i>
                  </>
                )}
                {!filePreviews["optionImageA"] && editedQuestion.optionA && (
                  <>
                    <img
                      src={`${config.apiUrl}/uploads/${editedQuestion.optionA}`}
                      alt="ExistingImage"
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                    <div
                      style={{
                        maxWidth: "150px",
                        marginTop: "10px",
                        color: "#198754",
                      }}
                    >
                      <b>{editedQuestion.optionA}</b>
                    </div>
                  </>
                )}
              </div>
              <div
                className="p-field"
                style={{ marginBottom: "20px", marginRight: "20px" }}
              >
                <label htmlFor="optionImageB">Option Image B</label>
                <FileUpload
                  ref={optionImageBRef}
                  name="optionImageB"
                  accept="image/*"
                  chooseLabel="Upload"
                  uploadLabel="Upload"
                  mode="basic"
                  customUpload
                  onSelect={(e) => handleFileUpload(e, "optionImageB")}
                  style={{ marginTop: "10px" }}
                />
                {filePreviews["optionImageB"] && (
                  <>
                    <img
                      src={filePreviews["optionImageB"]}
                      alt="Uploaded"
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                    <i
                      className="pi pi-times-circle"
                      onClick={() => handleClearImage("optionImageB")}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                        fontSize: "1rem",
                        color: "#bb2124",
                      }}
                    ></i>
                  </>
                )}
                {!filePreviews["optionImageB"] && editedQuestion.optionB && (
                  <>
                    <img
                      src={`${config.apiUrl}/uploads/${editedQuestion.optionB}`}
                      alt="ExistingImage"
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                    <div
                      style={{
                        maxWidth: "150px",
                        marginTop: "10px",
                        color: "#198754",
                      }}
                    >
                      <b>{editedQuestion.optionB}</b>
                    </div>
                  </>
                )}
              </div>
              <div
                className="p-field"
                style={{ marginBottom: "20px", marginRight: "20px" }}
              >
                <label htmlFor="optionImageC">Option Image C</label>
                <FileUpload
                  ref={optionImageCRef}
                  name="optionImageC"
                  accept="image/*"
                  chooseLabel="Upload"
                  uploadLabel="Upload"
                  mode="basic"
                  customUpload
                  onSelect={(e) => handleFileUpload(e, "optionImageC")}
                  style={{ marginTop: "10px" }}
                />
                {filePreviews["optionImageC"] && (
                  <>
                    <img
                      src={filePreviews["optionImageC"]}
                      alt="Uploaded"
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                    <i
                      className="pi pi-times-circle"
                      onClick={() => handleClearImage("optionImageC")}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                        fontSize: "1rem",
                        color: "#bb2124",
                      }}
                    ></i>
                  </>
                )}
                {!filePreviews["optionImageC"] && editedQuestion.optionC && (
                  <>
                    <img
                      src={`${config.apiUrl}/uploads/${editedQuestion.optionC}`}
                      alt="ExistingImage"
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                    <div
                      style={{
                        maxWidth: "150px",
                        marginTop: "10px",
                        color: "#198754",
                      }}
                    >
                      <b>{editedQuestion.optionC}</b>
                    </div>
                  </>
                )}
              </div>
              <div
                className="p-field"
                style={{ marginBottom: "20px", marginRight: "20px" }}
              >
                <label htmlFor="optionImageD">Option Image D</label>
                <FileUpload
                  ref={optionImageDRef}
                  name="optionImageD"
                  accept="image/*"
                  chooseLabel="Upload"
                  uploadLabel="Upload"
                  mode="basic"
                  customUpload
                  onSelect={(e) => handleFileUpload(e, "optionImageD")}
                  style={{ marginTop: "10px" }}
                />
                {filePreviews["optionImageD"] && (
                  <>
                    <img
                      src={filePreviews["optionImageD"]}
                      alt="Uploaded"
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                    <i
                      className="pi pi-times-circle"
                      onClick={() => handleClearImage("optionImageD")}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                        fontSize: "1rem",
                        color: "#bb2124",
                      }}
                    ></i>
                  </>
                )}
                {!filePreviews["optionImageD"] && editedQuestion.optionD && (
                  <>
                    <img
                      src={`${config.apiUrl}/uploads/${editedQuestion.optionD}`}
                      alt="ExistingImage"
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                    <div
                      style={{
                        maxWidth: "150px",
                        marginTop: "10px",
                        color: "#198754",
                      }}
                    >
                      <b>{editedQuestion.optionD}</b>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                className="p-field"
                style={{ marginBottom: "20px", marginRight: "20px" }}
              >
                <label htmlFor="optionImageE">Option Image E</label>
                <FileUpload
                  ref={optionImageERef}
                  name="optionImageE"
                  accept="image/*"
                  chooseLabel="Upload"
                  uploadLabel="Upload"
                  mode="basic"
                  customUpload
                  onSelect={(e) => handleFileUpload(e, "optionImageE")}
                  style={{ marginTop: "10px" }}
                />
                {filePreviews["optionImageE"] && (
                  <>
                    <img
                      src={filePreviews["optionImageE"]}
                      alt="Uploaded"
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                    <i
                      className="pi pi-times-circle"
                      onClick={() => handleClearImage("optionImageE")}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                        fontSize: "1rem",
                        color: "#bb2124",
                      }}
                    ></i>
                  </>
                )}
                {!filePreviews["optionImageE"] && editedQuestion.optionE && (
                  <>
                    <img
                      src={`${config.apiUrl}/uploads/${editedQuestion.optionE}`}
                      alt="ExistingImage"
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                    <div
                      style={{
                        maxWidth: "150px",
                        marginTop: "10px",
                        color: "#198754",
                      }}
                    >
                      <b>{editedQuestion.optionE}</b>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        <div className="p-field" style={{ marginBottom: "20px" }}>
          <label htmlFor="answer">Answer</label>
          <Dropdown
            id="answer"
            name="answer"
            value={editedQuestion.answer}
            options={answerOptions}
            onChange={onInputChange}
            optionLabel="label"
            optionValue="value"
            style={{ marginTop: "10px" }}
          />
        </div>
        <div className="p-field" style={{ marginBottom: "20px" }}>
          <label htmlFor="note">Note</label>
          <InputTextarea
            id="note"
            name="note"
            value={editedQuestion.note}
            onChange={onInputChange}
            rows={3}
            style={{ marginTop: "10px" }}
          />
        </div>
      </div>
      <div
        className="p-dialog-footer"
        style={{
          marginTop: "30px",
          marginBottom: "-10px",
          marginRight: "-24px",
        }}
      >
        <Button label="Cancel" onClick={onHide} severity="danger" outlined />
        <Button
          label={
            loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Saving...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Save"
            )
          }
          onClick={saveEditedQuestion}
          severity="success"
          outlined
          disabled={loading}
        />
      </div>
    </Dialog>
  );
};

export default EditQuestionForm;
