import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import config from "../../../config";

const CopyIDsPage = () => {
  const [syllabuses, setSyllabuses] = useState([]);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [grades, setGrades] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [paperTypeOptions, setPaperTypeOptions] = useState([]);
  const [paperInfos, setPaperInfos] = useState([]);
  const [paperInfoOptions, setPaperInfoOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    syllabusId: "",
    mediumId: "",
    gradeId: "",
    subjectId: "",
    paperTypeId: "",
    paperInfoId: "",
  });
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("all");
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/country`);
        setCountries(
          response.data.map((country) => ({
            label: country.name,
            value: country.name,
          }))
        );
      } catch (error) {
        console.error("Error fetching country list:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch country list",
        });
      }
    };

    fetchCountryData();
  }, []);

  useEffect(() => {
    const fetchSyllabusData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        let url = `${config.apiUrl}/syllabus/all-countries`;

        if (selectedCountry !== "all") {
          url = `${config.apiUrl}/syllabus/by-country/${encodeURIComponent(
            selectedCountry
          )}`;
        }

        const response = await axios.get(url, headersConfig);
        const fetchedSyllabuses = response.data.syllabuses;
        setSyllabuses(fetchedSyllabuses);
        setSyllabusOptions(
          fetchedSyllabuses.map((syllabus) => ({
            label: syllabus.name,
            value: syllabus._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching syllabuses:", error);
      }
    };

    fetchSyllabusData();
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedValues.syllabusId) {
      const fetchMediumData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const selectedSyllabus = selectedValues.syllabusId;
          const response = await axios.get(
            `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
            headersConfig
          );

          const fetchedMediums = response.data.mediums;
          setMediums(fetchedMediums);
          setMediumOptions(
            fetchedMediums.map((medium) => ({
              label: medium.name,
              value: medium._id,
            }))
          );
          setSelectedValues((prevValues) => ({
            ...prevValues,
            mediumId: "",
          }));
        } catch (error) {
          console.error("Error fetching mediums:", error);
        }
      };

      fetchMediumData();
    }
  }, [selectedValues.syllabusId]);

  useEffect(() => {
    if (selectedValues.syllabusId) {
      const fetchGradeData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const selectedSyllabus = selectedValues.syllabusId;
          const selectedMedium = selectedValues.mediumId;
          let url = `${config.apiUrl}/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

          if (selectedMedium) {
            url += `&mediumId=${selectedMedium}`;
          }

          const response = await axios.get(url, headersConfig);
          const fetchedGrades = response.data.grades;
          setGrades(fetchedGrades);
          setGradeOptions(
            fetchedGrades.map((grade) => ({
              label: grade.name,
              value: grade._id,
            }))
          );
          setSelectedValues((prevValues) => ({
            ...prevValues,
            gradeId: "",
          }));
        } catch (error) {
          console.error("Error fetching grades:", error);
        }
      };

      fetchGradeData();
    }
  }, [selectedValues.syllabusId, selectedValues.mediumId]);

  useEffect(() => {
    if (selectedValues.syllabusId) {
      const fetchSubjectData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const selectedSyllabus = selectedValues.syllabusId;
          const selectedMedium = selectedValues.mediumId;
          let url = `${config.apiUrl}/subject/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

          if (selectedMedium) {
            url += `&mediumId=${selectedMedium}`;
          }

          const response = await axios.get(url, headersConfig);
          const fetchedSubjects = response.data.subjects;
          setSubjects(fetchedSubjects);
          setSubjectOptions(
            fetchedSubjects.map((subject) => ({
              label: subject.name,
              value: subject._id,
            }))
          );
          setSelectedValues((prevValues) => ({
            ...prevValues,
            subjectId: "",
          }));
        } catch (error) {
          console.error("Error fetching subjects:", error);
        }
      };

      fetchSubjectData();
    }
  }, [
    selectedValues.syllabusId,
    selectedValues.mediumId,
    selectedValues.gradeId,
  ]);

  useEffect(() => {
    if (
      selectedValues.syllabusId &&
      selectedValues.gradeId &&
      selectedValues.subjectId
    ) {
      const fetchPaperTypeData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const selectedSyllabus = selectedValues.syllabusId;
          const selectedMedium = selectedValues.mediumId;
          const selectedGrade = selectedValues.gradeId;
          const selectedSubject = selectedValues.subjectId;

          let url = `${config.apiUrl}/paperType/byFilter?`;

          if (selectedSyllabus) {
            url += `syllabusId=${encodeURIComponent(selectedSyllabus)}`;
          }

          if (selectedMedium !== null) {
            url += `&mediumId=${encodeURIComponent(selectedMedium)}`;
          }

          if (selectedGrade) {
            url += `&gradeId=${encodeURIComponent(selectedGrade)}`;
          }

          if (selectedSubject) {
            url += `&subjectId=${encodeURIComponent(selectedSubject)}`;
          }

          const response = await axios.get(url, headersConfig);
          const fetchedPaperTypes = response.data.paperTypes;
          setPaperTypes(fetchedPaperTypes);
          setPaperTypeOptions(
            fetchedPaperTypes.map((paperType) => ({
              label: paperType.paperTypeName,
              value: paperType._id,
            }))
          );
          setSelectedValues((prevValues) => ({
            ...prevValues,
            paperTypeId: "",
          }));
        } catch (error) {
          console.error("Error fetching paper types:", error);
        }
      };

      fetchPaperTypeData();
    }
  }, [
    selectedValues.syllabusId,
    selectedValues.mediumId,
    selectedValues.gradeId,
    selectedValues.subjectId,
  ]);

  useEffect(() => {
    if (
      selectedValues.syllabusId &&
      selectedValues.gradeId &&
      selectedValues.subjectId &&
      selectedValues.paperTypeId
    ) {
      const fetchPaperInfoData = async () => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const selectedSyllabus = selectedValues.syllabusId;
          const selectedMedium = selectedValues.mediumId;
          const selectedGrade = selectedValues.gradeId;
          const selectedSubject = selectedValues.subjectId;
          const selectedPaperType = selectedValues.paperTypeId;

          let url = `${config.apiUrl}/paperInfo/byFilter?`;

          if (selectedSyllabus) {
            url += `syllabusId=${encodeURIComponent(selectedSyllabus)}`;
          }

          if (selectedMedium !== null) {
            url += `&mediumId=${encodeURIComponent(selectedMedium)}`;
          }

          if (selectedGrade) {
            url += `&gradeId=${encodeURIComponent(selectedGrade)}`;
          }

          if (selectedSubject) {
            url += `&subjectId=${encodeURIComponent(selectedSubject)}`;
          }

          if (selectedPaperType) {
            url += `&paperTypeId=${encodeURIComponent(selectedPaperType)}`;
          }

          const response = await axios.get(url, headersConfig);
          const fetchedPaperInfos = response.data.paperInfos;
          setPaperInfos(fetchedPaperInfos);
          setPaperInfoOptions(
            fetchedPaperInfos.map((paperInfo) => ({
              label: paperInfo.paperInfo,
              value: paperInfo._id,
            }))
          );
          setSelectedValues((prevValues) => ({
            ...prevValues,
            paperInfoId: "",
          }));
        } catch (error) {
          console.error("Error fetching paper infos:", error);
        }
      };

      fetchPaperInfoData();
    }
  }, [
    selectedValues.syllabusId,
    selectedValues.mediumId,
    selectedValues.gradeId,
    selectedValues.subjectId,
    selectedValues.paperTypeId,
  ]);

  const handleCountrySelection = (e) => {
    const value = e.value;
    setSelectedCountry(value);
    setShowCountryDropdown(value === "specific");
    setSelectedValues({
      syllabusId: "",
      mediumId: "",
      gradeId: "",
      subjectId: "",
      paperTypeId: "",
      paperInfoId: "",
    });
  };

  const handleChange = (e, name) => {
    const { value } = e.target;
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCopyIds = () => {
    toast.current.show({
      severity: "success",
      summary: "IDs Copied",
      detail: "IDs copied to clipboard",
      life: 3000,
    });
  };

  const resetValues = () => {
    setSelectedValues({
      syllabusId: null,
      mediumId: null,
      gradeId: null,
      subjectId: null,
      paperTypeId: null,
      paperInfoId: null,
    });
    document.getElementById("syllabusIdDisplay").value = "";
    document.getElementById("mediumIdDisplay").value = "";
    document.getElementById("gradeIdDisplay").value = "";
    document.getElementById("subjectIdDisplay").value = "";
    document.getElementById("paperTypeIdDisplay").value = "";
    document.getElementById("paperInfoIdDisplay").value = "";
  };

  return (
    <div>
      <h3 style={{ float: "left", color: "#808080" }}>Copy IDs</h3>
      <br />
      <br />
      <br />
      <br />
      <div>
        <div style={{ marginBottom: "20px", fontWeight: "500" }}>
          <RadioButton
            id="allCountries"
            name="countryFilter"
            value="all"
            checked={selectedCountry === "all"}
            onChange={handleCountrySelection}
          />
          &nbsp;
          <label htmlFor="allCountries">All Countries</label>
          <RadioButton
            id="specificCountry"
            name="countryFilter"
            value="specific"
            checked={selectedCountry !== "all"}
            onChange={handleCountrySelection}
            style={{ marginLeft: "20px" }}
          />
          &nbsp;
          <label htmlFor="specificCountry">Specific Country</label>
        </div>
        <div className="p-fluid" style={{ marginTop: "40px" }}>
          {showCountryDropdown && (
            <>
              <div style={{ marginTop: "20px", fontWeight: "500" }}>
                <label htmlFor="country">Country</label>
              </div>
              <div
                className="p-field"
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Dropdown
                  id="country"
                  name="country"
                  value={selectedCountry}
                  options={countries}
                  onChange={(e) => setSelectedCountry(e.value)}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select Country"
                  style={{ flex: "1", marginRight: "50px" }}
                  filter
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="p-fluid" style={{ marginTop: "40px" }}>
        <label
          htmlFor="syllabusId"
          style={{ marginTop: "20px", fontWeight: "500" }}
        >
          Syllabus
        </label>
        <div
          className="p-field"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Dropdown
            id="syllabusId"
            name="syllabusId"
            value={selectedValues.syllabusId}
            options={syllabusOptions}
            onChange={(e) => handleChange(e, "syllabusId")}
            optionLabel="label"
            placeholder="Select Syllabus"
            style={{ flex: "1", marginRight: "10px" }}
          />
          <InputText
            id="syllabusIdDisplay"
            value={selectedValues.syllabusId}
            readOnly
            style={{ flex: "1" }}
          />
          &nbsp;&nbsp;
          <CopyToClipboard
            text={selectedValues.syllabusId || ""}
            onCopy={handleCopyIds}
          >
            <Button
              icon="pi pi-copy"
              className="p-button-info p-button-outlined"
              style={{ flex: ".1" }}
              disabled={!selectedValues.syllabusId}
            />
          </CopyToClipboard>
        </div>
        <label
          htmlFor="mediumId"
          style={{ marginTop: "20px", fontWeight: "500" }}
        >
          Medium
        </label>
        <div
          className="p-field"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Dropdown
            id="mediumId"
            name="mediumId"
            value={selectedValues.mediumId}
            options={mediumOptions}
            onChange={(e) => handleChange(e, "mediumId")}
            optionLabel="label"
            placeholder="Select Medium"
            style={{ flex: "1", marginRight: "10px" }}
          />
          <InputText
            id="mediumIdDisplay"
            value={selectedValues.mediumId}
            readOnly
            style={{ flex: "1" }}
          />
          &nbsp;&nbsp;
          <CopyToClipboard
            text={selectedValues.mediumId || ""}
            onCopy={handleCopyIds}
          >
            <Button
              icon="pi pi-copy"
              className="p-button-info p-button-outlined"
              style={{ flex: ".1" }}
              disabled={!selectedValues.mediumId}
            />
          </CopyToClipboard>
        </div>
        <label
          htmlFor="gradeId"
          style={{ marginTop: "20px", fontWeight: "500" }}
        >
          Grade
        </label>
        <div
          className="p-field"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Dropdown
            id="gradeId"
            name="gradeId"
            value={selectedValues.gradeId}
            options={gradeOptions}
            onChange={(e) => handleChange(e, "gradeId")}
            optionLabel="label"
            placeholder="Select Grade"
            style={{ flex: "1", marginRight: "10px" }}
          />
          <InputText
            id="gradeIdDisplay"
            value={selectedValues.gradeId}
            readOnly
            style={{ flex: "1" }}
          />
          &nbsp;&nbsp;
          <CopyToClipboard
            text={selectedValues.gradeId || ""}
            onCopy={handleCopyIds}
          >
            <Button
              icon="pi pi-copy"
              className="p-button-info p-button-outlined"
              style={{ flex: ".1" }}
              disabled={!selectedValues.gradeId}
            />
          </CopyToClipboard>
        </div>
        <label
          htmlFor="subjectId"
          style={{ marginTop: "20px", fontWeight: "500" }}
        >
          Subject
        </label>
        <div
          className="p-field"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Dropdown
            id="subjectId"
            name="subjectId"
            value={selectedValues.subjectId}
            options={subjectOptions}
            onChange={(e) => handleChange(e, "subjectId")}
            optionLabel="label"
            placeholder="Select Subject"
            style={{ flex: "1", marginRight: "10px" }}
          />
          <InputText
            id="subjectIdDisplay"
            value={selectedValues.subjectId}
            readOnly
            style={{ flex: "1" }}
          />
          &nbsp;&nbsp;
          <CopyToClipboard
            text={selectedValues.subjectId || ""}
            onCopy={handleCopyIds}
          >
            <Button
              icon="pi pi-copy"
              className="p-button-info p-button-outlined"
              style={{ flex: ".1" }}
              disabled={!selectedValues.subjectId}
            />
          </CopyToClipboard>
        </div>
        <label
          htmlFor="paperTypeId"
          style={{ marginTop: "20px", fontWeight: "500" }}
        >
          Paper Type
        </label>
        <div
          className="p-field"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Dropdown
            id="paperTypeId"
            name="paperTypeId"
            value={selectedValues.paperTypeId}
            options={paperTypeOptions}
            onChange={(e) => handleChange(e, "paperTypeId")}
            optionLabel="label"
            placeholder="Select Paper Type"
            style={{ flex: "1", marginRight: "10px" }}
          />
          <InputText
            id="paperTypeIdDisplay"
            value={selectedValues.paperTypeId}
            readOnly
            style={{ flex: "1" }}
          />
          &nbsp;&nbsp;
          <CopyToClipboard
            text={selectedValues.paperTypeId || ""}
            onCopy={handleCopyIds}
          >
            <Button
              icon="pi pi-copy"
              className="p-button-info p-button-outlined"
              style={{ flex: ".1" }}
              disabled={!selectedValues.paperTypeId}
            />
          </CopyToClipboard>
        </div>
        <label
          htmlFor="paperInfoId"
          style={{ marginTop: "20px", fontWeight: "500" }}
        >
          Paper Info
        </label>
        <div
          className="p-field"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Dropdown
            id="paperInfoId"
            name="paperInfoId"
            value={selectedValues.paperInfoId}
            options={paperInfoOptions}
            onChange={(e) => handleChange(e, "paperInfoId")}
            optionLabel="label"
            placeholder="Select Paper Info"
            style={{ flex: "1", marginRight: "10px" }}
          />
          <InputText
            id="paperInfoIdDisplay"
            value={selectedValues.paperInfoId}
            readOnly
            style={{ flex: "1", alignItems: "center" }}
          />
          &nbsp;&nbsp;
          <CopyToClipboard
            text={selectedValues.paperInfoId || ""}
            onCopy={handleCopyIds}
          >
            <Button
              icon="pi pi-copy"
              className="p-button-info p-button-outlined"
              style={{ flex: ".1" }}
              disabled={!selectedValues.paperInfoId}
            />
          </CopyToClipboard>
        </div>
      </div>
      <div style={{ marginTop: "40px", marginBottom: "40px", float: "right" }}>
        <Button
          label="Reset"
          severity="danger"
          onClick={resetValues}
          outlined
        />
      </div>
      <Toast ref={toast} />
    </div>
  );
};

export default CopyIDsPage;
