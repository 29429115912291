import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";
import config from "../../config";
import axios from "axios";

const QuestionCountBySyllabusChart = () => {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${config.apiUrl}/common/by-syllabus/questions`,
          headersConfig
        );
        const data = response.data.data;

        const labels = data.map((item) => item.syllabusName);
        const counts = data.map((item) => item.count);

        setChartData({
          labels: labels,
          datasets: [
            {
              label: "Question Count",
              data: counts,
              backgroundColor: ["#708db6", "#4caf50", "#FF9F40", "#9966FF"],
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching question counts by syllabus:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="card">
      <h3
        style={{
          color: "#808080",
          marginTop: "40px",
          marginBottom: "70px",
        }}
      >
        Question Count by Syllabus
      </h3>
      {loading ? (
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      ) : (
        <div style={{ width: "450px" }}>
          <Chart type="pie" data={chartData} />
        </div>
      )}
    </div>
  );
};

export default QuestionCountBySyllabusChart;
