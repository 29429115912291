import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SubjectTable from "./SubjectTable";
import EditSubjectForm from "./EditSubjectForm";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import config from "../../../config";

// Component for managing subject operations
const Subject = () => {
  const [subjects, setSubjects] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editedSubject, setEditedSubject] = useState({
    syllabusId: "",
    mediumId: "",
    name: "",
    visibility: "",
    orderId: "",
  });
  const [subjectToDelete, setSubjectToDelete] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchAllSubjects();
  }, [selectedSyllabus, selectedMedium]);

  const fetchAllSubjects = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let response;
      let url;

      if (selectedSyllabus) {
        url = `${config.apiUrl}/subject/bySyllabusAndMedium?syllabusId=${selectedSyllabus}&first=${first}&rows=${rows}`;
        if (selectedMedium) {
          url += `&mediumId=${selectedMedium}`;
        }
      } else {
        url = `${config.apiUrl}/subject?first=${first}&rows=${rows}`;
      }

      response = await axios.get(url, headersConfig);

      setSubjects(response.data.subjects);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (event) => {
    fetchAllSubjects(event.first / event.rows + 1, event.rows);
  };

  useEffect(() => {
    fetchSyllabuses();
  }, []);

  const fetchSyllabuses = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/syllabus`,
        headersConfig
      );
      const fetchedSyllabuses = response.data.syllabuses;
      setSyllabusOptions(
        fetchedSyllabuses.map((syllabus) => ({
          label: syllabus.name,
          value: syllabus._id,
        }))
      );
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    }
  };

  useEffect(() => {
    if (editedSubject.syllabusId) {
      fetchMediums();
    }
  }, [editedSubject.syllabusId]);

  const fetchMediums = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedSubject.syllabusId;
      const response = await axios.get(
        `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
        headersConfig
      );
      const fetchedMediums = response.data.mediums;
      setMediumOptions(
        fetchedMediums.map((medium) => ({
          label: medium.name,
          value: medium._id,
        }))
      );
      const selectedMedium = fetchedMediums.find(
        (medium) => medium._id === editedSubject.mediumId
      );
      if (selectedMedium) {
        setEditedSubject((prevState) => ({
          ...prevState,
          mediumId: editedSubject.mediumId,
        }));
      } else {
        setEditedSubject((prevState) => ({
          ...prevState,
          mediumId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching mediums:", error);
    }
  };

  const handleEdit = (subject) => {
    setEditedSubject({
      ...subject,
      id: subject._id,
      syllabusId: subject.syllabus._id,
      mediumId: subject.medium ? subject.medium._id : null,
    });
    setSelectedSubject(subject);
    setVisibleEditDialog(true);
  };

  const saveEditedSubject = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.put(
        `${config.apiUrl}/subject/update/${editedSubject.id}`,
        editedSubject,
        headersConfig
      );
      setVisibleEditDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Subject edited successfully",
      });
      fetchAllSubjects();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setEditedSubject((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDelete = (subject) => {
    const userType = localStorage.getItem("userType");
    if (userType === "admin") {
      setSubjectToDelete({
        ...subject,
        id: subject._id,
      });
      setVisibleDeleteDialog(true);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Unauthorized",
        detail: "You do not have permission to delete the record.",
      });
    }
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.apiUrl}/subject/${subjectToDelete.id}`,
        headersConfig
      );
      setVisibleDeleteDialog(false);
      fetchAllSubjects();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    }
  };

  const handleSyllabusChange = (e) => {
    setSelectedSyllabus(e.value);
    setSelectedMedium(null);
    setSelectedSubject(null);
  };

  const handleMediumChange = (e) => {
    setSelectedMedium(e.value);
    setSelectedSubject(null);
  };

  const handleClearFilter = () => {
    setSelectedSyllabus(null);
    setSelectedMedium(null);
  };

  return (
    <div>
      <SubjectTable
        subjects={subjects}
        totalRecords={totalRecords}
        first={first}
        setFirst={setFirst}
        rows={rows}
        setRows={setRows}
        onPageChange={onPageChange}
        loading={loading}
        selectedSubject={selectedSubject}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        syllabusOptions={syllabusOptions}
        selectedSyllabus={selectedSyllabus}
        handleSyllabusChange={handleSyllabusChange}
        selectedMedium={selectedMedium}
        handleMediumChange={handleMediumChange}
        handleClearFilter={handleClearFilter}
      />
      <EditSubjectForm
        visible={visibleEditDialog}
        onHide={() => setVisibleEditDialog(false)}
        editedSubject={editedSubject}
        syllabusOptions={syllabusOptions}
        mediumOptions={mediumOptions}
        onInputChange={onInputChange}
        saveEditedSubject={saveEditedSubject}
        loading={loading}
      />
      <ConfirmDialog
        visible={visibleDeleteDialog}
        onHide={() => setVisibleDeleteDialog(false)}
        message="Are you sure you want to delete this subject?"
        header="Confirm"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        rejectClassName="p-button-secondary"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmDelete}
        reject={() => setVisibleDeleteDialog(false)}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default Subject;
