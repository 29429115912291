import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { FileUpload } from "primereact/fileupload";
import { ProgressSpinner } from "primereact/progressspinner";
import Resizer from "react-image-file-resizer";
import config from "../../../config";

// Component for adding a syllabus
const AddSyllabusForm = ({ syllabus }) => {
  const [formData, setFormData] = useState(
    syllabus || {
      country: "All",
      name: "",
      image: null,
      visibility: true,
      isMedium: true,
      orderId: "",
    }
  );
  const [countryList, setCountryList] = useState([]);
  const [visible, setVisible] = useState(false);
  const fileUploadRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/country`);
      setCountryList(
        response.data.map((country) => ({
          label: country.name,
          value: country.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching country list:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch country list",
      });
    }
  };

  const handleCountryChange = async (e) => {
    const selectedCountry = e.value;
    setFormData({ ...formData, country: selectedCountry });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (name, files) => {
    const file = files[0];
    Resizer.imageFileResizer(
      file,
      512,
      512,
      "png",
      100,
      0,
      (resizedFile) => {
        const imageUrl = URL.createObjectURL(resizedFile);
        setFormData({
          ...formData,
          [name]: resizedFile,
          [`${name}Preview`]: imageUrl,
          [`${name}EncryptedName`]: resizedFile.name,
        });
      },
      "file"
    );
  };

  const handleClearImage = () => {
    setFormData({
      ...formData,
      image: null,
      imagePreview: null,
      imageEncryptedName: null,
    });
    fileUploadRef.current.clear();
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("country", formData.country);
      formDataToSend.append("name", formData.name);
      formDataToSend.append("visibility", formData.visibility);
      formDataToSend.append("isMedium", formData.isMedium);
      formDataToSend.append("orderId", formData.orderId);

      if (formData.image) {
        formDataToSend.append("image", formData.image);
      }

      const response = await axios.post(
        `${config.apiUrl}/syllabus`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVisible(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Syllabus added successfully",
      });
      window.location.reload();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div>
        <h3
          style={{
            float: "left",
            color: "#808080",
          }}
        >
          Syllabuses
        </h3>
        <Button
          label="+ New Syllabus"
          onClick={() => setVisible(true)}
          style={{ float: "right" }}
          rounded
          text
          raised
        />
        <br />
        <br />
        <br />
      </div>
      <Dialog
        header="Add Syllabus"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "700px", maxHeight: "600px" }}
      >
        <div className="p-fluid">
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="country">
              Country&nbsp;
              <small style={{ color: "#808080", fontStyle: "italic" }}>
                (Optional)
              </small>
            </label>
            <Dropdown
              id="country"
              name="country"
              value={formData.country}
              options={countryList}
              onChange={handleCountryChange}
              placeholder="Select Country"
              style={{ marginTop: "10px" }}
              filter
            />
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="p-field"
              style={{
                marginBottom: "20px",
                marginRight: "20px",
                width: "50%",
              }}
            >
              <label htmlFor="name">Name</label>
              <InputText
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                style={{ marginTop: "10px" }}
              />
            </div>
            <div
              className="p-field"
              style={{ marginBottom: "20px", width: "50%" }}
            >
              <label htmlFor="orderId">
                Order ID&nbsp;&nbsp;
                <small style={{ color: "#808080", fontStyle: "italic" }}>
                  (Optional)
                </small>
              </label>
              <InputText
                id="orderId"
                name="orderId"
                value={formData.orderId || ""}
                keyfilter="int"
                onChange={handleChange}
                style={{ marginTop: "10px" }}
              />
            </div>
          </div>
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="image">
              Image&nbsp;<span style={{ fontSize: "small" }}>(optional)</span>
            </label>
            <FileUpload
              ref={fileUploadRef}
              name="image"
              accept="image/*"
              chooseLabel="Upload"
              uploadLabel="Upload"
              mode="basic"
              customUpload
              onSelect={(e) => handleFileUpload("image", e.files)}
              style={{ marginTop: "10px" }}
            />
            {formData.imagePreview && (
              <>
                <img
                  src={formData.imagePreview}
                  alt="Uploaded"
                  style={{ maxWidth: "150px", marginTop: "10px" }}
                />
                <i
                  className="pi pi-times-circle"
                  onClick={handleClearImage}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    verticalAlign: "middle",
                    fontSize: "1rem",
                    color: "#bb2124",
                  }}
                ></i>
              </>
            )}
          </div>
          <div className="p-field" style={{ marginBottom: "20px" }}>
            <label htmlFor="visibility">Visibility</label>
            <br></br>
            <RadioButton
              id="visibility"
              name="visibility"
              value={true}
              onChange={handleChange}
              checked={formData.visibility === true}
              style={{ marginTop: "10px" }}
            />{" "}
            Yes &nbsp;
            <RadioButton
              id="visibility"
              name="visibility"
              value={false}
              onChange={handleChange}
              checked={formData.visibility === false}
              style={{ marginTop: "10px" }}
            />{" "}
            No
          </div>
          <div className="p-field">
            <label htmlFor="isMedium">Is Medium</label>
            <br></br>
            <RadioButton
              id="isMedium"
              name="isMedium"
              value={true}
              onChange={handleChange}
              checked={formData.isMedium === true}
              style={{ marginTop: "10px" }}
            />{" "}
            Yes &nbsp;
            <RadioButton
              id="isMedium"
              name="isMedium"
              value={false}
              onChange={handleChange}
              checked={formData.isMedium === false}
              style={{ marginTop: "10px" }}
            />{" "}
            No
          </div>
        </div>
        <div
          className="p-dialog-footer"
          style={{
            marginTop: "20px",
            marginBottom: "-20px",
            marginRight: "-24px",
          }}
        >
          <Button
            label="Cancel"
            className="custom-button"
            onClick={() => setVisible(false)}
            severity="danger"
            outlined
          />
          <Button
            label={
              loading ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  Saving...&nbsp;&nbsp;
                  <ProgressSpinner
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                </div>
              ) : (
                "Save"
              )
            }
            onClick={handleSubmit}
            severity="success"
            outlined
            disabled={loading}
          />
        </div>
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};

export default AddSyllabusForm;
