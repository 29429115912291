import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from "primereact/paginator";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge } from "primereact/badge";

const CompletedLevels = () => {
  const [matchedLevels, setMatchedLevels] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [levelsLoading, setLevelsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedUser } = location.state || {};
  const [syllabusMap, setSyllabusMap] = useState({});
  const [mediumMap, setMediumMap] = useState({});
  const [gradeMap, setGradeMap] = useState({});
  const [subjectMap, setSubjectMap] = useState({});
  const [paperTypeMap, setPaperTypeMap] = useState({});
  const [paperInfoMap, setPaperInfoMap] = useState({});
  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    if (selectedUser) {
      fetchLevelsByUser();
      fetchTotalPoints();
    } else {
      navigate("/mobile-users");
    }
  }, [selectedUser]);

  const fetchLevelsByUser = async (first = 1, rows = 10) => {
    try {
      setLevelsLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/mobile/level/${selectedUser._id}?first=${first}&rows=${rows}`,
        headersConfig
      );
      setMatchedLevels(response.data.levelsWithPoints);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching levels by user:", error);
    } finally {
      setLevelsLoading(false);
    }
  };

  const fetchTotalPoints = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/mobile/level/total/${selectedUser._id}`,
        headersConfig
      );
      setTotalPoints(response.data);
    } catch (error) {
      console.error("Error fetching total points:", error);
    }
  };

  useEffect(() => {
    if (matchedLevels.length > 0) {
      fetchAllDetails();
    }
  }, [matchedLevels]);

  const fetchAllDetails = async () => {
    const syllabusIds = [
      ...new Set(matchedLevels.map((level) => level.syllabusId)),
    ];
    const mediumIds = [
      ...new Set(matchedLevels.map((level) => level.mediumId)),
    ];
    const gradeIds = [...new Set(matchedLevels.map((level) => level.gradeId))];
    const subjectIds = [
      ...new Set(matchedLevels.map((level) => level.subjectId)),
    ];
    const paperTypeIds = [
      ...new Set(matchedLevels.map((level) => level.paperTypeId)),
    ];
    const paperInfoIds = [
      ...new Set(matchedLevels.map((level) => level.paperInfoId)),
    ];

    await Promise.all([
      fetchDetails(syllabusIds, setSyllabusMap, "syllabus"),
      fetchDetails(mediumIds, setMediumMap, "medium"),
      fetchDetails(gradeIds, setGradeMap, "grade"),
      fetchDetails(subjectIds, setSubjectMap, "subject"),
      fetchDetails(paperTypeIds, setPaperTypeMap, "paperType", "paperTypeName"),
      fetchDetails(paperInfoIds, setPaperInfoMap, "paperInfo", "paperInfo"),
    ]);
  };

  const fetchDetails = async (ids, setMap, endpoint, nameField = "name") => {
    const map = {};
    await Promise.all(
      ids.map(async (id) => {
        try {
          const token = localStorage.getItem("token");
          const headersConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.get(
            `${config.apiUrl}/${endpoint}/${id}`,
            headersConfig
          );
          map[id] = response.data[endpoint][nameField];
        } catch (error) {
          console.error(`Error fetching ${endpoint} with id ${id}:`, error);
        }
      })
    );
    setMap(map);
  };

  const getName = (id, map) => {
    return map[id];
  };

  const onPageChange = (event) => {
    fetchLevelsByUser(event.first / event.rows + 1, event.rows);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const renderPoints = (rowData) => {
    return (
      <Badge
        value={rowData.points}
        style={{ backgroundColor: "darkgray" }}
      ></Badge>
    );
  };

  return (
    <div>
      <h3
        style={{
          float: "left",
          color: "#808080",
        }}
      >
        Completed Levels by &nbsp;
        <span style={{ fontWeight: "lighter" }}>{selectedUser?.name}</span>
      </h3>
      <span
        style={{
          float: "right",
          color: "#708db6",
          marginTop: "15px",
        }}
      >
        Total Points&nbsp; <Badge value={totalPoints}></Badge>
      </span>
      <br />
      <br />
      <DataTable
        value={matchedLevels}
        style={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <Column
          field="syllabusId"
          header="Syllabus"
          body={(rowData) => getName(rowData.syllabusId, syllabusMap)}
        />
        <Column
          field="mediumId"
          header="Medium"
          body={(rowData) => getName(rowData.mediumId, mediumMap)}
        />
        <Column
          field="gradeId"
          header="Grade"
          body={(rowData) => getName(rowData.gradeId, gradeMap)}
        />
        <Column
          field="subjectId"
          header="Subject"
          body={(rowData) => getName(rowData.subjectId, subjectMap)}
        />
        <Column
          field="paperTypeId"
          header="Paper Type"
          body={(rowData) => getName(rowData.paperTypeId, paperTypeMap)}
        />
        <Column
          field="paperInfoId"
          header="Paper Info"
          body={(rowData) => getName(rowData.paperInfoId, paperInfoMap)}
        />
        <Column field="levelNo" header="Level No" align="center" />{" "}
        <Column
          field="completedDate"
          header="Completed Date"
          body={(rowData) => formatDate(rowData.completedDate)}
        />
        <Column
          field="points"
          header="Points"
          align="center"
          body={renderPoints}
        />
      </DataTable>
      <center>
        {levelsLoading && (
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="5"
            animationDuration=".5s"
          />
        )}
      </center>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          onPageChange(e);
        }}
      />
    </div>
  );
};

export default CompletedLevels;
