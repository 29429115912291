import React from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { ProgressSpinner } from "primereact/progressspinner";

// Component for editing a subject
const EditSubjectForm = ({
  visible,
  onHide,
  editedSubject,
  syllabusOptions,
  mediumOptions,
  onInputChange,
  saveEditedSubject,
  loading,
}) => {
  return (
    <Dialog
      header="Edit Subject"
      visible={visible}
      onHide={onHide}
      style={{ width: "700px", maxHeight: "600px" }}
    >
      <div className="p-fluid">
        <div className="p-field" style={{ marginBottom: "20px" }}>
          <label htmlFor="syllabusId">Syllabus</label>
          <Dropdown
            id="syllabusId"
            name="syllabusId"
            value={editedSubject.syllabusId}
            options={syllabusOptions}
            onChange={onInputChange}
            optionLabel="label"
            optionValue="value"
            style={{ marginTop: "10px" }}
          />
        </div>
        <div className="p-field" style={{ marginBottom: "20px" }}>
          <label htmlFor="mediumId">Medium</label>
          <Dropdown
            id="mediumId"
            name="mediumId"
            value={editedSubject.mediumId}
            options={mediumOptions}
            onChange={onInputChange}
            optionLabel="label"
            optionValue="value"
            style={{ marginTop: "10px" }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="p-field"
            style={{ marginBottom: "20px", marginRight: "20px", width: "50%" }}
          >
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              name="name"
              value={editedSubject.name}
              onChange={onInputChange}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div
            className="p-field"
            style={{ marginBottom: "20px", width: "50%" }}
          >
            <label htmlFor="orderId">Order ID</label>
            <InputText
              id="orderId"
              name="orderId"
              value={editedSubject.orderId}
              keyfilter="int"
              onChange={onInputChange}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div className="p-field">
          <label htmlFor="visibility">Visibility</label>
          <br></br>
          <RadioButton
            id="visibility"
            name="visibility"
            value={true}
            onChange={onInputChange}
            checked={editedSubject.visibility === true}
            style={{ marginTop: "10px" }}
          />{" "}
          Yes &nbsp;
          <RadioButton
            id="visibility"
            name="visibility"
            value={false}
            onChange={onInputChange}
            checked={editedSubject.visibility === false}
            style={{ marginTop: "10px" }}
          />{" "}
          No
        </div>
      </div>
      <div
        className="p-dialog-footer"
        style={{
          marginTop: "20px",
          marginBottom: "-20px",
          marginRight: "-24px",
        }}
      >
        <Button label="Cancel" onClick={onHide} severity="danger" outlined />
        <Button
          label={
            loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                Saving...&nbsp;&nbsp;
                <ProgressSpinner
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </div>
            ) : (
              "Save"
            )
          }
          onClick={saveEditedSubject}
          severity="success"
          outlined
          disabled={loading}
        />
      </div>
    </Dialog>
  );
};

export default EditSubjectForm;
