import React, { useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import config from "../../../config";

// Table component for displaying mediums
const MediumTable = ({
  mediums,
  totalRecords,
  first,
  setFirst,
  rows,
  setRows,
  onPageChange,
  loading,
  selectedMedium,
  handleEdit,
  handleDelete,
  syllabusOptions,
  selectedSyllabus,
  handleSyllabusChange,
  handleClearFilter,
}) => {
  const toast = useRef(null);

  const renderVisibility = (rowData) => {
    return <span>{rowData.visibility ? "Yes" : "No"}</span>;
  };

  const renderImage = (rowData) => {
    if (rowData.image && typeof rowData.image === "string") {
      return (
        <img
          src={
            rowData.image.startsWith("http")
              ? rowData.image
              : `${config.apiUrl}/uploads/${rowData.image}`
          }
          alt={rowData.name}
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return null;
    }
  };

  const handleCopyId = (id) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: `ID copied successfully!`,
    });
  };

  const filteredMediums = mediums.filter((medium) => {
    const syllabusMatch =
      !selectedSyllabus ||
      (medium.syllabus && medium.syllabus._id === selectedSyllabus);
    return syllabusMatch;
  });

  return (
    <>
      <div className="table-container">
        <Dropdown
          value={selectedSyllabus}
          options={syllabusOptions}
          onChange={handleSyllabusChange}
          placeholder="Select Syllabus"
          optionLabel="label"
          optionValue="value"
          showClear
          onClear={() => handleClearFilter("syllabus.name", "syllabusId")}
        />
        &nbsp;&nbsp;
        <Button
          icon="pi pi-filter-slash"
          className="p-button-rounded"
          style={{
            background: "none",
            border: "none",
            borderColor: "#708db6",
            color: "#708db6",
            fontSize: "0.5rem",
          }}
          onClick={() => {
            handleClearFilter("syllabus.name", "syllabusId");
          }}
        />
        <br />
        <br />{" "}
        <DataTable
          value={filteredMediums}
          selectionMode="single"
          selection={selectedMedium}
          style={{ marginBottom: "50px" }}
        >
          <Column field="syllabus.name" header="Syllabus Name" />
          <Column field="name" header="Name" />
          <Column field="image" header="Image" body={renderImage} />
          <Column
            field="visibility"
            header="Visibility"
            body={renderVisibility}
          />
          <Column field="orderId" header="Order ID" />
          <Column
            header="Actions"
            body={(rowData) => (
              <div>
                <CopyToClipboard
                  text={rowData._id}
                  onCopy={() => handleCopyId(rowData._id)}
                >
                  <Button
                    icon="pi pi-copy"
                    className="p-button-rounded"
                    style={{
                      backgroundColor: "#aaaaaa",
                      borderColor: "#aaaaaa",
                      color: "#ffffff",
                    }}
                  />
                </CopyToClipboard>
                &nbsp;&nbsp;
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-success"
                  onClick={() => handleEdit(rowData)}
                />
                &nbsp;&nbsp;
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-danger"
                  onClick={() => handleDelete(rowData)}
                />
              </div>
            )}
          />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          onPageChange(e);
        }}
      />
      <Toast ref={toast} />
    </>
  );
};

export default MediumTable;
