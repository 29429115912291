import React, { useState, useEffect } from "react";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import { Badge } from "primereact/badge";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import DBUsersByGradeChart from "../Charts/DBUsersByGradeChart";
import config from "../../config";
import "./AppData.css";

const AppData = () => {
  const [loading, setLoading] = useState(true);
  const [countsLoading, setCountsLoading] = useState(false);
  const [stats, setStats] = useState({
    totalMobileUserCount: 0,
  });
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [totalSubs, setTotalSubs] = useState(0);
  const [ideamartCount, setIdeamartCount] = useState(0);
  const [mspaceCount, setMspaceCount] = useState(0);
  const [registeredCount, setRegisteredCount] = useState(0);
  const [notRegisteredCount, setNotRegisteredCount] = useState(0);

  const today = new Date();

  const formatDateForAPI = (date, endOfDay = false) => {
    if (!date) return null;
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    const time = endOfDay ? "23:59:59.999Z" : "00:00:00.000Z";
    return `${year}-${month}-${day}T${time}`;
  };

  const subscriptionTypes = [
    { label: "IDEAMART", value: "ideamart" },
    { label: "MSPACE", value: "mspace" },
  ];

  const subscriptionStatuses = [
    { label: "Registered", value: "registered" },
    { label: "Not Registered", value: "not-registered" },
  ];

  const handleClearAllFilters = () => {
    setType(null);
    setStatus(null);
    setFromDate(null);
    setToDate(null);
  };

  useEffect(() => {
    fetchSubscriptionCounts();
  }, [type, status, fromDate, toDate]);

  const fetchSubscriptionCounts = async () => {
    try {
      setCountsLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const params = {
        type,
        status,
        fromDate: formatDateForAPI(fromDate),
        toDate: formatDateForAPI(toDate, true),
      };

      const response = await axios.get(`${config.apiUrl}/subscription/totals`, {
        params,
        ...headersConfig,
      });

      setTotalSubs(response.data.totalRecords);
      setIdeamartCount(response.data.totalRecordsIdeamart);
      setMspaceCount(response.data.totalRecordsMspace);
      setRegisteredCount(response.data.totalRegisteredCount);
      setNotRegisteredCount(response.data.totalNotRegisteredCount);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setCountsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headersConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const res = await axios.get(
          `${config.apiUrl}/common/total-users`,
          headersConfig
        );

        setStats({
          totalMobileUserCount: res.data.totalMobileUserCount,
        });
      } catch (error) {
        console.error("Error fetching stats", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="app-data-dashboard" style={{ marginBottom: "50px" }}>
      {loading ? (
        <center>
          <ProgressSpinner strokeWidth="3" animationDuration=".5s" />
        </center>
      ) : (
        <>
          <div className="app-data-cards-container">
            <div className="app-data-dashboard-card">
              <div className="app-data-card-title">
                <span className="app-data-main-content">MOBILE USERS</span>
                <i className="pi pi-users app-data-card-icon"></i>
              </div>
              <hr className="app-data-custom-hr"></hr>
              <p className="app-data-card-value">
                {stats.totalMobileUserCount}
              </p>
            </div>
          </div>
          <div style={{ margin: "50px" }}>
            <h3
              style={{
                float: "left",
                color: "#808080",
              }}
            >
              Mobile User Subscriptions
            </h3>
            <br />
            <br />
            <br />
            <br />

            <div
              className="filters"
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <Dropdown
                  value={type}
                  options={subscriptionTypes}
                  onChange={(e) => setType(e.value)}
                  placeholder="Filter by Type"
                  showClear
                />
                <Dropdown
                  value={status}
                  options={subscriptionStatuses}
                  onChange={(e) => setStatus(e.value)}
                  placeholder="Filter by Status"
                  showClear
                />
                <Calendar
                  value={fromDate}
                  onChange={(e) => setFromDate(e.value)}
                  placeholder="From Date"
                  dateFormat="yy-mm-dd"
                  maxDate={today}
                />
                <Calendar
                  value={toDate}
                  onChange={(e) => setToDate(e.value)}
                  placeholder="To Date"
                  dateFormat="yy-mm-dd"
                  maxDate={today}
                />
                <Button
                  icon="pi pi-filter-slash"
                  className="p-button-rounded"
                  style={{
                    background: "none",
                    border: "none",
                    borderColor: "#708db6",
                    color: "#708db6",
                    fontSize: "0.5rem",
                  }}
                  onClick={handleClearAllFilters}
                />
              </div>
            </div>

            <br />
            <br />

            <div
              className="subscription-counts"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                height: "80px",
              }}
            >
              <Card
                style={{
                  backgroundColor: "#eff3f8",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  color: "#708db6",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span>
                    <b>Subscriptions</b>
                  </span>
                  &nbsp;&nbsp;
                  <Badge
                    value={totalSubs}
                    size="large"
                    severity="info"
                    style={{ fontWeight: "bold" }}
                  />
                </div>
              </Card>

              <Card
                style={{
                  backgroundColor: "#eff3f8",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  color: "#708db6",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span>
                    <b>IdeaMart</b>
                  </span>
                  &nbsp;&nbsp;
                  <Badge
                    value={ideamartCount}
                    size="large"
                    severity="info"
                    style={{ fontWeight: "bold" }}
                  />
                </div>
              </Card>

              <Card
                style={{
                  backgroundColor: "#eff3f8",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  color: "#708db6",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span>
                    <b>Mspace</b>
                  </span>
                  &nbsp;&nbsp;
                  <Badge
                    value={mspaceCount}
                    size="large"
                    severity="info"
                    style={{ fontWeight: "bold" }}
                  />
                </div>
              </Card>

              <Card
                style={{
                  backgroundColor: "#eff3f8",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  color: "#708db6",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span>
                    <b>Registered</b>
                  </span>
                  &nbsp;&nbsp;
                  <Badge
                    value={registeredCount}
                    size="large"
                    severity="info"
                    style={{ fontWeight: "bold" }}
                  />
                </div>
              </Card>

              <Card
                style={{
                  backgroundColor: "#eff3f8",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  color: "#708db6",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span>
                    <b>NotRegistered</b>
                  </span>
                  &nbsp;&nbsp;
                  <Badge
                    value={notRegisteredCount}
                    size="large"
                    severity="info"
                    style={{ fontWeight: "bold" }}
                  />
                </div>
              </Card>
            </div>
            <center>
              {countsLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <ProgressSpinner strokeWidth="3" animationDuration=".5s" />
                </div>
              )}
            </center>
          </div>
          <div>
            <DBUsersByGradeChart />
          </div>
        </>
      )}
    </div>
  );
};

export default AppData;
