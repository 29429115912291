import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import "../../app/App.css";

const Paper = () => {
  const [papers, setPapers] = useState([]);
  const [selectedPaper, setSelectedPaper] = useState(null);
  const [totalPaperRecords, setTotalPaperRecords] = useState(0);
  const [firstPaper, setFirstPaper] = useState(0);
  const [paperRows, setPaperRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [paperTypeOptions, setPaperTypeOptions] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "syllabus.name": { value: null, matchMode: FilterMatchMode.EQUALS },
    "medium.name": { value: null, matchMode: FilterMatchMode.EQUALS },
    "grade.name": { value: null, matchMode: FilterMatchMode.EQUALS },
    "subject.name": { value: null, matchMode: FilterMatchMode.EQUALS },
    "paperType.paperTypeName": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedIds, setSelectedIds] = useState({
    syllabusId: null,
    mediumId: null,
    gradeId: null,
    paperTypeId: null,
    subjectId: null,
  });
  const [totalPaperCount, setTotalPaperCount] = useState(0);
  const [totalQuestionCount, setTotalQuestionCount] = useState(0);

  useEffect(() => {
    fetchFiltersData();
    fetchAllPapers();
  }, []);

  useEffect(() => {
    fetchAllPapers();
  }, [filters, selectedIds]);

  const fetchFiltersData = async () => {
    await fetchSyllabuses();
    await fetchMediums();
    await fetchGrades();
    await fetchSubjects();
    await fetchPaperTypes();
  };

  const handleDropdownChange = (e, filterName) => {
    const value = e.value;
    setSelectedIds((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));

    if (filterName === "syllabusId") {
      fetchMediums(value);
    }

    if (filterName === "mediumId" || filterName === "syllabusId") {
      const syllabusId =
        filterName === "syllabusId" ? value : selectedIds.syllabusId;
      const mediumId = filterName === "mediumId" ? value : selectedIds.mediumId;
      fetchGrades(syllabusId, mediumId);
      fetchSubjects(syllabusId, mediumId);
    }

    const syllabusId =
      filterName === "syllabusId" ? value : selectedIds.syllabusId;
    const mediumId = filterName === "mediumId" ? value : selectedIds.mediumId;
    const gradeId = filterName === "gradeId" ? value : selectedIds.gradeId;
    const subjectId =
      filterName === "subjectId" ? value : selectedIds.subjectId;

    fetchPaperTypes(syllabusId, mediumId, gradeId, subjectId);
  };

  const fetchAllPapers = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/common/criterias?first=${first}&rows=${rows}`,
        {
          params: {
            globalFilter: filters.global.value,
            syllabusId: selectedIds.syllabusId,
            mediumId: selectedIds.mediumId,
            gradeId: selectedIds.gradeId,
            subjectId: selectedIds.subjectId,
            paperTypeId: selectedIds.paperTypeId,
          },
          ...headersConfig,
        }
      );
      setPapers(response.data.distinctCriteria);
      setTotalPaperRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching distinct criterias:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSyllabuses = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/syllabus`,
        headersConfig
      );
      setSyllabusOptions(
        response.data.syllabuses.map((s) => ({ label: s.name, value: s._id }))
      );
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    }
  };

  const fetchMediums = async (syllabusId = null) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let apiUrl = `${config.apiUrl}/medium/bySyllabus`;
      let params = { syllabusId };

      const response = await axios.get(apiUrl, {
        params,
        ...headersConfig,
      });

      setMediumOptions(
        response.data.mediums.map((m) => ({ label: m.name, value: m._id }))
      );
    } catch (error) {
      console.error("Error fetching mediums:", error);
    }
  };

  const fetchGrades = async (syllabusId = null, mediumId = null) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let apiUrl = `${config.apiUrl}/grade/bySyllabusAndMedium`;
      const params = { syllabusId };

      if (mediumId) {
        params.mediumId = mediumId;
      }

      const response = await axios.get(apiUrl, {
        params,
        ...headersConfig,
      });

      setGradeOptions(
        response.data.grades.map((g) => ({ label: g.name, value: g._id }))
      );
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  const fetchSubjects = async (syllabusId = null, mediumId = null) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let apiUrl = `${config.apiUrl}/subject/bySyllabusAndMedium`;
      const params = { syllabusId };

      if (mediumId) {
        params.mediumId = mediumId;
      }

      const response = await axios.get(apiUrl, {
        params,
        ...headersConfig,
      });

      setSubjectOptions(
        response.data.subjects.map((s) => ({ label: s.name, value: s._id }))
      );
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const fetchPaperTypes = async (
    syllabusId = null,
    mediumId = null,
    gradeId = null,
    subjectId = null
  ) => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const params = { syllabusId, gradeId, subjectId };
      if (mediumId) {
        params.mediumId = mediumId;
      }

      const response = await axios.get(`${config.apiUrl}/paperType/byFilter`, {
        params,
        ...headersConfig,
      });

      setPaperTypeOptions(
        response.data.paperTypes.map((p) => ({
          label: p.paperTypeName,
          value: p._id,
        }))
      );
    } catch (error) {
      console.error("Error fetching paper types:", error);
    }
  };

  const handleSelect = (filter) => {
    setSelectedPaper(filter);
    navigate(`/filtered-questions`, { state: { selectedCriteria: filter } });
  };

  const onPaperPageChange = (event) => {
    fetchAllPapers(event.first / event.rows + 1, event.rows);
  };

  const handleFilterChange = (name, value, idName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: { value, matchMode: FilterMatchMode.EQUALS },
    }));
    setSelectedIds((prevIds) => ({
      ...prevIds,
      [idName]: value ? value : null,
    }));
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const handleClearFilter = (name, idName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: { value: null, matchMode: FilterMatchMode.EQUALS },
    }));
    setSelectedIds((prevIds) => ({
      ...prevIds,
      [idName]: null,
    }));
    setGlobalFilterValue("");
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
        />
      </div>
    );
  };

  const syllabusFilterTemplate = (options) => {
    return (
      <div className="p-inputgroup">
        <Dropdown
          value={options.value}
          options={syllabusOptions}
          onChange={(e) =>
            handleFilterChange("syllabus.name", e.value, "syllabusId")
          }
          placeholder="Select Syllabus"
          className="p-column-filter"
        />
        <Button
          icon="pi pi-times"
          onClick={() => handleClearFilter("syllabus.name", "syllabusId")}
        />
      </div>
    );
  };

  const mediumFilterTemplate = (options) => {
    return (
      <div className="p-inputgroup">
        <Dropdown
          value={options.value}
          options={mediumOptions}
          onChange={(e) =>
            handleFilterChange("medium.name", e.value, "mediumId")
          }
          placeholder="Select Medium"
          className="p-column-filter"
        />
        <Button
          icon="pi pi-times"
          onClick={() => handleClearFilter("medium.name", "mediumId")}
        />
      </div>
    );
  };

  const gradeFilterTemplate = (options) => {
    return (
      <div className="p-inputgroup">
        <Dropdown
          value={options.value}
          options={gradeOptions}
          onChange={(e) => handleFilterChange("grade.name", e.value, "gradeId")}
          placeholder="Select Grade"
          className="p-column-filter"
        />
        <Button
          icon="pi pi-times"
          onClick={() => handleClearFilter("grade.name", "gradeId")}
        />
      </div>
    );
  };

  const subjectFilterTemplate = (options) => {
    return (
      <div className="p-inputgroup">
        <Dropdown
          value={options.value}
          options={subjectOptions}
          onChange={(e) =>
            handleFilterChange("subject.name", e.value, "subjectId")
          }
          placeholder="Select Subject"
          className="p-column-filter"
        />
        <Button
          icon="pi pi-times"
          onClick={() => handleClearFilter("subject.name", "subjectId")}
        />
      </div>
    );
  };

  useEffect(() => {
    fetchTotalCounts();
  }, [selectedIds]);

  const fetchTotalCounts = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const paperCountResponse = await axios.get(
        `${config.apiUrl}/common/total-papers`,
        {
          params: {
            syllabusId: selectedIds.syllabusId,
            mediumId: selectedIds.mediumId,
            gradeId: selectedIds.gradeId,
            subjectId: selectedIds.subjectId,
            paperTypeId: selectedIds.paperTypeId,
          },
          ...headersConfig,
        }
      );
      setTotalPaperCount(paperCountResponse.data.totalPaperCount);

      const questionCountResponse = await axios.get(
        `${config.apiUrl}/common/total-questions`,
        {
          params: {
            syllabusId: selectedIds.syllabusId,
            mediumId: selectedIds.mediumId,
            gradeId: selectedIds.gradeId,
            subjectId: selectedIds.subjectId,
            paperTypeId: selectedIds.paperTypeId,
          },
          ...headersConfig,
        }
      );
      setTotalQuestionCount(questionCountResponse.data.totalQuestionCount);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };

  return (
    <>
      <h3 style={{ float: "left", color: "#808080" }}>All Papers</h3>
      <br />
      <br />
      <br />
      <br />
      <div className="filters-container">
        <div className="filter-row">
          <Dropdown
            value={selectedIds.syllabusId}
            options={syllabusOptions}
            onChange={(e) => handleDropdownChange(e, "syllabusId")}
            placeholder="Select Syllabus"
            showClear
            onClear={() => handleClearFilter("syllabus.name", "syllabusId")}
          />
          &nbsp;
        </div>
        <div className="filter-row">
          <Dropdown
            value={selectedIds.mediumId}
            options={mediumOptions}
            onChange={(e) => handleDropdownChange(e, "mediumId")}
            placeholder="Select Medium"
            disabled={!selectedIds.syllabusId}
            showClear
            onClear={() => handleClearFilter("medium.name", "mediumId")}
          />
          &nbsp;
        </div>
        <div className="filter-row">
          <Dropdown
            value={selectedIds.gradeId}
            options={gradeOptions}
            onChange={(e) => handleDropdownChange(e, "gradeId")}
            placeholder="Select Grade"
            disabled={!selectedIds.syllabusId}
            showClear
            onClear={() => handleClearFilter("grade.name", "gradeId")}
          />
          &nbsp;
        </div>
        <div className="filter-row">
          <Dropdown
            value={selectedIds.subjectId}
            options={subjectOptions}
            onChange={(e) => handleDropdownChange(e, "subjectId")}
            placeholder="Select Subject"
            disabled={!selectedIds.syllabusId}
            showClear
            onClear={() => handleClearFilter("subject.name", "subjectId")}
          />
          &nbsp;
        </div>
        <div className="filter-row">
          <Dropdown
            value={selectedIds.paperTypeId}
            options={paperTypeOptions}
            onChange={(e) => handleDropdownChange(e, "paperTypeId")}
            placeholder="Select Paper Type"
            disabled={!selectedIds.syllabusId}
            showClear
            onClear={() =>
              handleClearFilter("paperType.paperTypeName", "paperTypeId")
            }
          />
        </div>
        &nbsp;
        <Button
          icon="pi pi-filter-slash"
          className="p-button-rounded"
          style={{
            background: "none",
            border: "none",
            borderColor: "#708db6",
            color: "#708db6",
            fontSize: "0.5rem",
            float: "right",
          }}
          onClick={() => {
            handleClearFilter("syllabus.name", "syllabusId");
            handleClearFilter("medium.name", "mediumId");
            handleClearFilter("grade.name", "gradeId");
            handleClearFilter("subject.name", "subjectId");
            handleClearFilter("paperType.paperTypeName", "paperTypeId");
          }}
        />
      </div>
      <br />
      <br />
      <div
        style={{
          display: "flex",
          gap: "20px",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "150px",
              height: "50px",
              padding: "10px",
              border: "1px solid #708db6",
              borderRadius: "8px",
              boxSizing: "border-box",
              backgroundColor: "transparent",
            }}
          >
            <span style={{ fontSize: "12px", color: "#708db6" }}>PAPERS</span>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                backgroundColor: "#708db6",
                color: "white",
                borderRadius: "12px",
                padding: "5px 10px",
              }}
            >
              {totalPaperCount}
            </span>
          </div>
        </div>

        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "150px",
              height: "50px",
              padding: "10px",
              border: "1px solid #28a745",
              borderRadius: "8px",
              boxSizing: "border-box",
              backgroundColor: "transparent",
            }}
          >
            <span style={{ fontSize: "12px", color: "#28a745" }}>
              QUESTIONS
            </span>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                backgroundColor: "#28a745",
                color: "white",
                borderRadius: "12px",
                padding: "5px 10px",
              }}
            >
              {totalQuestionCount}
            </span>
          </div>
        </div>
      </div>

      <br />
      <br />

      <DataTable
        value={papers}
        lazy
        filters={filters}
        filterDisplay="menu"
        selectionMode="single"
        selection={selectedPaper}
        style={{ marginBottom: "40px" }}
      >
        <Column
          field="syllabus.name"
          header="Syllabus"
          filter
          filterElement={syllabusFilterTemplate}
          showApplyButton={false}
          showClearButton={false}
        />

        <Column
          field="medium.name"
          header="Medium"
          filter
          filterElement={mediumFilterTemplate}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          field="grade.name"
          header="Grade"
          filter
          filterElement={gradeFilterTemplate}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column
          field="subject.name"
          header="Subject"
          filter
          filterElement={subjectFilterTemplate}
          showApplyButton={false}
          showClearButton={false}
        />
        <Column field="paperType.paperTypeName" header="Paper Type" />
        <Column field="paperInfo.paperInfo" header="Paper Info" />
        <Column field="questionCount" header="Question Count" align="center" />
        <Column
          header="Actions"
          body={(rowData) => (
            <div>
              <Button
                icon="pi pi-angle-double-right"
                className="p-button-rounded"
                onClick={() => handleSelect(rowData)}
                style={{
                  color: "#ffffff",
                  backgroundColor: "#708db6",
                  border: "#708db6",
                }}
              />
            </div>
          )}
        />
      </DataTable>
      <center>
        {loading && (
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="5"
            animationDuration=".5s"
          />
        )}
      </center>

      <Paginator
        first={firstPaper}
        rows={paperRows}
        totalRecords={totalPaperRecords}
        onPageChange={(e) => {
          setFirstPaper(e.first);
          setPaperRows(e.rows);
          onPaperPageChange(e);
        }}
        style={{ marginBottom: "40px" }}
      />
    </>
  );
};

export default Paper;
