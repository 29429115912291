import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from "primereact/paginator";
import { Tag } from "primereact/tag";
import { Badge } from "primereact/badge";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import PaymentGatewayLineChart from "../Charts/PaymentGatewayLineChart.js";
import config from "../../config";

const MobileUserPaymentGatewayTable = () => {
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [country, setCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    fetchAllPaymentGateways();
  }, [country, type]);

  const fetchAllPaymentGateways = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const params = {
        first,
        rows,
        country,
        type,
      };

      const response = await axios.get(`${config.apiUrl}/payment-gateway`, {
        params,
        ...headersConfig,
      });

      setPaymentGateways(response.data.paymentGatewayInfo);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching payment gateway information:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderType = (rowData) => {
    const typeLabel = rowData.type === "annual" ? "ANNUAL" : "MONTHLY";
    const typeSeverity = rowData.type === "annual" ? "info" : "success";

    return <Tag value={typeLabel} severity={typeSeverity} />;
  };

  const formatDateForDisplay = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const payTypes = [
    { label: "ANNUAL", value: "annual" },
    { label: "MONTHLY", value: "monthly" },
  ];

  const handleClearAllFilters = () => {
    setType(null);
    setCountry(null);
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/country`);
      setCountryList(
        response.data.map((country) => ({
          label: country.name,
          value: country.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching country list:", error);
    }
  };

  return (
    <>
      <h3
        style={{
          float: "left",
          color: "#808080",
        }}
      >
        Mobile User Payment Gateway Information
      </h3>
      <br />
      <br />
      <br />
      <br />

      <div
        className="payment-gateway-counts"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          height: "80px",
        }}
      >
        <Card
          style={{
            backgroundColor: "#eff3f8",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            color: "#708db6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>
              <b>Payment Count</b>
            </span>
            &nbsp;&nbsp;
            <Badge
              value={totalRecords}
              size="large"
              severity="info"
              style={{ fontWeight: "bold" }}
            />
          </div>
        </Card>
      </div>

      <br />
      <br />

      <div
        className="filters"
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "50px",
        }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Dropdown
            value={country}
            options={countryList}
            onChange={(e) => setCountry(e.value)}
            placeholder="Filter by Country"
            filter
            showClear
          />
          <Dropdown
            value={type}
            options={payTypes}
            onChange={(e) => setType(e.value)}
            placeholder="Filter by Type"
            showClear
          />
          <Button
            icon="pi pi-filter-slash"
            className="p-button-rounded"
            style={{
              background: "none",
              border: "none",
              borderColor: "#708db6",
              color: "#708db6",
              fontSize: "0.5rem",
            }}
            onClick={handleClearAllFilters}
          />
        </div>
      </div>

      <div className="table-container">
        <DataTable value={paymentGateways} style={{ marginBottom: "50px" }}>
          <Column field="country" header="Country" />
          <Column field="userName" header="Name" />
          <Column field="email" header="Email" />
          <Column field="type" header="Type" body={renderType} />
          <Column
            field="purchaseDate"
            header="Purchase Date"
            body={(rowData) => formatDateForDisplay(rowData.purchaseDate)}
          />
          <Column
            field="expireDate"
            header="Expire Date"
            body={(rowData) => formatDateForDisplay(rowData.expireDate)}
          />
          <Column field="isActive" header="Is Active" />
          <Column field="willRenew" header="Will Renew" />
          <Column field="amount" header="Amount" />
        </DataTable>
        <center>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          )}
        </center>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
          fetchAllPaymentGateways(e.first / e.rows + 1, e.rows);
        }}
      />
      <PaymentGatewayLineChart />
    </>
  );
};

export default MobileUserPaymentGatewayTable;
