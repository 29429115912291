import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import BannerTable from "./BannerTable";
import EditBannerForm from "./EditBannerForm";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import config from "../../config";

// Component for managing ad banner operations
const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editedBanner, setEditedBanner] = useState({
    syllabusId: "",
    mediumId: "",
    gradeId: "",
    image: "",
    expireDate: "",
    visibility: "",
    type: "",
  });
  const [bannerToDelete, setBannerToDelete] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const fileUploadRef = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchAllBanners();
  }, []);

  const fetchAllBanners = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/banner?first=${first}&rows=${rows}`,
        headersConfig
      );
      setBanners(response.data.banners);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching banners:", error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (event) => {
    fetchAllBanners(event.first / event.rows + 1, event.rows);
  };

  useEffect(() => {
    fetchSyllabuses();
  }, []);

  const fetchSyllabuses = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/syllabus`,
        headersConfig
      );
      const fetchedSyllabuses = response.data.syllabuses;
      setSyllabusOptions(
        fetchedSyllabuses.map((syllabus) => ({
          label: syllabus.name,
          value: syllabus._id,
        }))
      );
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    }
  };

  useEffect(() => {
    if (editedBanner.syllabusId) {
      fetchMediums();
    }
  }, [editedBanner.syllabusId]);

  const fetchMediums = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedBanner.syllabusId;
      const response = await axios.get(
        `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
        headersConfig
      );
      const fetchedMediums = response.data.mediums;
      setMediumOptions(
        fetchedMediums.map((medium) => ({
          label: medium.name,
          value: medium._id,
        }))
      );
      if (
        fetchedMediums.some((medium) => medium._id === editedBanner.mediumId)
      ) {
        setEditedBanner((prevState) => ({
          ...prevState,
          mediumId: editedBanner.mediumId,
        }));
      } else {
        setEditedBanner((prevState) => ({
          ...prevState,
          mediumId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching mediums:", error);
    }
  };

  useEffect(() => {
    if (editedBanner.syllabusId) {
      fetchGrades();
    }
  }, [editedBanner.syllabusId, editedBanner.mediumId]);

  const fetchGrades = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedBanner.syllabusId;
      const selectedMedium = editedBanner.mediumId;
      const response = await axios.get(
        `${config.apiUrl}/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}&mediumId=${selectedMedium}`,
        headersConfig
      );
      const fetchedGrades = response.data.grades;
      setGradeOptions(
        fetchedGrades.map((grade) => ({
          label: grade.name,
          value: grade._id,
        }))
      );

      if (fetchedGrades.some((grade) => grade._id === editedBanner.gradeId)) {
        setEditedBanner((prevState) => ({
          ...prevState,
          gradeId: editedBanner.gradeId,
        }));
      } else {
        setEditedBanner((prevState) => ({
          ...prevState,
          gradeId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.files[0];
    const fileURL = URL.createObjectURL(file);

    setEditedBanner((prevFormData) => ({
      ...prevFormData,
      image: file,
      imagePreview: fileURL,
    }));

    onInputChange({
      target: {
        name: "image",
        value: file.name,
        files: [file],
      },
    });
  };

  const handleClearImage = () => {
    setEditedBanner((prevState) => ({
      ...prevState,
      image: null,
    }));
    setFilePreview(null);
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const handleEdit = (banner) => {
    setEditedBanner({
      ...banner,
      id: banner._id,
      syllabusId: banner.syllabus ? banner.syllabus._id : null,
      mediumId: banner.medium ? banner.medium._id : null,
      gradeId: banner.grade ? banner.grade._id : null,
      expireDate: banner.expireDate ? new Date(banner.expireDate) : null,
    });
    setSelectedBanner(banner);
    setVisibleEditDialog(true);
  };

  const handleDateChange = (e) => {
    setEditedBanner((prevState) => ({ ...prevState, expireDate: e.value }));
  };

  const saveEditedBanner = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const formDataToSend = new FormData();
      for (const key in editedBanner) {
        if (key === "image" && editedBanner[key]) {
          formDataToSend.append("image", editedBanner[key]);
        } else {
          formDataToSend.append(key, editedBanner[key]);
        }
      }
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.put(
        `${config.apiUrl}/banner/update/${editedBanner.id}`,
        editedBanner,
        headersConfig
      );
      setVisibleEditDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Banner edited successfully",
      });
      fetchAllBanners();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setEditedBanner((prevState) => ({
        ...prevState,
        [name]: file,
      }));
      setFilePreview(URL.createObjectURL(file));
    } else {
      setEditedBanner((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleDelete = (banner) => {
    setBannerToDelete({
      ...banner,
      id: banner._id,
    });
    setVisibleDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.apiUrl}/banner/${bannerToDelete.id}`,
        headersConfig
      );
      setVisibleDeleteDialog(false);
      fetchAllBanners();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    }
  };
  return (
    <div>
      <BannerTable
        banners={banners}
        totalRecords={totalRecords}
        first={first}
        setFirst={setFirst}
        rows={rows}
        setRows={setRows}
        onPageChange={onPageChange}
        loading={loading}
        selectedBanner={selectedBanner}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <EditBannerForm
        visible={visibleEditDialog}
        onHide={() => setVisibleEditDialog(false)}
        editedBanner={editedBanner}
        syllabusOptions={syllabusOptions}
        mediumOptions={mediumOptions}
        gradeOptions={gradeOptions}
        onInputChange={onInputChange}
        handleDateChange={handleDateChange}
        handleFileUpload={handleFileUpload}
        handleClearImage={handleClearImage}
        fileUploadRef={fileUploadRef}
        filePreview={filePreview}
        saveEditedBanner={saveEditedBanner}
        loading={loading}
      />
      <ConfirmDialog
        visible={visibleDeleteDialog}
        onHide={() => setVisibleDeleteDialog(false)}
        message="Are you sure you want to delete this banner?"
        header="Confirm"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        rejectClassName="p-button-secondary"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmDelete}
        reject={() => setVisibleDeleteDialog(false)}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default Banner;
