import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PaperInfoTable from "./PaperInfoTable";
import EditPaperInfoForm from "./EditPaperInfoForm";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import Resizer from "react-image-file-resizer";
import config from "../../../config";

// Component for managing paper info operations
const PaperInfo = () => {
  const [paperInfos, setPaperInfos] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedPaperType, setSelectedPaperType] = useState(null);
  const [selectedPaperInfo, setSelectedPaperInfo] = useState(null);
  const [visibleEditDialog, setVisibleEditDialog] = useState(false);
  const [visibleDeleteDialog, setVisibleDeleteDialog] = useState(false);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [paperTypeOptions, setPaperTypeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editedPaperInfo, setEditedPaperInfo] = useState({
    syllabusId: "",
    mediumId: "",
    gradeId: "",
    subjectId: "",
    paperTypeId: "",
    paperInfo: "",
    image: "",
    visibility: "",
  });
  const [filePreview, setFilePreview] = useState(null);
  const [paperInfoToDelete, setPaperInfoToDelete] = useState(null);
  const fileUploadRef = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    fetchAllPaperInfos();
  }, [
    selectedSyllabus,
    selectedMedium,
    selectedGrade,
    selectedSubject,
    selectedPaperType,
  ]);

  const fetchAllPaperInfos = async (first = 1, rows = 10) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let url = `${config.apiUrl}/paperInfo`;
      const params = { first, rows };

      if (selectedSyllabus) {
        params.syllabusId = selectedSyllabus;
      }
      if (selectedMedium) {
        params.mediumId = selectedMedium;
      }
      if (selectedGrade) {
        params.gradeId = selectedGrade;
      }
      if (selectedSubject) {
        params.subjectId = selectedSubject;
      }
      if (selectedPaperType) {
        params.paperTypeId = selectedPaperType;
      }

      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");

      if (queryString) {
        url = `${config.apiUrl}/paperInfo/byFilter?${queryString}`;
      }

      const response = await axios.get(url, {
        headers: headersConfig.headers,
      });
      setPaperInfos(response.data.paperInfos);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Error fetching paper infos:", error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (event) => {
    fetchAllPaperInfos(event.first / event.rows + 1, event.rows);
  };

  useEffect(() => {
    fetchSyllabuses();
  }, []);

  const fetchSyllabuses = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${config.apiUrl}/syllabus`,
        headersConfig
      );
      const fetchedSyllabuses = response.data.syllabuses;
      setSyllabusOptions(
        fetchedSyllabuses.map((syllabus) => ({
          label: syllabus.name,
          value: syllabus._id,
        }))
      );
    } catch (error) {
      console.error("Error fetching syllabuses:", error);
    }
  };

  useEffect(() => {
    if (editedPaperInfo.syllabusId) {
      fetchMediums();
    }
  }, [editedPaperInfo.syllabusId]);

  const fetchMediums = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedPaperInfo.syllabusId;
      const response = await axios.get(
        `${config.apiUrl}/medium/bySyllabus?syllabusId=${selectedSyllabus}`,
        headersConfig
      );
      const fetchedMediums = response.data.mediums;
      setMediumOptions(
        fetchedMediums.map((medium) => ({
          label: medium.name,
          value: medium._id,
        }))
      );
      if (
        fetchedMediums.some((medium) => medium._id === editedPaperInfo.mediumId)
      ) {
        setEditedPaperInfo((prevState) => ({
          ...prevState,
          mediumId: editedPaperInfo.mediumId,
        }));
      } else {
        setEditedPaperInfo((prevState) => ({
          ...prevState,
          mediumId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching mediums:", error);
    }
  };

  useEffect(() => {
    if (editedPaperInfo.syllabusId) {
      fetchGrades();
    }
  }, [editedPaperInfo.syllabusId, editedPaperInfo.mediumId]);

  const fetchGrades = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedPaperInfo.syllabusId;
      const selectedMedium = editedPaperInfo.mediumId;
      const response = await axios.get(
        `${config.apiUrl}/grade/bySyllabusAndMedium?syllabusId=${selectedSyllabus}&mediumId=${selectedMedium}`,
        headersConfig
      );
      const fetchedGrades = response.data.grades;
      setGradeOptions(
        fetchedGrades.map((grade) => ({
          label: grade.name,
          value: grade._id,
        }))
      );

      if (
        fetchedGrades.some((grade) => grade._id === editedPaperInfo.gradeId)
      ) {
        setEditedPaperInfo((prevState) => ({
          ...prevState,
          gradeId: editedPaperInfo.gradeId,
        }));
      } else {
        setEditedPaperInfo((prevState) => ({
          ...prevState,
          gradeId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  useEffect(() => {
    if (editedPaperInfo.syllabusId) {
      fetchSubjects();
    }
  }, [editedPaperInfo.syllabusId, editedPaperInfo.mediumId]);

  const fetchSubjects = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedSyllabus = editedPaperInfo.syllabusId;
      let url = `${config.apiUrl}/subject/bySyllabusAndMedium?syllabusId=${selectedSyllabus}`;

      if (editedPaperInfo.mediumId) {
        url += `&mediumId=${editedPaperInfo.mediumId}`;
      }
      const response = await axios.get(url, headersConfig);
      const fetchedSubjects = response.data.subjects;
      setSubjectOptions(
        fetchedSubjects.map((subject) => ({
          label: subject.name,
          value: subject._id,
        }))
      );
      if (
        fetchedSubjects.some(
          (subject) => subject._id === editedPaperInfo.subjectId
        )
      ) {
        setEditedPaperInfo((prevState) => ({
          ...prevState,
          subjectId: editedPaperInfo.subjectId,
        }));
      } else {
        setEditedPaperInfo((prevState) => ({
          ...prevState,
          subjectId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  useEffect(() => {
    if (
      editedPaperInfo.syllabusId &&
      editedPaperInfo.gradeId !== "" &&
      editedPaperInfo.subjectId !== ""
    ) {
      fetchPaperTypes();
    }
  }, [
    editedPaperInfo.syllabusId,
    editedPaperInfo.mediumId,
    editedPaperInfo.gradeId,
    editedPaperInfo.subjectId,
  ]);

  const fetchPaperTypes = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let url = `${config.apiUrl}/paperType/byFilter?`;

      if (editedPaperInfo.syllabusId) {
        url += `syllabusId=${encodeURIComponent(editedPaperInfo.syllabusId)}&`;
      }

      if (editedPaperInfo.mediumId !== null) {
        url += `mediumId=${encodeURIComponent(editedPaperInfo.mediumId)}&`;
      }

      if (editedPaperInfo.gradeId) {
        url += `gradeId=${encodeURIComponent(editedPaperInfo.gradeId)}&`;
      }

      if (editedPaperInfo.subjectId) {
        url += `subjectId=${encodeURIComponent(editedPaperInfo.subjectId)}`;
      }

      const response = await axios.get(url, headersConfig);
      const fetchedPaperTypes = response.data.paperTypes;
      setPaperTypeOptions(
        fetchedPaperTypes.map((paperType) => ({
          label: paperType.paperTypeName,
          value: paperType._id,
        }))
      );
      if (
        fetchedPaperTypes.some(
          (paperType) => paperType._id === editedPaperInfo.paperTypeId
        )
      ) {
        setEditedPaperInfo((prevState) => ({
          ...prevState,
          paperTypeId: editedPaperInfo.paperTypeId,
        }));
      } else {
        setEditedPaperInfo((prevState) => ({
          ...prevState,
          paperTypeId: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching paper types:", error);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.files[0];
    const handleResizedImage = (resizedFile) => {
      const fileURL = URL.createObjectURL(resizedFile);
      setFilePreview(fileURL);

      onInputChange({
        target: {
          name: "image",
          value: resizedFile.name,
          files: [resizedFile],
        },
      });
    };

    Resizer.imageFileResizer(
      file,
      512,
      512,
      "png",
      100,
      0,
      handleResizedImage,
      "file"
    );
  };

  const handleClearImage = () => {
    setEditedPaperInfo((prevState) => ({
      ...prevState,
      image: null,
    }));
    setFilePreview(null);
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const handleEdit = (paperInfo) => {
    setEditedPaperInfo({
      ...paperInfo,
      id: paperInfo._id,
      syllabusId: paperInfo.syllabus ? paperInfo.syllabus._id : null,
      mediumId: paperInfo.medium ? paperInfo.medium._id : null,
      gradeId: paperInfo.grade ? paperInfo.grade._id : null,
      subjectId: paperInfo.subject ? paperInfo.subject._id : null,
      paperTypeId: paperInfo.paperType ? paperInfo.paperType._id : null,
    });
    setSelectedPaperInfo(paperInfo);
    setVisibleEditDialog(true);
  };

  const saveEditedPaperInfo = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const formDataToSend = new FormData();
      for (const key in editedPaperInfo) {
        if (key === "image" && editedPaperInfo[key]) {
          formDataToSend.append("image", editedPaperInfo[key]);
        } else {
          formDataToSend.append(key, editedPaperInfo[key]);
        }
      }
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.put(
        `${config.apiUrl}/paperInfo/update/${editedPaperInfo.id}`,
        editedPaperInfo,
        headersConfig
      );
      setVisibleEditDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Paper info edited successfully",
      });
      fetchAllPaperInfos();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setEditedPaperInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
      setFilePreview(URL.createObjectURL(file));
    } else {
      setEditedPaperInfo((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleDelete = (paperInfo) => {
    const userType = localStorage.getItem("userType");
    if (userType === "admin") {
      setPaperInfoToDelete({
        ...paperInfo,
        id: paperInfo._id,
      });
      setVisibleDeleteDialog(true);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Unauthorized",
        detail: "You do not have permission to delete the record.",
      });
    }
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const headersConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.apiUrl}/paperInfo/${paperInfoToDelete.id}`,
        headersConfig
      );
      setVisibleDeleteDialog(false);
      fetchAllPaperInfos();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
      });
    }
  };

  const handleSyllabusChange = (e) => {
    setSelectedSyllabus(e.value);
    setSelectedMedium(null);
    setSelectedGrade(null);
    setSelectedSubject(null);
    setSelectedPaperType(null);
    setSelectedPaperInfo(null);
  };

  const handleMediumChange = (e) => {
    setSelectedMedium(e.value);
    setSelectedGrade(null);
    setSelectedSubject(null);
    setSelectedPaperType(null);
    setSelectedPaperInfo(null);
  };

  const handleGradeChange = (e) => {
    setSelectedGrade(e.value);
    setSelectedPaperType(null);
    setSelectedPaperInfo(null);
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.value);
    setSelectedPaperType(null);
    setSelectedPaperInfo(null);
  };

  const handlePaperTypeChange = (e) => {
    setSelectedPaperType(e.value);
    setSelectedPaperInfo();
  };

  const handleClearFilter = () => {
    setSelectedSyllabus(null);
    setSelectedMedium(null);
    setSelectedGrade(null);
    setSelectedSubject(null);
    setSelectedPaperType(null);
  };

  return (
    <div>
      <PaperInfoTable
        paperInfos={paperInfos}
        totalRecords={totalRecords}
        first={first}
        setFirst={setFirst}
        rows={rows}
        setRows={setRows}
        onPageChange={onPageChange}
        loading={loading}
        selectedPaperInfo={selectedPaperInfo}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        syllabusOptions={syllabusOptions}
        selectedSyllabus={selectedSyllabus}
        handleSyllabusChange={handleSyllabusChange}
        selectedMedium={selectedMedium}
        handleMediumChange={handleMediumChange}
        selectedGrade={selectedGrade}
        handleGradeChange={handleGradeChange}
        selectedSubject={selectedSubject}
        handleSubjectChange={handleSubjectChange}
        selectedPaperType={selectedPaperType}
        handlePaperTypeChange={handlePaperTypeChange}
        handleClearFilter={handleClearFilter}
      />
      <EditPaperInfoForm
        visible={visibleEditDialog}
        onHide={() => setVisibleEditDialog(false)}
        editedPaperInfo={editedPaperInfo}
        syllabusOptions={syllabusOptions}
        mediumOptions={mediumOptions}
        gradeOptions={gradeOptions}
        subjectOptions={subjectOptions}
        paperTypeOptions={paperTypeOptions}
        onInputChange={onInputChange}
        handleFileUpload={handleFileUpload}
        handleClearImage={handleClearImage}
        fileUploadRef={fileUploadRef}
        filePreview={filePreview}
        saveEditedPaperInfo={saveEditedPaperInfo}
        loading={loading}
      />
      <ConfirmDialog
        visible={visibleDeleteDialog}
        onHide={() => setVisibleDeleteDialog(false)}
        message="Are you sure you want to delete this paper info?"
        header="Confirm"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        rejectClassName="p-button-secondary"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmDelete}
        reject={() => setVisibleDeleteDialog(false)}
      />
      <Toast ref={toast} />
    </div>
  );
};

export default PaperInfo;
